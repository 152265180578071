import firebase from 'firebase/app';

import { setLocalStorage, setRemoveLocalStorage } from '../functions/VLocalStorage';
import { actions_requests } from '../requests';
import { getProviderForProviderId, getPhotoURLProvider } from '../functions/providersFirebase';

class FirebaseClass {

  constructor ( user ) {
    this.user = user;
  }

  async AuthWithEmailAndPassword ( email, pass ) {
    let user         = await this.getUserFirebase( email, pass);
    let update_login = await this.UpdateLogin();

    if ( update_login === true ) {
      return user;
    }

  }

  async getUserFirebase ( email, pass ) {

    return await firebase.auth().signInWithEmailAndPassword(email, pass)
      .then( user => {
        setLocalStorage(user.user.displayName, user.user.photoURL, user.user.email, user.additionalUserInfo.providerId );
        let objUser = Object.assign({}, { displayName: user.user.displayName }, { photoURL: user.user.photoURL }, { email: user.user.email }, { providerId: user.additionalUserInfo.providerId }, { emailVerified: user.user.emailVerified });
        this.user = objUser;
        return { code: 200, response: 'success', user: objUser };
      })
      .catch( err => {
        return { code: 500, response: 'error', message: err };
      });

  }

  async UpdateLogin () {

    let user = this.user;
    let body        = `email=${user.email}&displayName=${user.displayName}&photoURL=${encodeURI(user.photoURL)}`;
    let url_request = process.env.REACT_APP_API_AUTHENTICATIONS;

    return await actions_requests( body, url_request, 'PUT' )
      .then( respo => {

        if ( respo.response === 'success' ) {
          return true;
        }

      })
      .catch( err => {
        console.log(err);
      });
  }

  async AuthProviderSocial ( optionSocial ) {

    let provider = "";

    if ( optionSocial === "1" ) {
      provider = new firebase.auth.FacebookAuthProvider();
    } else if ( optionSocial === "2" ) {
      provider = new firebase.auth.GoogleAuthProvider();
    }

    return await firebase.auth().signInWithPopup(provider)
      .then( result => {
        let photoURL = getPhotoURLProvider(result);

        setLocalStorage(result.user.displayName, photoURL, result.user.email, result.additionalUserInfo.providerId );
        let objUser = Object.assign( {}, { displayName: result.user.displayName }, { photoURL }, { email : result.user.email }, { providerId: result.additionalUserInfo.providerId });
        //result.user.new_photoURL = photoURL;
        this.user = objUser;
        return objUser;

      })
      .catch( err => {
        if (err.code === 'auth/account-exists-with-different-credential') {
          var email = err.email;

          return firebase.auth().fetchProvidersForEmail(email)
            .then( function(providers) {

              if ( providers[0] === 'password' ) {
                // Sesión con usuario y contraseña
              }

              let provider_ = getProviderForProviderId(providers[0]);

              return firebase.auth().signInWithPopup(provider_)
                .then( response => {
                  let photoURL = getPhotoURLProvider(response);
                  response.user.new_photoURL = photoURL;
                  setLocalStorage(response);
                  this.user = response;

                  return response;

                });

            })
        }
      });
  }

  async logout () {
    return await firebase.auth().signOut()
      .then( result => {
        setRemoveLocalStorage();
        return true;
      })
      .catch( err => console.log(`Error ${ err.code} : ${err.message }`));
  }

  async saveUser ( email, pass, displayName ) {
    let newUser = await this.createUser(email, pass, displayName);
    let update = await this.updateUser( displayName );
    let authentication = await this.fetchAuthentication();

    if ( newUser === true && update === true && authentication === true ) {
      return await this.sendEmailVerificationUser();
    }
  }

  async createUser ( email, pass, displayName ) {

    return await firebase.auth().createUserWithEmailAndPassword( email, pass )
      .then( user => {
        return true;
      })
      .catch( err => {
        console.log(err);
      });

  }

  async fetchAuthentication () {

    let user = firebase.auth().currentUser;

    let body = `email=${user.providerData[0].email}&provider=${user.providerData[0].providerId}&uid_user=${user.uid}&displayName=${user.displayName}&photoURL=${user.photoURL}`;
    let url_request = process.env.REACT_APP_API_AUTHENTICATIONS;

    return await actions_requests( body, url_request, 'POST')
      .then( respo => {
        if ( respo.response === 'success' ) {
          return true;
        }
      })
      .catch( err => {
        console.log(err);
      });
  }

  async updateUser ( displayName ) {
    let user = firebase.auth().currentUser;

    let photoURL = localStorage.getItem('photoURLRegister');

    return await user.updateProfile({ displayName, photoURL })
      .then( response => {
        return true;
      })
      .catch( err => console.log(err.message));
  }

  async sendEmailVerificationUser () {
    let user = firebase.auth().currentUser;

    return await user.sendEmailVerification().then( () => {
      alert(" Favor de verificar tu cuenta de correo");
    }).catch( err => {
      console.log(`Error: ${err.message}`);
    })
  }

  async resetPassword ( emailAddress ) {

    return await firebase.auth().sendPasswordResetEmail( emailAddress ).then( () => {
      return { response: 'success', message: 'Se le enviará un correo para reestabler la contraseña' };
    }).catch( err => {
      console.log(err.message);
    })
  }


}

export default FirebaseClass;
