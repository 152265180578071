import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import PicturesProject from './PicturesProject';

const propTypes = {
  class_position : PropTypes.string.isRequired,
  id             : PropTypes.string.isRequired,
  date           : PropTypes.number.isRequired,
  url            : PropTypes.array.isRequired,
  category       : PropTypes.string.isRequired,
  subcategory    : PropTypes.string.isRequired,
  description    : PropTypes.string.isRequired,
}

const ItemProject = ({ class_position, id, date, url, category, subcategory, description }) => {
  return (
    <div>
      <div className="timeline-date">
        <h3> { moment(date).format('LL') } </h3>
      </div>
      <article className={ class_position }>
        <div className="portfolio-item img-thumbnail">
          <span className="thumb-info">
            <PicturesProject
              pictures = { url }
            />
            <span className="thumb-info-title">
              <span className="thumb-info-inner">{ category }</span>
              <span className="thumb-info-type">{ subcategory }</span>
            </span>
          </span>
          <p>{ description }</p>
        </div>
      </article>
    </div>
  )
}

ItemProject.propTypes = propTypes;

export default ItemProject;
