import React from 'react';
import './css/spinner.css';

const Spinner = () => {
  return (
    <div className = "loader">
    </div>
  )
}

export default Spinner;
