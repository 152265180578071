import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import moment from 'moment';
import uuid from 'uuid';

import Comment from './Comment';
import InputComment from './InputComment';
import { renderLikesUser } from './ServicesRender';
import { isEmptyJSON } from '../../functions/ValidationState';
import { shareFacebook } from '../../functions/shareOpenGraph';
import { getItemLocalStorage } from '../../functions/VLocalStorage';

import './css/input_comment.css';

const propTypes = {
  likes_user      : PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  likes           : PropTypes.number.isRequired,
  textComments    : PropTypes.string.isRequired,
  keyChildren     : PropTypes.string.isRequired,
  id              : PropTypes.string.isRequired,
  avatar          : PropTypes.string.isRequired,
  handleLike      : PropTypes.func.isRequired,
  number_comments : PropTypes.number.isRequired,
  firstMessage    : PropTypes.bool.isRequired,
  description     : PropTypes.string.isRequired,
  url             : PropTypes.array.isRequired,
  url_post        : PropTypes.string.isRequired
}

class PictureCardFooter extends Component {

  constructor (props) {
    super(props);

    this.state = {
      pressLike: false,
      comments :  {},
      pressComment : false,
      pressMoreComment: false,
      number_comments : this.props.number_comments
    }

    this.onPressLike        = this.onPressLike.bind(this);
    this.handleSendComment  = this.handleSendComment.bind(this);
    this.handlePressComment = this.handlePressComment.bind(this);
    this.handleMoreComments = this.handleMoreComments.bind(this);
  }

  componentWillMount () {
    var comments = [];

    this.firebaseRef = firebase.database().ref('messages').child(`${this.props.keyChildren}/comments`);
    this.firebaseRef.on('child_added', snapshot => {
      let comment = {
        comment : snapshot.val().comment,
        id: snapshot.val().id,
        keyComment: snapshot.key,
        date: snapshot.val().date,
        user: {
          username: snapshot.val().user.username,
          avatar: snapshot.val().user.avatar
        }
      }
      comments.push(comment);
      this.setState({ comments });
    });

    this.getLikesUser();
  }

  getLikesUser () {
    var likes_user = [];

    this.firebaseRef2 = firebase.database().ref('messages').child(`${this.props.keyChildren}/likes_user`);
    this.firebaseRef2.on('child_added', snapshot => {
      let like_user = {
        displayName : snapshot.val().displayName,
        email       : snapshot.val().email
      };

      likes_user.push(like_user);
      this.setState({ likes_user });
    });
  }

  componentWillUnmount () {
    this.firebaseRef.off();
    this.firebaseRef2.off();
  }

  handlePressComment () {
    this.setState({ pressComment: true });
  }

  handleMoreComments () {
    this.setState({ pressMoreComment: true });
  }

  onPressLike () {
   this.props.handleLike();
  }

  renderInputComment () {
    if ( typeof(getItemLocalStorage('displayName')) !== 'undefined' ) {
      if ( this.state.pressComment === true ) {
        return (
          <InputComment
            sendComment = { this.handleSendComment }
            idButton    = { this.props.id }
            avatar      = { this.props.avatar }
          />
        )
      }
    } else {
      return (
        <div>
          Necesitas una cuenta para poder comentar!!
        </div>
      )
    }
  }

  handleSendComment (event) {

    if( event.charCode === 13 ) {
      let id = event.target.getAttribute('data-id');
      let comment = event.target.value;

      let newComment = {
        comment : comment,
        id: uuid.v4(),
        date: Date.now(),
        user: {
          username: getItemLocalStorage('displayName'),
          avatar: getItemLocalStorage('photoURL')
        }
      }

      firebase.database().ref('messages').orderByChild("id").equalTo(id).on('child_added', (snapshot) => {
        firebase.database().ref(`messages/${snapshot.key}`).child('comments').push(newComment);
        let number_comments = snapshot.val().number_comments + 1;
        firebase.database().ref(`messages/${snapshot.key}`).update({ number_comments, firstMessage: true });
        let MessagesShow = this.state.MessagesShow + 1;
        this.setState({
          number_comments,
          MessagesShow
        });
      });

      document.getElementById(id).value = '';
    }
  }

  renderComments () {

    if( isEmptyJSON(this.state.comments) === true ) {
      var tam = this.state.comments.length;
      var commentsLetters = "";

      if (tam > 1) {
        commentsLetters = "comentarios";
      } else if (tam >= 0 && tam <= 1 ) {
        commentsLetters = "comentario";
      }

      if (tam > 2 && this.state.pressMoreComment === true && this.props.firstMessage === true) {
        return (
          this.state.comments.map( (comm, index) => {
            return (
              <Comment
                key         = { comm.id }
                id          = { comm.id }
                avatar      = { comm.user.avatar }
                username    = { comm.user.username }
                date        = { moment(comm.date).fromNow() }
                comment     = { comm.comment }
                keyComment  = { comm.keyComment }
                keyMessage  = { this.props.keyChildren }
              />
            )
          })
        )
      } else if ( tam > 0 && tam <= 2) {
        return (
          this.state.comments.map( (comm) => {
            let dateFormat = moment(comm.date).fromNow();
              return (
                <Comment
                  key         = { comm.id }
                  id          = { comm.id }
                  avatar      = { comm.user.avatar }
                  username    = { comm.user.username }
                  date        = { dateFormat }
                  comment     = { comm.comment }
                  keyComment  = { comm.keyComment }
                  keyMessage  = { this.props.keyChildren }
                />
              )
          })
        )
      } else if ( tam > 2 && this.props.firstMessage === false ) {
        return (
          this.state.comments.map( (comm) => {
            return (
              <Comment
                key        = { comm.id }
                id         = { comm.id }
                avatar     = { comm.user.avatar }
                username   = { comm.user.username }
                date       = { moment(comm.date).fromNow() }
                comment    = { comm.comment }
                keyComment = { comm.keyComment }
                keyMessage = { this.props.keyChildren }
              />
            )
          })
        )
      } else if ( tam > 2 && this.state.pressMoreComment === false && this.props.firstMessage === true ) {
         var tamReal = tam - 2;
        return (
          this.state.comments.map( (comm, index) => {
            let dateFormat = moment(comm.date).fromNow();
            if ( this.state.pressComment === false ) {
              if ( index === 0 ) {
                return (
                  <a href = "#comments" key={ index } onClick={ this.handleMoreComments }> Ver { tamReal } { commentsLetters } más</a>
                )
              } else if( index > ( tamReal - this.state.MessagesShow ) ) {
                return (
                  <Comment
                    key        = { comm.id }
                    id         = { comm.id }
                    avatar     = { comm.user.avatar }
                    username   = { comm.user.username }
                    date       = { dateFormat }
                    comment    = { comm.comment }
                    keyComment = { comm.keyComment }
                    keyMessage = { this.props.keyChildren }
                  />
                )
              }
          } else if ( this.state.pressComment === true ) {
            if ( index === 0) {
              if ( this.state.MessagesShow === 1 ) {
                return (
                  <a href = "#comments" key={ index } onClick={ this.handleMoreComments }> Ver { tamReal } { commentsLetters } más</a>
                )
              } else if ( this.state.MessagesShow > 1 ) {
                let newTamReal = (( tam - this.state.MessagesShow ) - 1);

                if ( newTamReal > 0 ) {
                  return (
                    <a href = "#comments" key={ index } onClick={ this.handleMoreComments }> Ver { newTamReal } { commentsLetters } más </a>
                  )
                }
              }

            } else if ( index > ( tamReal - this.state.MessagesShow ) ) {
              return (
                <Comment
                  key         = { comm.id }
                  id          = { comm.id }
                  avatar      = { comm.user.avatar }
                  username    = { comm.user.username }
                  date        = { dateFormat }
                  comment     = { comm.comment }
                  keyComment  = { comm.keyComment }
                  keyMessage  = { this.props.keyChildren }
                />
              )
            }
          }
            return true;
          })
        )
      }
    } else  {
    }

  }


  render () {
    return (
      <section className = "post-footer">
        <div className = "row">

          <div className = "elementLeft">
            <span className = "thumb-info-social-icons">
              <a href = "#like">
                <i className = "fa fa-thumbs-o-up"></i>
              </a>
              { renderLikesUser(this.props.likes_user) }
              <span className = "colorComments">{ this.props.likes }</span>
            </span>
          </div>

          <div className = "elementRight">
            <span className = "thumb-info-social-icons">
              <span className="colorComments"> { this.state.number_comments }  { this.props.textComments } </span>
            </span>
          </div>

        </div>

        <div className = "hrLine"></div>
        <div className = "post-footer-option container actionsTimeline">
          <ul className = "list-unstyled">
            <li>
              <a href="#pressLike" onClick = { this.onPressLike }> <i className = "fa fa-thumbs-o-up fa-2x"></i> <span className = "colorActions"></span> </a>
            </li>
            <li>
              <a href = "#pressComment" onClick = { this.handlePressComment }> <i className = "fa fa-comment-o fa-2x"></i> <span className = "colorActions"> Comentar </span> </a>
            </li>
            <li>
              <i
                className = "fa fa-facebook fa-2x"
                onClick   =
                {
                  () => shareFacebook(
                    this.props.url_post,
                    'Protics - Qué estamos haciendo!!',
                    this.props.description,
                    this.props.url[0]
                  )
                }
              >
              </i>
            </li>
          </ul>
        </div>

        <div className = " panel-white post panel-shadow">
          <div className = "post-footer">
            { this.renderInputComment() }
            { this.renderComments() }
          </div>
        </div>
      </section>
    )
  }
}

PictureCardFooter.propTypes = propTypes;

export default PictureCardFooter;
