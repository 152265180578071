import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FirebaseClass from "../../../classes/FirebaseClass";
import { dataUser } from '../../../actions/user';
import { valid_input } from '../../../functions/validationsParams';
import { _sweetAlert } from '../../../services/SweetAlert2';
import { handleMenuCollapse } from '../../../functions/MenuHeader';
import { isOnline, MessageConnection } from '../../../functions/NavigatorOnline';

let objFirebase = new FirebaseClass("Anónimo");

class FormLogIn extends Component {

  constructor () {
    super();

    this.handleLogIn = this.handleLogIn.bind(this);
    this.changeVisibility = this.changeVisibility.bind(this);
  }

  async handleLogIn ( evt ) {
    evt.preventDefault();

    if ( isOnline() === true ) {

      let email_login = document.getElementById('email_login').value.trim();
      let pass_login  = document.getElementById('pass_login').value.trim();

      if ( !email_login ) {
        valid_input( email_login, 'correo Electrónico' );
      } else {
        if ( !pass_login ) {
          valid_input( pass_login, 'Contraseña' );
        } else {
          let user = await objFirebase.AuthWithEmailAndPassword(email_login, pass_login);

          if ( user.response === 'success' ) {
            if ( user.emailVerified === true ) {
              this.props.dataUser( user );
            } else if ( user.emailVerified === false )  {
              _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'Debes verificar tu correo, para poder iniciar sesión', typeResponse: 'warning' });
            }
          } else if ( user.response === 'error' ) {
            if ( user.message.code === 'auth/wrong-password' ) {
              _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'El correo o la contraseña estan incorrectos', typeResponse: 'warning' });
            } else if ( user.message.code === 'auth/invalid-email' ) {
              _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'El correo esta mal formado', typeResponse: 'warning' });
            }
          }
        }
      }

    } else if ( isOnline() === false ) {
      MessageConnection();
    }

  }

  changeVisibility () {
    handleMenuCollapse();
  }

  render () {
    return (
      <form onSubmit = { this.handleLogIn }>

        <div className = "row">
          <div className = "form-group">
            <div className = "col-md-12">
              <label> Correo electrónico </label>
              <input type = "text" name = "email_login" id = "email_login" className = "form-control input-lg" />
            </div>
          </div>
        </div>

        <div className = "row">
          <div className = "form-group">
            <div className = "col-md-12">
              <label> Contraseña </label>
              <input type = "password" name = "pass_login" id = "pass_login" className = "form-control input-lg" />
            </div>
          </div>
        </div>

        <div className = "row">

          <div className = "col-md-5">
            <Link to = "/recover_password" onClick = { this.changeVisibility } >
              <span className = "pull-right"> No recuerdas tu contraseña </span>
            </Link>
          </div>

          <div className = "col-md-7">
            <input type = "submit" value = "Iniciar Sesión" className = "btn btn-primary" />
          </div>

        </div>

        <div className = "row">

          <div className = "col-md-12">
            <p className = "log-in-info">
              Ya tienes una cuenta?
              <Link to = "/signup" onClick = { this.changeVisibility } className = "colorHighlight"> Registrate </Link>
            </p>
          </div>

        </div>

      </form>
    )
  }
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    dataUser
  }, dispatch)
}

export default connect( null, mapDispatchToProps)(FormLogIn);
