import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';

const propTypes = {
  keyChildren        : PropTypes.string.isRequired,
  url                : PropTypes.string.isRequired,
  description        : PropTypes.string.isRequired,
  date               : PropTypes.number.isRequired,
  handleUpdatePostId : PropTypes.func.isRequired
}

const ItemPost = ({ keyChildren, url, description, date, handleUpdatePostId }) => {
  let descriptionShort = `${description.substring(0, 48)} ...`;

  return (
    <li onClick={ () => handleUpdatePostId(keyChildren) }>
      <div className="post-image">
        <div className="img-thumbnail">
          <a href = "#postImage" >
            <img src={ url } alt={ description } width="72px" height="72px" />
          </a>
        </div>
      </div>
      <div className="post-info">
        <a href = "#postDescr"> { descriptionShort } </a>
        <div className="post-meta">
          { moment(date).fromNow() }
        </div>
      </div>
    </li>
  )
}

ItemPost.propTypes = propTypes;

export default ItemPost;
