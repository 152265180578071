import firebase from 'firebase/app';

export function getProviderForProviderId (provi) {
  let provider = "";

  if ( provi === 'facebook.com' ) {
    provider = new firebase.auth.FacebookAuthProvider();
  } else if ( provi === 'google.com' ) {
    provider = new firebase.auth.GoogleAuthProvider();
  }

  return provider;

}

export function getPhotoURLProvider ( result ) {
  let photoURL = "";

  if ( result.additionalUserInfo.providerId === 'facebook.com' ) {
    photoURL = result.additionalUserInfo.profile.picture.data.url;
  } else if ( result.additionalUserInfo.providerId  === 'google.com' ) {
    photoURL = result.user.photoURL;
  }

  return photoURL;
}
