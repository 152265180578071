import React from 'react';
import PropTypes from 'prop-types';

import ItemPost from './ItemPost';

const propTypes = {
  posts_notifications : PropTypes.array.isRequired
}

const ListPosts = ({ posts_notifications }) => {
  return (
    posts_notifications.map( pon => {
      return (
        <ItemPost
          key           = { pon.Sys_GUID }
          post_key      = { pon.Sys_GUID }
          post_date     = { pon.createdAt }
          post_title    = { pon.post_title }
          post_content  = { pon.post_content }
          post_guid     = { pon.post_guid }
          post_name     = { pon.post_name }
        />
      )
    })
  )
}

ListPosts.propTypes = propTypes;

export default ListPosts;
