import React from 'react';
import PropTypes from 'prop-types';

import ShowProjects from './ShowProjects';

const propTypes = {
  id          : PropTypes.string.isRequired,
  name_client : PropTypes.string.isRequired,
  address     : PropTypes.string.isRequired
}

const InfoBoxContent = ({ id, name_client, address }) => {
  let link_projects = `/customer/${id}`;
  return (
    <div>
      <div className="infoBoxContent">
        <p>
          <span className="infoNameClient">{ name_client }</span>
        </p>
        <p>
          <span className="infoAddress">{ address }</span>
        </p>
      </div>
      <ShowProjects
        link_projects = { link_projects }
      />
    </div>
  )
}

InfoBoxContent.propTypes = propTypes;

export default InfoBoxContent;
