import React from 'react';

import LogoWeb from './LogoWeb';
import CurrentTimeClock from './CurrentTimeClock';
import SocialNetworks from './SocialNetworks';
import MenuNav from './MenuNav';
import ButtonCollapse from './ButtonCollapse';

const HeaderContainer = () => {
  return (
    <div className = "container">
      <LogoWeb />
      <CurrentTimeClock />
      <SocialNetworks />
      <MenuNav />
      <ButtonCollapse />
    </div>
  )
}

export default HeaderContainer;
