import React from 'react';
import LazyLoad from 'react-lazyload';

const CamerasDahua = () => {
   return (
      <div className = "backgroundCamerasDahua">
         <div className = "container ptxxxl pbxxxl">
            <div className = "row">

               <div className = "col-md-6">                  
                  <h1 className = "title_camera_dahua text-center">¡Seguridad y calma lo puedes obtener con dahua! </h1>
               </div>
               <div className = "col-md-6 text-center">
                  <LazyLoad>
                     <img className = "img-responsive" src = { process.env.REACT_APP_CAMERAS_DAHUA } alt = "protics partner dahua" />
                     <span className = "title_partner_dahua"> <i className = "fa fa-diamond color_diamond"></i>  Somos partner Dahua <i className = "fa fa-diamond color_diamond"></i>  </span>
                  </LazyLoad>
               </div>

            </div>
         </div>
      </div>
   )
};

export default CamerasDahua;