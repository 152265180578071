import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { customerInfoLocation } from '../../actions/customer_info';
import InfoCustomerPlace from './InfoCustomerPlace';

const propTypes = {
  placeKey : PropTypes.string.isRequired
}

class InfoCustomer extends Component {

  componentDidMount () {
    if ( this.props.customer_info_location === null ) {
      this.props.customerInfoLocation(this.props.placeKey);
    }
  }

  renderData () {
    if ( typeof(this.props.customer_info_location) !== 'undefined' && this.props.customer_info_location !== null ) {
      return ( <InfoCustomerPlace customer_info_location = { this.props.customer_info_location } /> );
    } else { return null }
  }

  render () {
    return ( this.renderData() )
  }
}

InfoCustomer.propTypes = propTypes;

function mapStateToProps ( state ) {
  return {
    customer_info_location: state.customer_info_location
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    customerInfoLocation
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(InfoCustomer);
