import React from 'react';

import { PROTICSMX } from '../../../constants/youtube';
import '../css/sidebar.css';

const Head = () => {
  return (
    <div className = "user-head">
      <span className = "inbox-avatar">
        <i className = "fa fa-youtube youtube_"></i>
      </span>
      <div className = "user-name">
        <h5 className = "tittleChannel"> { PROTICSMX }</h5>
      </div>
    </div>
  )
}

export default Head;
