export function showPosition () {
   if( navigator.geolocation ) {
     var timeoutVal = 10 * 1000 * 1000;
     navigator.geolocation.getCurrentPosition(
       displayPosition,
       displayError,
       { enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0 }
     );
   } else {
     alert("Lo sentimos, su navegador no es compatible con la geolocalización de HTML5.");
   }
}


function displayPosition(position) {
  sessionStorage.setItem('Latitude',position.coords.latitude);
  sessionStorage.setItem('Longitude', position.coords.longitude);
}

function displayError (error) {
  sessionStorage.setItem('Latitude', process.env.REACT_APP_LATITUDE_CENTRAL);
  sessionStorage.setItem('Longitude', process.env.REACT_APP_LONGITUDE_CENTRAL );
  var errors = {
    1: 'permiso denegado, ¡Te pondremos en un lugar central!',
    2: 'No pudimos encontrar tu ubicación :( , ¡Te pondremos en un lugar central!',
    3: 'Solicitud de tiempo de espera, no hemos podido encontrar tu ubicación'
  };

  if ( error.code === 1 ) {
    alert("Se ha denegado el permiso anteriormente, cambie la configuación de su navegador, para que se pueda solicitar de nuevo el permiso");
  } else if ( error.code !== '1' ) {
    alert("Error: " + errors[error.code]);
  }
}
