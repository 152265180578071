import React from 'react';
import { Link } from 'react-router-dom';

import Navegation from './Navegation';

const NotFound = () => {
  return (
    <div>
      <Navegation
        title = { "404 Página no encontrada" }
        url   = { "/" }
      />
      <div className="container">
        <section className="page-not-found">
          <div className="row">
            <div className="col-md-6 col-md-offset-1">
              <div className="page-not-found-main">
                <h2>404 <i className="fa fa-file"></i></h2>
                <p>Lo sentimos, pero la página que estabas buscando no existe.</p>
              </div>
            </div>
            <div className="col-md-4">
              <h4>Aquí hay algunos enlaces útiles</h4>
              <ul className="nav nav-list primary">
									<li><Link to="/">Inicio</Link></li>
									<li><Link to="/opinions">Opiniones</Link></li>
									<li><Link to="/timeline">Qué estamos haciendo</Link></li>
									<li><Link to="/about_us">Acerca de nosotros</Link></li>
									<li><Link to="/contact_us">Contactanos</Link></li>
								</ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default NotFound;
