export const setMessage = ( snapshot ) => {

  let message = {
    keyChildren: snapshot.key,
    category_service: {
      Sys_GUID: snapshot.val().category_service.Sys_GUID,
      categoryName: snapshot.val().category_service.categoryName,
      categoryId: snapshot.val().category_service.categoryId
    },
    description: snapshot.val().description,
    firstMessage: snapshot.val().firstMessage,
    id: snapshot.val().id,
    liked: false,
    likes: snapshot.val().likes,
    url: snapshot.val().url,
    partner: snapshot.val().partner,
    date: snapshot.val().date,
    increment: snapshot.val().increment,
    user: {
      avatar: snapshot.val().user.avatar,
      username: snapshot.val().user.username
    },
    likes_user: {},
    comments: snapshot.val().comments,
    number_comments: snapshot.val().number_comments,
    subcategory_service: {
      Sys_GUID: snapshot.val().subcategory_service.Sys_GUID,
      subcategoryName: snapshot.val().subcategory_service.subcategoryName,
      subcategoryId: snapshot.val().subcategory_service.subcategoryId
    },
    place: {
      key: snapshot.val().place.key,
      name: snapshot.val().place.name
    },
    service : {
      Sys_GUID : snapshot.val().service.Sys_GUID,
      serviceName : snapshot.val().service.serviceName,
      serviceId : snapshot.val().service.serviceId
    }
  }

  return message;

}
