import React from 'react';

import RecoverPassword from '../../components/Global/RecoverPassword';
import Footer from '../../components/Global/Footer';

const RecoverPasswordContainer = () => {
  return (
    <div>
      <RecoverPassword />
      <Footer />
    </div>
  )
}

export default RecoverPasswordContainer;
