import {
  GET_LIST_MEMBERS
} from '../constants';

import { actions_requests } from '../requests';
import ErrorRequest from '../requests/ErrorRequest';

const getMembers = payload => { return { type: GET_LIST_MEMBERS, payload }};

export const memberList = () => {

  return ( dispatch, getState ) => {
    let url_request = process.env.REACT_APP_API_MEMBERS;

    actions_requests(null, url_request, 'GET')
      .then( respo => {

        if ( respo.response === 'success' ) {
          dispatch(getMembers(respo.data));
        }

      })
      .catch( err => {
        ErrorRequest(err, null, 1);
      });
  }

}
