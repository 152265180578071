import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { allCustomersMap } from '../../../actions/customers_map';
import { MapWithAMarkerClusterer } from './MapWithAMarkerClusterer';
import IconsMarker from './IconsMarker';

class Customers extends Component {

  componentDidMount () {
    if ( this.props.customers_map === null ) {
      this.props.allCustomersMap();
    }
  }

  renderCustomersMap () {
    if ( typeof(this.props.customers_map) !== 'undefined' && this.props.customers_map !== null ) {
      return (
        <MapWithAMarkerClusterer
          markers = { this.props.customers_map }
        />
      )
    }
  }

  render () {
    return (
      <div>
        { this.renderCustomersMap() }
        <IconsMarker />
      </div>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    customers_map: state.customers_map
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    allCustomersMap
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(Customers);
