import React from 'react';
import { Link } from 'react-router-dom';

import Navegation from '../../components/Global/Navegation';
import './css/index.css';

const Knowledge = () => {
  return (
    <div>
      <Navegation
        title = "Knowledge"
        url   = "/knowledge"
      />
      <div className = "container">
        <div className = "col-md-12">
          <div className = "row">

            <div className = "col-md-6">
              <div className = "event">
                <div className = "event-body">
                  <div className = "event-image">
                    <img alt = "Contpaq" src = "https://res.cloudinary.com/dlaew2qjk/image/upload/v1549229326/assets/static/contpaq/logo-contpaqi.png" className = "img-responsive" />
                  </div>
                  <div className = "event-location"> Sistemas de Contpaq </div>
                  <div className = "event-title"> <span>Últimas Versiones </span> </div>
                  <div className = "event-details">
                    <Link to = "/downloads_contpaq"> Enlace </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Knowledge;
