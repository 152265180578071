import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './css/subscriber_notification.css';
import { _sweetAlert } from '../../services/SweetAlert2';

const propTypes = {
  title_subscriber_notification : PropTypes.string.isRequired,
  info_subscriber_notification  : PropTypes.string.isRequired
}

class SubscriberNotification extends Component {

  constructor () {
    super();

    let show_notification = localStorage.notifications || false;

    this.state = {
      show_notification : show_notification
    }

    this.modifyStateNotification  = this.modifyStateNotification.bind(this);
    this.handleSubscriber         = this.handleSubscriber.bind(this);
    this.modifyStateNotification  = this.modifyStateNotification.bind(this);

  }

  renderButtonNotifications () {
    if ( this.state.show_notification === "true" ) { //typeof(localStorage.notifications) !== 'undefined' && localStorage.notifications === "true"
      return ( <button type="button" className = "btn btn-noti-activate lettersButton" onClick = { () => this.handleCancelSubscription() }><i className = "fa fa-bell"></i> Notificaciones Activadas <i className = "fa fa-bell"></i> </button> );
    } else {
      return ( <button className = "btn btn-highlight-1 buttonText lettersButton" onClick = { () => this.handleSubscriber() }> <i className = "fa fa-bell"></i> Permitir Notificaciones <i className = "fa fa-bell"></i> </button> );
    }
  }

  handleSubscriber () {
    if ( window.navigator.serviceWorker ) {
      window.notificationsTurnedOff().then( respo => {
        if ( respo.response === 'success' ) {
          let show_notification = localStorage.notifications || false;
          this.setState({ show_notification });
        }
      });
    } else {
      _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Advertencia', messageResponse: 'Se necesita un navegador más moderno, para utilizar este servicio', typeResponse: 'warning' });
    }
  }

  handleCancelSubscription () {
    if ( window.navigator.serviceWorker ) {
      window.cancelSubscription();
      setTimeout( () => this.modifyStateNotification(), 2000);
    } else {
      _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Advertencia', messageResponse: 'Se necesita un navegador más moderno, para utilizar este servicio', typeResponse: 'warning' });
    }
  }

  modifyStateNotification () {
    let show_notification = localStorage.notifications || false;
    this.setState({ show_notification });
  }

  render () {
    return (
      <div>
        <div className = "backgroundSubscribeNotification">
          <div className = "container ptxxxl pbxxxl">
            <div className = "row">

              <div className = "col-md-6 text-center">
                <h2 className = "titleFacebook"> { this.props.title_subscriber_notification } </h2>
                <p className = "contentFacebook">{ this.props.info_subscriber_notification }</p>
              </div>

              <div className = "col-md-6 text-center">
                { this.renderButtonNotifications() }
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

SubscriberNotification.propTypes = propTypes;

export default SubscriberNotification;
