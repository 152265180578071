import {
  GET_SLIDE_DATA
} from '../constants';

import { actions_requests } from '../requests';

const getSlideData = payload => { return { type: GET_SLIDE_DATA, payload }};

export const getItemsSlide = () => {

  return ( dispatch, getState ) => {
    let url_request = process.env.REACT_APP_API_SLIDES_DATA;

    actions_requests(null, url_request, 'GET')
      .then( respo => {

        if ( respo.response === 'success' ) {
          dispatch(getSlideData(respo.data));
        }

      })
      .catch( err => {
      });
  }

}
