import React from 'react';
import { Link } from 'react-router-dom';

import { getCurrentYear } from '../../../functions/DateFormat';

const Copyright = () => {
  return (
    <div className="footer-copyright">
      <div className="container">
        <div className="row">
          <div className="col-md-1">
            <i className="logo">
              <img src={ process.env.REACT_APP_LOGO_WHITE } className="img-responsive" width="100px" height="100px" alt="logo"/>
            </i>
          </div>
          <div className="col-md-7">
            <p>© Copyright { getCurrentYear() }. Todos los derechos reservados</p>
            <p><Link to="/privacyPolicy"> Politica de Privacidad </Link> </p>
          </div>
          <div className="col-md-4">
            <nav id="sub-menu">
              <ul>
                <li><Link to="/contact_us">Contacto</Link></li>
                <li><Link to="/jobs">Trabajos</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Copyright;
