import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';

import ItemProject from './ItemProject';
import Navegation from '../../../components/Global/Navegation';
import { isEmptyJSON } from '../../../functions/ValidationState';

import './css/timeline.css';

const propTypes = {
  id: PropTypes.string.isRequired
}

class Projects extends Component {

  constructor (props) {
    super(props);

    this.state = { items_project : null }
  }

  componentWillMount () {
    let { id } = this.props;
    this.messagesRef = firebase.database().ref('/messages').orderByChild('place/key').equalTo(id);
    let items_project = [];
    this.messagesRef.on('child_added', snapshot => {
      items_project.push(snapshot.val());

      this.setState({
        items_project : items_project
      });
    });
  }

  componentWillUnmount () {
    this.messagesRef.off();
  }

  renderItems () {
    if ( isEmptyJSON(this.state.items_project) === true ) {
      return (
        <section className="timeline" id="timeline">
          <div className="timeline-body">
            {
              this.state.items_project.map( (ip, index) => {
                let class_position = "";
                if (index % 2 === 0) {
                  class_position = "timeline-box left";
                } else {
                  class_position = "timeline-box right";
                }
                return (
                  <ItemProject
                    key            = { ip.id }
                    class_position = { class_position }
                    id             = { ip.id }
                    date           = { ip.date }
                    url            = { ip.url }
                    category       = { ip.category }
                    subcategory    = { ip.subcategory }
                    description    = { ip.description }
                  />
                )
              })
            }
          </div>
        </section>
      )
    } else if ( isEmptyJSON(this.state.items_project) === false ) {
      return (
        <div className="text-center">
          <center>
            <img
              src       = "https://res.cloudinary.com/dlaew2qjk/image/upload/v1523555938/assets/static/people_working.jpg"
              className = "img-responsive"
              alt       = "protics-trabajando"
              width     = "300px;"
              height    = "300px;"
            />
          </center>
        </div>
      )
    }
  }

  renderCustomerName () {
    if ( isEmptyJSON(this.state.items_project) === true ) {
      return (
        <h1> Proyecto(s) realizados a { this.state.items_project[0].place.name } </h1>
      )
    } else if ( isEmptyJSON(this.state.items_project) === false ) {
      return (
        <h1> Realizando Proyectos </h1>
      )
    }
  }

  render () {
    return (
      <div>
        <Navegation
          title = { "Clientes" }
          url   = { "/customers_map" }
        />
        <div className="container">
          <div className="page-header text-center">
            { this.renderCustomerName() }
          </div>
          { this.renderItems() }
        </div>
      </div>
    )
  }
}

Projects.propTypes = propTypes;

export default Projects;
