import {
  GET_LIST_POST,
  GET_POSTS_INFINITY_SCROLL,
  UPDATE_FILTERS_HASHTAGS
} from '../constants';

const getListPost = payload => { return { type: GET_LIST_POST, payload }};
const getDataScrollInfinite = payload => { return { type: GET_POSTS_INFINITY_SCROLL, payload }};
const getDataFiltersHasgtags = payload => { return { type: UPDATE_FILTERS_HASHTAGS, payload }};

export const listPost = ( messages, info_user ) => {

  return ( dispatch, getState ) => {

    let likes;

    if ( typeof(info_user) !== 'undefined' && info_user !== null ) {
      if ( typeof(info_user.likes) !== 'undefined' ) {
        likes = info_user.likes;
      } else {
        likes = [];
      }
    } else {
      likes = [];
    }

    messages.user = Object.assign( {}, info_user, { likes } );

    dispatch( getListPost(messages) );
  }

}

export const scrollInfinitePosts = ( messages ) => {
  return ( dispatch, getState ) => {

    let increments = 0, user;

    if ( getState().posts_timeline === null ) {
      increments = 3;
      user = [];
    } else {
      increments = getState().posts_timeline.increments;
      user = getState().posts_timeline.user;
    }

    let newObj = Object.assign( {}, { messages }, { increments }, { user });
    dispatch( getDataScrollInfinite( newObj ));

  }
}

export const dataFilterHashtags = ( messages ) => {

  return ( dispatch, getState ) => {
    let increments = 0, user;

    if ( getState().posts_timeline === null ) {
      increments = 3;
      user = [];
    } else {
      increments = getState().posts_timeline.increments;
      user = getState().posts_timeline.user;
    }

    let newObj = Object.assign( {}, { messages }, { increments }, { user } );
    dispatch( getDataFiltersHasgtags(newObj) );
  }

}
