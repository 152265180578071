import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navegation from '../Global/Navegation';
import { listPostsNotifications } from '../../actions/posts_notifications';
import { isEmptyJSON } from '../../functions/ValidationState';
import Spinner from '../Global/Spinner';
import ContainerPosts from './ContainerPosts';

class PostsNotifications extends Component {

  componentDidMount () {
    if ( this.props.posts_notifications === null ) {
      this.props.listPostsNotifications();
    }
  }

  renderPostsNotifications () {
    if ( typeof(this.props.posts_notifications) !== 'undefined' && this.props.posts_notifications !== null ) {
      let posts;

      if ( isEmptyJSON(this.props.posts_notifications.posts_filter) === true ) {
        posts = this.props.posts_notifications.posts_filter;
      } else {
        posts = this.props.posts_notifications.posts_data;
      }

      posts = posts.slice(0, 6);

      return (
        <ContainerPosts posts_notifications = { posts } />
      )
    } else {
      return ( <Spinner /> );
    }
  }

  render () {
    return (
      <div>
        <Navegation
          title = "Notificaciones"
          url   = "/posts_notifications"
        />
        { this.renderPostsNotifications() }
      </div>
    )
  }

}

function mapStateToProps ( state ) {
  return {
    posts_notifications: state.posts_notifications
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    listPostsNotifications
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(PostsNotifications);
