import {
  GET_POSTS_NOTIFICATIONS,
  GET_POSTS_FILTER_PAGINATION
} from '../constants';

export const posts_notifications = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_POSTS_NOTIFICATIONS: {
      return action.payload;
    }
    case GET_POSTS_FILTER_PAGINATION: {
      return action.payload;
    }

    default:
      return state;

  }
}
