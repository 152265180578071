export const setLocalStorage = ( displayName, photoURL, email, providerId ) => {

  localStorage.setItem("userData_protics", JSON.stringify({
    displayName,
    photoURL,
    email,
    providerId
  }));

}

export const setRemoveLocalStorage = () => {
  localStorage.removeItem('userData_protics');
}

export const getUserLocalStorage = () => {
  return JSON.parse(localStorage.userData_protics);
}

export const getItemLocalStorage = ( _key ) => {
  if ( typeof(localStorage.userData_protics) !== 'undefined' ) {
    let value_final = "";
    JSON.parse(localStorage.userData_protics, ( key, value ) => {
      if ( key === _key ) {
        value_final = value;
      }
    });
    return value_final;
  }
}
