import swal from 'sweetalert2';

export const _sweetAlert = option => {

  if ( option.typeMessage === 'sweet_message' ) {
    return swal(option.titleResponse, option.messageResponse , option.typeResponse);
  }
}

export function _sweetConfirm ( options ) {
  swal({
      title: options.title,
      text: options.text,
      type: options.typeMessage,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: options.confirmText
    }).then((result) => {

      if (result.value === true ) {
        options._then();
      } else if ( result.dismiss === 'cancel' ) {
        swal('Cancelado', 'No se ha realizado ninguna operación', 'success');
      }
    });
}
