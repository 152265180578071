export const GET_SLIDE_DATA               = 'GET_SLIDE_DATA';
export const GET_WEBSITE_INFO             = 'GET_WEBSITE_INFO';
export const GET_CUSTOMERS                = 'GET_CUSTOMERS';
export const GET_INFO_CONTACT_COMPANY     = 'GET_INFO_CONTACT_COMPANY';
export const GET_LIST_TWEETS              = 'GET_LIST_TWEETS';
export const GET_LIST_HASHTAGS            = 'GET_LIST_HASHTAGS';
export const GET_LIST_MEMBERS             = 'GET_LIST_MEMBERS';
export const GET_INFO_CHANNEL_YOUTUBE     = 'GET_INFO_CHANNEL_YOUTUBE';
export const GET_PLAYLISTS                = 'GET_PLAYLISTS';
export const GET_VIDEOS_YOUTUBE           = 'GET_VIDEOS_YOUTUBE';
export const GET_VIDEOS_NEXT_PAGE         = 'GET_VIDEOS_NEXT_PAGE';
export const GET_VIDEOS_PREVIOUS_PAGE     = 'GET_VIDEOS_PREVIOUS_PAGE';
export const SET_VIDEO_PLAY               = 'SET_VIDEO_PLAY';
export const GET_FACEBOOK_RATINGS         = 'GET_FACEBOOK_RATINGS';
export const GET_USER                     = 'GET_USER';
export const GET_SYSTEMS_SOLUTIONS        = 'GET_SYSTEMS_SOLUTIONS';
export const GET_DETAILS_SOLUTION         = 'GET_DETAILS_SOLUTION';
export const GET_CUSTOMERS_MAP            = 'GET_CUSTOMERS_MAP';
export const GET_LIST_POST                = 'GET_LIST_POST';
export const GET_POSTS_INFINITY_SCROLL    = 'GET_POSTS_INFINITY_SCROLL';
export const GET_FILTER_SUBCATEGORIES     = 'GET_FILTER_SUBCATEGORIES';
export const UPDATE_FILTERS_SUBCATEGORIES = 'UPDATE_FILTERS_SUBCATEGORIES';
export const UPDATE_FILTERS_HASHTAGS      = 'UPDATE_FILTERS_HASHTAGS';
export const GET_INFO_CUSTOMER_MAP        = 'GET_INFO_CUSTOMER_MAP';
export const GET_POSTS_NOTIFICATIONS      = 'GET_POSTS_NOTIFICATIONS';
export const GET_INFO_ITEM_POST           = 'GET_INFO_ITEM_POST';
export const GET_POSTS_FILTER_PAGINATION  = 'GET_POSTS_FILTER_PAGINATION';
export const GET_LIST_JOBS                = 'GET_LIST_JOBS';
export const GET_DETAILS_PROFILE_JOB      = 'GET_DETAILS_PROFILE_JOB';
export const UPDATE_PROFILE_DOCUMENTS     = 'UPDATE_PROFILE_DOCUMENTS';
export const CLEAN_PROSPECT_PROFILE       = 'CLEAN_PROSPECT_PROFILE';
