import { isEmptyJSON } from '../functions/ValidationState';
import ErrorRequest from '../requests/ErrorRequest';
import {
  GET_PLAYLISTS
} from '../constants';

const getPlayLists = payload => { return { type: GET_PLAYLISTS, payload }};

export const playListYoutube = ( channelId ) => {

  return ( dispatch, getState ) => {
    let url_request = `${process.env.REACT_APP_API_YOUTUBE_PLAYLISTS}${channelId}&key=${process.env.REACT_APP_KEY_YOUTUBE}`;

    fetch(url_request, {
      method: 'GET'
    })
    .then( response => response.json() )
    .then( data => {
      if ( isEmptyJSON(data) === true ) {
        dispatch(getPlayLists(data));
      }
    })
    .catch( err => {
      ErrorRequest(err, null, 3);
    });
  }

}
