import React from 'react';
import PropTypes from 'prop-types';
import ReactStars from 'react-stars';

const propTypes = {
  average     : PropTypes.number.isRequired,
  total       : PropTypes.number.isRequired,
  starNumbers : PropTypes.array.isRequired
}

const InfoStars = ({ average, total, starNumbers }) => {

  if ( isNaN(average) === true ) {
    average = 0;
  }

  return (
    <div>
      <h4> Cantidad de estrellas </h4>
      <br />

      <div className = "container">
        <div className = "row">

          <div className = "col-md-3 text-center">
            <div className = "col-sm-12">
              <span className = "averageSize"> { average } </span>
              <br /><br />
              <ReactStars
                size   = { 24 }
                color2 = { '#ffd700' }
                value  = { average }
                edit   = { false }
              />
              <br />
              <small className="totalSize"> <i className="fa fa-user"></i> Basada en la opinión de { total } personas </small>
            </div>
          </div>

          <div className="col-md-2">
            <ul className="list-unstyled">
              { starNumbers }
            </ul>
          </div>

          <div className="col-md-7">
            <div className="text-center">
              <h5> Visualiza las opiniones en Facebook </h5>
              <a href = { process.env.REACT_APP_RATINGS_FACEBOOK } target="_blank" rel="noopener noreferrer" >
                <i className="fa fa-external-link fa-3x"></i>
              </a>
            </div>
          </div>


        </div>
      </div>

    </div>
  )

}

InfoStars.propTypes = propTypes;

export default InfoStars;
