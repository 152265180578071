import React from 'react';

import IconRepresentation from './IconRepresentation';
import './css/map.css';

const IconsMarker = () => {
  return (
    <div className="btnIcon">
      <IconRepresentation
        url   = { process.env.REACT_APP_MARKER_GROUP_CUSTOMER }
        alt_  = { "icon_group_customers" }
        title = { "Grupo Clientes" }
      />
      <IconRepresentation
        url   = { process.env.REACT_APP_MARKER_CUSTOMER }
        alt_  = { "icon_customers" }
        title = { "Cliente" }
      />
    </div>
  )
}

export default IconsMarker;
