import React from 'react';

import PostsNotifications from '../../components/PostsNotifications';
import Footer from '../../components/Global/Footer';

const PostsNotificationsContainer = () => {
  return (
    <div>
      <PostsNotifications />
      <Footer />
    </div>
  )
}

export default PostsNotificationsContainer;
