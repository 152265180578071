import React from 'react';
import { Link } from 'react-router-dom';

const LogoWeb = () => {
  return (
    <div className="logo">
      <Link to = "/" >
        <img
          src       = { process.env.REACT_APP_LOGO_BLACK }
          alt       = "Protics"
          width     = "200"
          height    = "60"
          className = "img-responsive"
        />
      </Link>
    </div>
  )
}

export default LogoWeb;
