export function splitbywords(variable, empieza, termina) {
  var s = variable+' ',
      largo = s.length,
      posStart = Math.max(0, empieza === 0 ? 0 : s.indexOf(' ', empieza)),
      posEnd = Math.max(0, termina > largo ? largo : s.indexOf(' ', termina)),
      cadena = s.substr(posStart, posEnd);
  if (cadena.charAt(cadena.length-1) === ',') {
      cadena = cadena.substr(0, cadena.length-1);
  }
  return cadena;
}

export function getYoutubeLikeToDisplay (millisec) {
  var seconds = (millisec / 1000).toFixed(0);
  var minutes = Math.floor(seconds / 60);
  var hours = "";
  if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = (hours >= 10) ? hours : "0" + hours;
      minutes = minutes - (hours * 60);
      minutes = (minutes >= 10) ? minutes : "0" + minutes;
  }

  seconds = Math.floor(seconds % 60);
  seconds = (seconds >= 10) ? seconds : "0" + seconds;
  if (hours !== "") {
      return hours + ":" + minutes + ":" + seconds;
  }
  return minutes + ":" + seconds;
}
