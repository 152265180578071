import React from 'react';
import Scrollchor from 'react-scrollchor';

import './css/list-items.css';

const ListItems = () => {
  return (
    <div>
        <div className="colorBackground">
          <h2>Política de Privacidad</h2>
          <h4 className="colorDateUpdate"> Esta política de privacidad se actualizó por última vez el  16 de agosto del 2018 </h4>
        </div>

        <h4> Indice </h4>
        <div className="col-md-6 offset-md-3">

          <ul className="timeline">
            <li> <Scrollchor to="#sectionOne" className="nav-link">Introducción.</Scrollchor> </li>
            <li> <Scrollchor to="#sectionTwo" className="nav-link">Cómo Contactarnos. </Scrollchor> </li>
            <li> <Scrollchor to="#sectionThree" className="nav-link">Que tipo de información recolectamos. </Scrollchor> </li>
            <li> <Scrollchor to="#sectionFour" className="nav-link">Cómo utilizamos la información.</Scrollchor> </li>
            <li> <Scrollchor to="#sectionFive" className="nav-link"> Servicios de terceros.</Scrollchor> </li>
          </ul>

        </div>

        <div className="col-md-6">
          <ul className="timeline">
            <li> <Scrollchor to="#sectionSix" className="nav-link">Seguridad.</Scrollchor> </li>
            <li> <Scrollchor to="#sectionSeven" className="nav-link">Niños. </Scrollchor></li>
            <li> <Scrollchor to="#sectionEight" className="nav-link">Enlaces a otras redes, servicios, páginas.</Scrollchor></li>
            <li> <Scrollchor to="#sectionNine" className="nav-link">Cómo consultar, actualizar o borrar su información.</Scrollchor></li>
            <li> <Scrollchor to="#sectionTen" className="nav-link">Opciones del usuario con respecto al uso de sus datos.</Scrollchor></li>
          </ul>
        </div>

    </div>
  )
}

export default ListItems;
