import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import Recomendation from './Recomendation';

const propTypes = {
  ratings : PropTypes.array.isRequired
}

const ListRecomendations = ({ ratings }) => {

  return (
    <div>
      {
        ratings.map( r => {
          return (
            <Recomendation
              key         = { uuid.v4() }
              opinion     = {  r.open_graph_story.message }
              date        = {  r.open_graph_story.start_time }
              numberStars = {  r.open_graph_story.data.rating.value }
              username    = {  '' } //r.s.name
              avatar      = {  '' } //r.reviewer.picture.data.url
            />
          )
        })
      }
    </div>
  )

}

ListRecomendations.propTypes = propTypes;

export default ListRecomendations;
