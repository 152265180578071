import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LazyLoad from 'react-lazyload';

import { websiteInfo } from '../../actions/website_info';
import InfoCompany from './InfoCompany/';
import Facebook from './Facebook';
import Company from './Company';
import Twitter from './Twitter';
import DataGeneral from './DataGeneral';
import CustomersPlace from './CustomersPlace';
import Youtube from './Youtube';
import Spinner from '../Global/Spinner';
import SubscriberNotification from './SubscriberNotification.js';
import Slide from './Slide';
import Cameras from './Cameras';
import CamerasDahua from './CamerasDahua';
import './css/info_company.css';
import './css/home.css';

class Home extends Component {

  componentDidMount () {

    if ( this.props.website_info === null ) {
      setTimeout( () => this.props.websiteInfo(), 2000);
    }

  }

  renderDataCompany () {
    if ( typeof(this.props.website_info) !== 'undefined' && this.props.website_info !== null ) {
      return (
        <div>
          <InfoCompany
            title_info_company  = { this.props.website_info[23].info_value }
            info_company_one    = { this.props.website_info[24].info_value }
            info_company_two    = { this.props.website_info[25].info_value }
            info_company_three  = { this.props.website_info[26].info_value }
            button_info_company = { this.props.website_info[27].info_value }
          />
          <LazyLoad>
            <SubscriberNotification
              title_subscriber_notification = { this.props.website_info[30].info_value }
              info_subscriber_notification  = { this.props.website_info[31].info_value }
            />
          </LazyLoad>
          <LazyLoad>
            <Cameras
              point_cameras_one   = {this.props.website_info[36].info_value }
              point_cameras_two   = {this.props.website_info[37].info_value }
              point_cameras_three = {this.props.website_info[38].info_value }
              point_cameras_four  = {this.props.website_info[39].info_value }
              title_cameras       = {this.props.website_info[40].info_value }
            />
          </LazyLoad>
          <LazyLoad>
            <CamerasDahua />
          </LazyLoad>
          <LazyLoad>
            <Facebook
              title_suscribe_facebook       = { this.props.website_info[21].info_value }
              description_suscribe_facebook = { this.props.website_info[22].info_value }
            />
          </LazyLoad>
          <LazyLoad>
            <Company
              description_company = { this.props.website_info[19].info_value }
              button_company      = { this.props.website_info[20].info_value }
            />
          </LazyLoad>
          <LazyLoad>
            <Twitter
              title_suscribe_twitter       = { this.props.website_info[28].info_value }
              description_suscribe_twitter = { this.props.website_info[29].info_value }
            />
          </LazyLoad>
          <LazyLoad>
            <DataGeneral
              title_mission       = { this.props.website_info[2].info_value }
              description_mission = { this.props.website_info[3].info_value }
              title_view          = { this.props.website_info[4].info_value }
              description_view    = { this.props.website_info[5].info_value }
              title_values        = { this.props.website_info[6].info_value }
              description_values  = { this.props.website_info[7].info_value }
            />
          </LazyLoad>
          <LazyLoad>
            <CustomersPlace
              title_clients = { this.props.website_info[18].info_value }
            />
          </LazyLoad>
          <LazyLoad>
            <Youtube
              title_suscribe_youtube       = { this.props.website_info[16].info_value }
              description_suscribe_youtube = { this.props.website_info[17].info_value }
            />
          </LazyLoad>
        </div>
      )
    } else {
      return (<Spinner />);
    }
  }

  render () {
    return (
      <div className="content">
        <Slide />
        { this.renderDataCompany() }
      </div>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    website_info: state.website_info
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    websiteInfo
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(Home);
