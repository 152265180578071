import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  short_name    : PropTypes.string.isRequired,
  photo         : PropTypes.string.isRequired,
  work_position : PropTypes.string,
  description   : PropTypes.string,
  email         : PropTypes.string
}

const ItemMember = ({ short_name, photo, work_position, description, email }) => {
  return (
    <li className="col-md-3 col-sm-6 col-xs-12">
      <div className="team-item thumbnail">
        <Link to = "/about_us" className="thumb-info team">
          <img alt={ short_name } src={ photo } />
          <span className="thumb-info-title">
            <span className="thumb-info-inner">{ short_name }</span>
            <span className="thumb-info-type">{ work_position }</span>
          </span>
        </Link>
        <span className="thumb-info-caption">
          <p>
            { description }
          </p>
          <p className="pEmail">{ email }</p>
        </span>
      </div>
    </li>
  )
}

ItemMember.propTypes = propTypes;

export default ItemMember;
