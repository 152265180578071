import React from 'react';
import Navegation from '../../components/Global/Navegation';

const NotAuthorized = () => {
  return (
    <div>
      <Navegation
        title = { 'Knowledge' }
        url   = { '/knowledge' }
      />
      <div className="main">
        <div className="container">
          <div className="row">
            <div className="col-md-12 center">
              <div className="logo">
                <span>
                  <img width="111" height="54" alt="Protics" src={ process.env.REACT_APP_LOGO_BLACK } />
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr className=""/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 center">
              <h1> Acceso no autorizado </h1>
              <p className="lead"> Para ingresar a este contenido es necesario ser parte de Protics. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotAuthorized;
