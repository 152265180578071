import React from 'react';
import PropTypes from 'prop-types';

import { getItemLocalStorage } from '../../functions/VLocalStorage';
import './css/inputComment.css';

const propTypes = {
  sendComment: PropTypes.func.isRequired,
  idButton   : PropTypes.string.isRequired,
  avatar     : PropTypes.string.isRequired
}
const InputComment = ({ sendComment, idButton, avatar }) => {
  return (
    <div>
      <img className="img-circle" src={ getItemLocalStorage('photoURL') } width="30px;" height="30px;" alt="img" />
      <input className="comment round-comment" name="comment" size="32" onKeyPress={ sendComment } id={ idButton } data-id={ idButton } placeholder="Escribe un comentario" type="text" />
    </div>
  )
}

InputComment.propTypes = propTypes;

export default InputComment;
