import React from 'react';
import PropTypes from 'prop-types';

import CustomerLogo from './CustomerLogo';
import CustomerData from './CustomerData';
import './css/info_customer_place.css';

const propTypes = {
  customer_info_location : PropTypes.object.isRequired
}

const InfoCustomerPlace = ({ customer_info_location }) => {
  return (
    <div className = "container">
      <CustomerLogo
        url_photo   = { customer_info_location.url_photo }
        name_client = { customer_info_location.name_client }
      />
      <hr className = "tall" />
      <CustomerData
        name_client  = { customer_info_location.name_client }
        notes        = { customer_info_location.notes }
        url_facebook = { customer_info_location.url_facebook }
        website      = { customer_info_location.website }
        latitude     = { customer_info_location.latitude }
        longitude    = { customer_info_location.longitude }
        address      = { customer_info_location.address }
        phone        = { customer_info_location.phone }
        email        = { customer_info_location.email } 
      />
    </div>
  )
}

InfoCustomerPlace.propTypes = propTypes;

export default InfoCustomerPlace;
