import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  tamDiv : PropTypes.string.isRequired,
  title  : PropTypes.string.isRequired,
  id     : PropTypes.string.isRequired
}

class InputForm extends Component {
  render () {
    let { tamDiv, title, id } = this.props;
    let classDiv = `col-md-${tamDiv}`;

    return (
      <div className={ classDiv }>
        <label>{ title }</label>
        <input type="text" className="form-control" name={ id } id={ id } autoComplete = { "off" } />
      </div>
    )
  }
}

InputForm.propTypes = propTypes;

export default InputForm;
