import React from 'react';

import Customers from '../../components/Home/Customers';
import Footer from '../../components/Global/Footer';

const CustomersContainer = () => {
  return (
    <div>
      <Customers />
      <Footer />
    </div>
  )
}

export default CustomersContainer;
