import React from 'react';

import './css/index.css';

const Header = () => {
  return (
    <div className="page-top custom-product">
      <div className="container">

        <div className="row">
          <div className="col-md-12 center">
            <h1>Políticas de Privacidad de <strong>Protics</strong> </h1>
            <p className="lead">
              La presente política de privacidad establece los términos en que Protics usa y protege
              la información que es proporcionada por sus usuarios al momento de utilizar el sitio web.
            </p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Header;
