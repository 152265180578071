import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PlayList from './PlayList';
import { videosYoutube } from '../../../actions/videos_youtube';
import { isOnline, MessageConnection } from '../../../functions/NavigatorOnline';

class SidebarContent extends Component {

  constructor () {
    super();

    this.handleVideosStart = this.handleVideosStart.bind(this);
  }

  handleVideosStart () {
    if ( isOnline() === true ) {
      let uploads = this.props.channel_youtube.items[0].contentDetails.relatedPlaylists.uploads;
      this.props.videosYoutube(8, uploads);
    } else if ( isOnline() === false ) {
      MessageConnection();
    }
  }

  render () {
    return (
      <ul className = "inbox-nav inbox-divider">
        <li className = "active">
          <a href="#sideber" onClick = { this.handleVideosStart }>
            <i className = "fa fa-bars fa-2x"></i>
            <span className = "pull-right"> Inicio </span>
          </a>
        </li>
        <ul className = "nav nav-pills nav-stacked labels-info inbox-divider">
          <li>
            <h4 className = "text-center"> Listas de Reproducción </h4>
          </li>
          <PlayList />
        </ul>
      </ul>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    channel_youtube : state.channel_youtube
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    videosYoutube
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(SidebarContent);
