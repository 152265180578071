import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { NewString } from '../../functions/ReplaceCharacter';
import './index.css';

const propTypes = {
   id_profile          : PropTypes.number.isRequired,
   uuid_profile        : PropTypes.string.isRequired,
   name_profile        : PropTypes.string.isRequired,
   location            : PropTypes.string.isRequired,
   status_point        : PropTypes.string.isRequired,
   description_profile : PropTypes.string.isRequired
}

const handleClicItem = ( evt ) => {

   if ( evt.target.tagName !== 'A' ) {

      let target = evt.target;
      let parent_node = target.parentNode;
      let div_profiles = document.getElementById('div_profiles').childNodes;
      let amountElements = div_profiles.length;
      let uuid_profile = evt.target.getAttribute('data-uuid_profile');

      let title_toggle = "";
      let content_toggle = "";

      if ( parent_node.className.includes('toggle active') ) {
         title_toggle = "toggle";
         content_toggle = "toggle-content";
      } else {
         title_toggle = "toggle active";
         content_toggle = "openContentJob";
      }

      parent_node.className = title_toggle;
      parent_node.childNodes[1].classList = content_toggle;

      for ( let i = 0; i < amountElements; i++ ) {

         if ( uuid_profile !== div_profiles[i].getAttribute('data-uuid_profile') ) {
            div_profiles[i].className = "toggle";
            div_profiles[i].childNodes[1].classList = "toggle-content";
         }

      }
      
   }

}

const ItemJob = ({ id_profile, uuid_profile, name_profile, location, status_point, description_profile }) => {
   return (
      <section className="toggle" onClick = { handleClicItem } data-uuid_profile = { uuid_profile }>
         <label data-uuid_profile = { uuid_profile } > { name_profile } </label>
         <div className="toggle-content">
            <p><strong> Ubicación :</strong> { location } - <strong> Estado Civil:</strong> { status_point }</p>
            <p>{ description_profile }</p>
            <p>
               <Link className = "btn btn-primary push-bottom" to = {`/job_details/${ id_profile }/${ NewString( name_profile, 1 ) }`}>Más Información</Link>
               <Link className = "btn btn-primary push-bottom" to = {`/apply_job/${ id_profile }/${ NewString( name_profile, 1 ) }`}> Aplicar para la vacante </Link>
            </p>
         </div>
      </section>
   )
}

ItemJob.propTypes = propTypes;

export default ItemJob;