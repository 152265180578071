import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  title_info_company  : PropTypes.string.isRequired,
  info_company_one    : PropTypes.string.isRequired,
  info_company_two    : PropTypes.string.isRequired,
  info_company_three  : PropTypes.string.isRequired,
  button_info_company : PropTypes.string.isRequired
}

const ContentText = ({ title_info_company, info_company_one, info_company_two, info_company_three, button_info_company }) => {
  return (
    <div className = "col-md-5 text-center">
      <h3 className="tittleInfo"> { title_info_company }</h3>
      <ul className = "list-unordered-alt type-neutral-11">
        <li className="liInfo">
          { info_company_one }
        </li>
        <li className="liInfo">
          { info_company_two }
        </li>
        <li className="liInfo">
        { info_company_three }
        </li>
      </ul>
      <br />
      <p>
        <Link to = "/opinions" className = "btn btn-highlight-1">
          <span className="buttonText">
            { button_info_company }
          </span>
        </Link>
      </p>

    </div>
  )
}

ContentText.propTypes = propTypes;

export default ContentText;
