import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import uuid from 'uuid';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { isEmptyJSON } from '../../../functions/ValidationState';

const propTypes = {
  jobs_pictures : PropTypes.array
}

const renderJobsPictures = jobs_pictures => {

   if ( isEmptyJSON( jobs_pictures) === true ) {
    return (
       jobs_pictures.map( (jb, index) => {
        let name_picture = `image${index}`;
        return (
          <div key = { uuid.v4() }>
            <img src={ jb.url } alt={ name_picture } />
          </div>
        )
      })
    )
  } else {
    return (
      <div className="text-center">
        <img src="https://res.cloudinary.com/dlaew2qjk/image/upload/v1523555938/assets/static/people_working.jpg" className="img-responsive" alt="protics-trabajando"/>
      </div>
    )
  }
}

const InfoBoxCarousel = ({ jobs_pictures }) => {
  let infinite_loop = true, auto_play = true;

  if (isEmptyJSON(jobs_pictures) === true ) {
    infinite_loop = true;
    auto_play = true;
  } else {
    infinite_loop = false;
    auto_play = false;
  }
  return (
    <Carousel
        showStatus  = { false }
        showThumbs  = { false }
        infiniteLoo = { infinite_loop }
        autoPlay    = { auto_play }
        className   = "carousel"
      >
      { renderJobsPictures(jobs_pictures) }
    </Carousel>
  )
}

InfoBoxCarousel.propTypes = propTypes;

export default InfoBoxCarousel;
