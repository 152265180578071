import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MessageAlert from '../../Global/MessageAlert';
import { _sweetAlert } from '../../../services/SweetAlert2';
import { displayName } from '../../../constants/users_info';
import { actions_requests } from '../../../requests';
import ErrorRequest from '../../../requests/ErrorRequest';
import { isOnline, MessageConnection } from '../../../functions/NavigatorOnline';

const propTypes = {
  title_newsletter : PropTypes.string.isRequired,
  info_newsletter  : PropTypes.string.isRequired
}

class Newsletter extends Component {

  constructor () {
    super();

    this.state = {
      empty_message  : false,
      status_message : "",
      title_message  : "",
      message_       : ""
    }

    this.SuscribeEmail = this.SuscribeEmail.bind(this);
    this.sendMailSubscribe = this.sendMailSubscribe.bind(this);
  }

  changeStateMessage ( empty_message, status_message, title_message, message_ ) {

    this.setState({
      empty_message,
      status_message,
      title_message,
      message_
    });

  }

  changeStateTime () {
    setTimeout( () => this.changeStateMessage( false, "", "", ""), 5000);
  }

  SuscribeEmail ( evt ) {
    evt.preventDefault();

    if ( isOnline() === true ) {
      let email = evt.target.newsletterEmail.value;

      if ( email !== '' ) {
        const body      = `email=${email}&name=${displayName()}`;
        let url_request = process.env.REACT_APP_API_SUSCRIBE;

        actions_requests(body, url_request, 'POST')
          .then( respo => {
            document.getElementById('newsletterEmail').value = "";

            if ( respo.response === 'success' ) {
              this.changeStateMessage(true, 'success', 'Excelente', respo.message);
              localStorage.setItem('newsletter', true);
              this.sendMailSubscribe(email);
            } else if ( respo.response === 'without changes' ) {
              this.changeStateMessage(true, 'info', 'Alerta', respo.message);
              this.changeStateTime();
            }

          })
          .catch( err => {
            ErrorRequest(err, this, 3);
            document.getElementById('newsletterEmail').value = "";
          });


      } else {
        _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Falta Información', messageResponse: 'Ingresa un correo electrónico', typeResponse: 'warning' });
      }
    } else if ( isOnline() === false ) {
      MessageConnection();
    }


  }

  sendMailSubscribe ( email ) {
    setTimeout( () => this.changeStateMessage(false, "", "", ""), 5000 );
    const body = `from_name=Protics&to_email=${email}&subject_message=Hola&subject_name=${displayName()}&option_template=suscriber`;
    let url_request = process.env.REACT_APP_API_SENDEMAIL;
    actions_requests(body, url_request, 'POST')
      .then( respo => {
      })
      .catch( err => {
        ErrorRequest(err, this, 3);
      });

  }


  renderMessage () {

    let { empty_message, status_message, title_message, message_ } = this.state;
    
    if ( empty_message === false) {
      return null;
    } else if ( empty_message === true ) {
      return (
        <MessageAlert
          title_message  = { title_message }
          message        = { message_ }
          status_message = { status_message }
        />
      )
    }
  }


  render () {
    return (
      <div className = "newsletter">
        <h4> { this.props.title_newsletter } </h4>
        <p> { this.props.info_newsletter } </p>

        <div id = "divMessageEmail">
          { this.renderMessage() }
        </div>

        <form id = "newsletterForm" onSubmit = { this.SuscribeEmail }>
          <div className = "input-group">
            <label htmlFor="newsletterEmail"></label>
            <input className = "form-control" type = "email" placeholder = "Correo Electrónico" name = "newsletterEmail" id = "newsletterEmail" aria-label = "Correo Electrónico" />
            <span className = "input-group-btn">
              <button className = "btn btn-default" type = "submit"> Ir! </button>
            </span>
          </div>
        </form>

      </div>
    )
  }
}

Newsletter.propTypes = propTypes;

export default Newsletter;
