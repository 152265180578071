import React from 'react';
import Clock from 'react-live-clock';

import { setDate } from '../../../functions/DateFormat';
import './css/clock.css';

const CurrentTimeClock = () => {
  return (
    <div className="search">
      <span className="dateFormat"> { setDate() }</span>
      <br />
      <Clock
        className = { "hourFormat" }
        format    = { "h:mm:ss A" }
        ticking   = { true }
      />
    </div>
  )
}

export default CurrentTimeClock;
