import { PROTICSMX } from '../constants/youtube';
import { isEmptyJSON } from '../functions/ValidationState';
import ErrorRequest from '../requests/ErrorRequest';
import {
  GET_INFO_CHANNEL_YOUTUBE
} from '../constants';

const getChannel = payload => { return { type: GET_INFO_CHANNEL_YOUTUBE, payload }};

export const channelYoutubeInfo = () => {

  return ( dispatch, getState ) => {
    let url_request = `${process.env.REACT_APP_API_YOUTUBE_CHANNEL}${PROTICSMX}&key=${process.env.REACT_APP_KEY_YOUTUBE}`;

    fetch(url_request, {
      method: 'GET'
    })
    .then( response => response.json() )
    .then( data => {
      if ( isEmptyJSON(data) === true ) {
        dispatch(getChannel(data));
      }
    })
    .catch( err => {
      ErrorRequest(err, null, 1);
    });

  }

}
