import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeVideoPlay } from '../../actions/videos_youtube';
import { splitbywords } from './FunctionsYoutube';
import MiniatureVideo from './MiniatureVideo';
import StatisticsVideo from './StatisticsVideo';
import { isOnline, MessageConnection } from '../../functions/NavigatorOnline';
import './css/miniature.css';

const propTypes = {
  videos_youtube : PropTypes.object.isRequired,
  onClassVideo   : PropTypes.bool.isRequired
}

class Videos extends Component {

  constructor () {
    super();

    this.handleViewVideo = this.handleViewVideo.bind(this);

  }


  handleViewVideo ( evt ) {

    if ( isOnline() === true ) {
      let urlVideo = evt.target.getAttribute('data-url');
      let idVideo  = evt.target.getAttribute('data-video_id');
      let descripVideo = evt.target.getAttribute('data-descrip');
      this.props.changeVideoPlay( urlVideo, idVideo, descripVideo );
    } else if ( isOnline() === false ) {
      MessageConnection();
    }

  }

  setClassVideo () {
    if ( this.props.onClassVideo === false ) {
      return "";
    } else if ( this.props.onClassVideo === true ) {
      return "col-lg-3 col-sm-4 col-xs-6 tamVideo";
    }
  }

  renderVideos () {
    if ( typeof(this.props.videos_youtube) !== 'undefined' && this.props.videos_youtube !== null ) {
      return (
        <ul className = "list-unstyled video-list-thumbs row">
          {
            this.props.videos_youtube.videos.map( vy => {
              let url = `${process.env.REACT_APP_EMBED}${vy.id}`;
              let title_short = splitbywords(vy.snippet.title, 0, 50);
              return (
                <li className = { this.setClassVideo() } key = { vy.id }>
                  <span title = { vy.snippet.title }>
                    <MiniatureVideo
                      key             = { vy.id }
                      tamDiv          = "col-md-3"
                      thumbnails      = { vy.snippet.thumbnails.medium.url }
                      url             = { url }
                      videoId         = { vy.id }
                      title           = { vy.snippet.title }
                      description     = { vy.snippet.description }
                      handleViewVideo = { this.handleViewVideo }
                    />
                    <StatisticsVideo
                      title_short = { title_short }
                      duration    = { vy.contentDetails.duration }
                      viewCount   = { vy.statistics.viewCount }
                      publishedAt = { vy.snippet.publishedAt }
                    />
                  </span>
                </li>
              )
            })
          }
        </ul>
      )
    } else {
      return null;
    }
  }

  render () {
    return (
      this.renderVideos()
    )
  }
}

Videos.propTypes = propTypes;

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    changeVideoPlay
  }, dispatch)
}

export default connect( null, mapDispatchToProps)(Videos);
