import React from 'react';
import PropTypes from 'prop-types';

import ContentImage from './ContentImage';
import ContentText from './ContentText';
import './css/info.css';

const propTypes = {
  title_info_company   : PropTypes.string.isRequired,
  info_company_one     : PropTypes.string.isRequired,
  info_company_two     : PropTypes.string.isRequired,
  info_company_three   : PropTypes.string.isRequired,
  button_info_company  : PropTypes.string.isRequired
}

const InfoCompany = ({ title_info_company, info_company_one, info_company_two, info_company_three, button_info_company }) => {
  return (
    <div className = "">
      <div className = "backgroundInfoCompany" >
        <div className = "container ptxxxl pbxxxl">
          <div className = "row">

            <ContentImage />
            <ContentText
              title_info_company  = { title_info_company }
              info_company_one    = { info_company_one }
              info_company_two    = { info_company_two }
              info_company_three  = { info_company_three }
              button_info_company = { button_info_company }
            />

          </div>
        </div>
      </div>
    </div>
  )
}

InfoCompany.propTypes = propTypes;

export default InfoCompany;
