import {
  GET_VIDEOS_YOUTUBE,
  GET_VIDEOS_NEXT_PAGE,
  GET_VIDEOS_PREVIOUS_PAGE,
  SET_VIDEO_PLAY
} from '../constants';

export const videos_youtube = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_VIDEOS_YOUTUBE: {
      return action.payload;
    }

    case GET_VIDEOS_NEXT_PAGE : {
      return action.payload;
    }

    case GET_VIDEOS_PREVIOUS_PAGE : {
      return action.payload;
    }

    case SET_VIDEO_PLAY : {
      return action.payload;
    }

    default:
      return state;

  }
}
