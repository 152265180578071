import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title_message  : PropTypes.string,
  status_message : PropTypes.string,
  message        : PropTypes.string,
}

const MessageAlert = ({ title_message, message, status_message }) => {
  let status = `alert alert-${status_message}`;
  return (
    <div className={ status }>
      <strong>{ title_message }</strong> { message }
    </div>
  )
}

MessageAlert.propTypes = propTypes;

export default MessageAlert;
