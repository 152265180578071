import {
  GET_LIST_POST,
  GET_POSTS_INFINITY_SCROLL,
  UPDATE_FILTERS_HASHTAGS
} from '../constants';

export const posts_timeline = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_LIST_POST: {
      return action.payload;
    }

    case GET_POSTS_INFINITY_SCROLL: {
      return action.payload;
    }

    case UPDATE_FILTERS_HASHTAGS : {
      return action.payload;
    }

    default:
      return state;

  }
}
