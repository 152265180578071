import { _headers } from '../constants/headers';

export const getHeader = typeHeader => {

  if ( typeHeader === null ) {
    return _headers();
  }

}

export const getResponse = ( data, resolve, reject ) => {
  switch( data.response ) {

    case 'Forbidden':
      reject(data);
    break;

    case 'without permission':
      reject(data);
    break;

    case 'success':
      resolve(data);
    break;

    case 'Invalid Token':
      reject(data);
    break;

    case 'error':
      reject(data);
    break;

    case 'Unauthorized':
      reject(data);
    break;

    case 'Not Found':
      reject(data);
    break;

    case 'duplicate':
      reject(data);
    break;

    case 'without changes':
      resolve(data);
    break;

    case 'Bad Request':
      reject(data);
    break;

    case 'Token Valid':
      resolve(data);
    break;

    default:
      resolve({ response: 'without response', message: `There's no answer` });
    break;
  }
}

export const actions_requests = ( body, url_request, method, typeHeader = null ) => {
  let headers = getHeader(typeHeader);

  return new Promise( ( resolve, reject ) => {
    fetch(url_request, {
      method,
      headers,
      body
    })
    .then( response => response.json())
    .then( data => {
      getResponse( data, resolve, reject );
    });
  });
}
