import { _sweetAlert } from '../services/SweetAlert2';

export const isOnline = () => {

  if ( navigator.onLine ) {
    return true;
  } else {
    return false;
  }

}

export const MessageConnection = () => {
  _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'No hay conexión a Internet', messageResponse: 'Esta acción estará disponible cuando tengas conexión a internet', typeResponse: 'warning' });
}
