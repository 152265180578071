import React from 'react';
import PropTypes from 'prop-types';

import FiveImages from './FiveImages';
import PlusImages from './PlusImages';

const propTypes = {
  images : PropTypes.array.isRequired
}

const TypeCard = ({ images }) => {

  var tam = images.length;
  var moreImages = 0;
  var url = images;
  var photosMore = [];

  if (tam > 5) {
    moreImages = tam - 5;
    for (var i = 5; i < tam; i++) {
      photosMore.push(
        url[i]
      );
    }
  }

  var photos_ = [];

  if ( tam === 2 ) {
    photos_ = [
      {src: images[0], width:1, height:1},
      {src: images[1], width:1, height:1}
    ];
  } else if ( tam === 3 ) {
    photos_ = [
      {src: images[0], width: 4, height: 3},
      {src: images[1], width: 1, height: 1},
      {src: images[2], width: 3, height: 3}
    ];
  } else if ( tam === 4 ) {
    photos_ = [
      {src: images[0], width: 4, height: 3},
      {src: images[1], width: 1, height: 1},
      {src: images[2], width: 3, height: 4},
      {src: images[3], width: 1, height: 1}
    ];
  } else if ( tam >= 5 ) {
    photos_ = [
      {src: images[0], width:4, height:3},
      {src: images[1], width:1, height:1},
      {src: images[2], width:3, height:4},
      {src: images[3], width:2, height:2}
    ];
  }

  if( tam === 1 ) {
    return (
      <img className="img-responsive" src={ images[0] } alt="img"  width="1000px"/>
    );
  } else if( tam === 2 || tam === 3 || tam === 4 || tam === 5 ) {
    return (
      <FiveImages
        photos = { photos_ }
      />
    );
  } else if ( tam > 5 ) {
    return (
      <div>
        <PlusImages
          photos     = { photos_ }
          photosMore = { photosMore }
          moreImages = { moreImages }
        />
      </div>
    )
  }
}

TypeCard.propTypes = propTypes;

export default TypeCard;
