import React from 'react';
import Navegation from '../Global/Navegation';

import Contact from './Contact';
import SharedUbication from '../Global/SharedUbication';

const ContactUs = () => {
  return (
    <div>
      <Navegation
        title = { 'Contáctanos' }
        url   = { '/contact_us' }
      />
      <div className = "container">
        <div className = "form-group">
          <div className = "row">
            <SharedUbication />
          </div>
        </div>
      </div>
      <br />
      <Contact />
    </div>
  )
}

export default ContactUs;
