import {
  GET_FACEBOOK_RATINGS
} from '../constants';

import ErrorRequest from '../requests/ErrorRequest';
import { isEmptyJSON } from '../functions/ValidationState';

const getFacebookRatings = payload => { return { type: GET_FACEBOOK_RATINGS, payload }};

const getStars = ( ratings ) => {
  let stars = [];

  ratings.map( r => {
    stars.push( r.open_graph_story.data.rating.value );
    return true;
  });

  return stars;
}

export const facebookRatings = () => {

  return ( dispatch, getState ) => {
    let url_request = process.env.REACT_APP_API_FACEBOOK_RATINGS;

    fetch( url_request, {  method: 'GET' })
      .then( response => response.json() )
      .then( info => {

        if ( ( typeof(info.data) !== 'undefined'  && isEmptyJSON(info.data) === true ) ) {

          let obj = Object.assign( {}, { ratings: info.data }, { stars: getStars(info.data) } );
          dispatch( getFacebookRatings(obj));

        }

      })
      .catch( err => {
        ErrorRequest(err, null, 3);
      });

  }

}
