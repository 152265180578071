import {
  GET_FACEBOOK_RATINGS
} from '../constants';

export const facebook_ratings = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_FACEBOOK_RATINGS: {
      return action.payload;
    }

    default:
      return state;

  }
}
