import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PictureCard from './PictureCard';
import RecentPosts from './RecentPosts';
import Navegation from '../../components/Global//Navegation';
import { isEmptyJSON } from '../../functions/ValidationState';
import { getItemLocalStorage } from '../../functions/VLocalStorage';
import TimeLineClass from "../../classes/TimeLineClass";
import { dataFilterHashtags } from '../../actions/timeline';

let objTimeLine = new TimeLineClass();

const propTypes = {
  keyChildren : PropTypes.string.isRequired
}

class DetailPost extends Component {

  constructor (props) {
    super(props);

    this.state = {
      message : null,
      PostId : this.props.keyChildren
    }

    this.handleUpdatePostId = this.handleUpdatePostId.bind(this);
    this.handleLike = this.handleLike.bind(this);
    this.handleFilterHashtag = this.handleFilterHashtag.bind(this);
  }

  componentWillMount () {
    this.loadMessages(this.state.PostId);
  }

  loadMessages ( PostId ) {
    this.firebaseRef = firebase.database().ref(`messages/${PostId}`);

    this.firebaseRef.on('value', snapshot => {
      this.setState({ message: snapshot.val() });
    });
  }

  componentWillUnmount () {
    this.firebaseRef.off();
  }

  handleUpdatePostId (PostId) {
    this.loadMessages(PostId);
  }

  handleLike ( msgId ) {
    let numLikes = 0;
    firebase.database().ref('messages').orderByChild("id").equalTo(msgId).on('child_added', (snapshot) => {

      if ( snapshot.hasChild('likes_user') === false ) {
        numLikes+=1;
        this.handleUpdateLike(this.state.PostId, numLikes);
      } else {
        if (this.handleCheckUser(this.state.PostId).length === 0) {
          numLikes = snapshot.val().likes + 1;
          this.handleUpdateLike(this.state.PostId, numLikes);
        } else {
          console.log(" Sólo puedes dar un clic por imágen");
        }
      }
    });
  }

  handleUpdateLike (keyChildren, numLikes) {
    firebase.database().ref(`messages/${keyChildren}`).update({ likes: numLikes, liked: true });

    var newData = {
      date: Date.now(),
      liked: true,
      email: getItemLocalStorage('email'),
      displayName: getItemLocalStorage('displayName')
    }

    firebase.database().ref(`messages/${keyChildren}`).child('likes_user').push(newData);
  }

  handleCheckUser (keyChildren) {
    var valor = [];
    firebase.database().ref(`messages/${keyChildren}`).child('likes_user').orderByChild('email').equalTo(this.props.info_user.email).on('child_added', (sna) => {
      valor = sna.val().email;
    });

    return valor;
  }

  async loadMessagesHashtags ( hashtag, numElements ) {

    this.hashtagsRef = firebase.database().ref().child("hashtags").orderByChild("text").equalTo(hashtag).limitToLast(numElements);
    this.messageRef  = firebase.database().ref().child("messages").orderByChild("id");
    let messages = await objTimeLine.FilterHashtagLoadMessages( this.hashtagsRef, this.messageRef );
    this.props.dataFilterHashtags( messages );

  }

  handleFilterHashtag ( evt ) {

    let hashtag = evt.target.getAttribute('data-hashtag');
    sessionStorage.removeItem('children');
    sessionStorage.removeItem('childConcept');
    sessionStorage.setItem("hashtag_filter", hashtag);

    this.loadMessagesHashtags( hashtag, 3);

  }

  renderPictureCard () {
    if ( isEmptyJSON( this.state.message) === true ) {
      let { message } = this.state;
      return (
        <PictureCard
          id                  = { message.id }
          category            = { message.category_service.categoryName }
          datePublication     = { message.date }
          description         = { message.description }
          liked               = { message.liked }
          likes               = { message.likes }
          url                 = { message.url }
          avatar              = { message.user.avatar }
          username            = { message.user.username }
          p_username          = { message.user.username }
          keyChildren         = { this.state.PostId }
          number_comments     = { message.number_comments }
          partner             = { message.partner }
          subcategory         = { message.subcategory_service.subcategoryName }
          place               = { message.place.name }
          placeKey            = { message.place.key }
          firstMessage        = { message.firstMessage }
          service             = { message.service.serviceName }
          handleLike          = { () => this.handleLike( message.id ) }
          handleFilterHashtag = { this.handleFilterHashtag }
        />
      )
    } else if ( isEmptyJSON(this.state.message) === false ) {
      return null;
    }
  }

  render () {
    return (
      <div>
        <Navegation
          title = { 'Qué estamos haciendo' }
          url   = { '/timeline' }
        />
        <div className = "container">
          <div className = "row">
            <div className = "col-lg-12">
              <div className = "col-lg-2"></div>

              <div className = "col-lg-6">
                { this.renderPictureCard() }
              </div>

              <div className = "col-lg-4">
                <RecentPosts
                  keyChildren        = { this.props.keyChildren }
                  handleUpdatePostId = { this.handleUpdatePostId }
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

DetailPost.propTypes = propTypes;

function mapStateToProps ( state ) {
  return {
    info_user : state.info_user
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    dataFilterHashtags
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(DetailPost);
