import React from 'react';

import Tutorials from '../../components/Tutorials';
import Footer from '../../components/Global/Footer';

const TutorialsContainer = () => {
  return (
    <div>
      <Tutorials />
      <Footer />
    </div>
  )
}

export default TutorialsContainer;
