import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  link_projects : PropTypes.string.isRequired
}

const ShowProjects = ({ link_projects }) => {
  return (
    <div className="infoBoxFooter">
      <Link to={ link_projects }>
        <button type="button" className="btn btn-primary"> Ver Proyectos </button>
      </Link>
    </div>
  )
}

ShowProjects.propTypes = propTypes;

export default ShowProjects;
