import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import './css/cameras.css';

const propTypes = {
  point_cameras_one   : PropTypes.string.isRequired,
  point_cameras_two   : PropTypes.string.isRequired,
  point_cameras_three : PropTypes.string.isRequired,
  point_cameras_four  : PropTypes.string.isRequired,
  title_cameras       : PropTypes.string.isRequired
}

const Cameras = ({ point_cameras_one, point_cameras_two, point_cameras_three, point_cameras_four, title_cameras }) => {
  return (
    <div className = "backgroundCameras">
      <div className = "container ptxxxl pbxxxl">
        <div className = "row">

          <div className = "col-md-6 text-center">
            <LazyLoad>
              <img className = "img-responsive" src = { process.env.REACT_APP_CAMERAS_HIKVISION } alt = "protics partner hikvision" />
            </LazyLoad >
            <span className = "title_partner"> <i className = "fa fa-diamond color_diamond"></i> { point_cameras_one } <i className = "fa fa-diamond color_diamond"></i>  </span>
          </div>

          <div className = "col-md-6">
            <h1 className = "titleCameras text-center"> { title_cameras }  </h1>
            <ul className = "list-unstyled pointsCameras text-center">
              <li>
                <i className = "fa fa-diamond color_diamond"></i> { point_cameras_two } <i className = "fa fa-diamond color_diamond"></i>
              </li>
              <li>
                <i className = "fa fa-diamond color_diamond"></i> { point_cameras_three } <i className = "fa fa-diamond color_diamond"></i>
              </li>
              <li>
                <i className = "fa fa-diamond color_diamond"></i> { point_cameras_four } <i className = "fa fa-diamond color_diamond"></i>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  )
}

Cameras.propTypes = propTypes;

export default Cameras;
