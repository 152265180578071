import React from 'react';

import { handleMenuCollapse } from '../../../functions/MenuHeader';

const ButtonCollapse = () => {
  return (
    <button
      type        = "button"
      aria-label  = "Boton para mostrar el menú"
      className   = "btn btn-responsive-nav btn-inverse"
      name        = "btnMenuCollapse"
      id          = "btnMenuCollapse"
      data-toggle = "collapse"
      data-target = ".nav-main-collapse"
      onClick     = { handleMenuCollapse }
    >
      <i className = "fa fa-bars"></i>
    </button>
  )
}

export default ButtonCollapse;
