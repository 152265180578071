export const profiles_data = ( data, details, url_curriculum, public_id_curriculum, url_curp,
   public_id_curp, url_proof_address, public_id_proof_address, url_ife, public_id_ife,
   title_curriculum, title_curp, title_proof_address, title_ife ) => (
   Object.assign(
      { data },
      { details },
      { url_curriculum },
      { public_id_curriculum },
      { url_curp },
      { public_id_curp },
      { url_proof_address },
      { public_id_proof_address },
      { url_ife },
      { public_id_ife },
      { title_curriculum },
      { title_curp },
      { title_proof_address },
      { title_ife }
   )
);

export const initStateProfiles = () => (
   Object.assign({
      data: [],
      details: [], 
      url_curriculum: '',
      public_id_curriculum: '',
      url_curp: '',
      public_id_curp: '',
      url_proof_address: '',
      public_id_proof_address : '',
      url_ife : '',
      public_id_ife : '',
      title_curriculum : 'Curriculum',
      title_curp : 'Curp',
      title_proof_address : 'Comprobante de domicilio',
      title_ife : 'IFE'
   })
);