import React from 'react';
import { Link } from 'react-router-dom';

import './css/politics.css';

const Politics = () => {
  return (
    <div className="col-md-12">

      <section id="sectionOne">
        <h2> <span className="numberColor"> 1. </span> Introducción. </h2>
        <p className="tall">
          Bienvenido a Protics un servicio en Internet (“El servicio” o el “Sitio Web”) proveído por
          protics.com.mx. Respetamos su privacidad y queremos que sepa qué información recolectamos sobre usted.
        </p>
      </section>

      <section id="sectionTwo">
        <h2> <span className="numberColor"> 2. </span> Cómo contactarnos </h2>
        <p className="tall">
          Si tiene alguna pregunta sobre esta Política de Privacidad, por favor contáctenos a <a href="mailto:info@protics.com.mx">info@protics.com.mx</a>.
          Si quisiera contactar a Protics por alguna otra razón de alguna forma más tradicional,
          puede visitarnos en Av. Andrés Quintana Roo #357 Col. Martinez Ross.
        </p>
      </section>

      <section id="sectionThree">
        <h2> <span className="numberColor"> 3. </span> Que tipo de información recolectamos.</h2>
        <p className="tall">
          Dependiendo de cómo se navegue en el sitio web podemos recopilar diferentes datos del usuario.
          A continuación se muestra los puntos de como recopilamos la información.
        </p>

        <div>
          <p className="alert alert-info"> <strong>Información de registro: </strong>
          No se requiere que usted nos dé a nosotros ningún tipo de información personal para acceder a
          nuestro sitio web. Sin embargo podemos ofrecerle la forma de registrarse con nosotros o suscribirte
          a nuestra lista de correo. </p>
        </div>

        <div>
          <p className="alert alert-info"> <strong>Datos sobre las cuentas de usuario en otros servicios: </strong>
          Podremos obtener determinada información a través de las redes sociales ( Google, Facebook ). Si el usuario
           inicia sesión en Protics a través de una de las redes sociales mencionada, solicitaremos su permiso para
           acceder a determinada información de su cuenta: El nombre de usuario, la foto de perfil, el número de ID
           de la cuenta, la dirección de correo de inicio de sesión. Dichas plataformas o servicios ponen la
           información a nuestra disposición a través de su API. La información que recibimos depende de la
           información que el usuario ( a través de su configuración de privacidad) decida proporcionarnos.
           Si el usuario accede a través de una red social, la recopilación, el uso y el uso compartido de los datos
            del usuario también estarán sujetos a las políticas de privacidad y a otros acuerdos de dichos terceros.</p>
        </div>

        <div className="alert alert-info">
          <strong>Recolección de datos: </strong>
          Usted podrá visitar nuestro website de forma anónima. No obstante aunque se haya registrado o no.
          protics.com.mx puede recolectar cierta información para el uso de nuestros servicios, por ejemplo: La
          localización geográfica de donde usted está accediendo, esto para poder indicar en un mapa que tan
          cerca está de nuestra oficina. La mayoría de los navegadores web permiten que usted active la
          geolocalización y para darnos ese permiso usted recibirá una notificación que verá en el sitio web.
           Note que si usted bloquea esta función quizás pueda afectar el funcionamiento. Podremos obtener y
           almacenar  las opiniones y comentarios que nos dejes acerca de la empresa y los servicios que hacemos.
        </div>

      </section>


      <section id="sectionFour">
        <h2> <span className="numberColor"> 4. </span> Cómo utilizamos la información:</h2>

        <div>
          <p className="alert alert-info">
          <strong>Comunicarnos contigo: </strong> Para mantenerlo informado sobre nuestros servicios, podríamos
          enviar correos electrónicos y anuncios que son necesarios para la administración de nuestro sitio web.
           También podríamos proveer a nuestros usuarios con la opción de unirse a nuestra lista de correos
           electrónicos para recibir información adicional sobre nuestro sitio web, servicios y ofertas.
          </p>
        </div>

        <p className="alert alert-info">
          <strong> Para mostrar opiniones y comentarios </strong>
          Para que puedan ver los comentarios que nos han dejado otros usuarios y puedan comprobar que nuestros
          servicios son reales y de excelente calidad.
        </p>

      </section>

      <section id="sectionFive">
        <h2> <span className="numberColor"> 5. </span> Servicios de terceros: </h2>

        <div>
          <h3> Estadísticas</h3>
          <p className="alert alert-info">
            <strong> Google Analytics: </strong>
             Es una herramienta de análisis web que nos facilita informes sobre lo que pasa en nuestro sitio web:
             visitantes, duración de visitas, duración de la sesión, cómo ha llegado a nuestro sitio web,
             que dispositivo uso, con que navegador se conectó.
          </p>

          <p className="alert alert-info">
            <strong> Para impulsar funciones de redes sociales: </strong>
            Las funciones de redes sociales en los Servicios (como el botón Me gusta de Facebook o el botón de
            seguir en Twitter) podrán permitir al proveedor de redes sociales de terceros recopilar cosas,
            como la dirección IP del usuario y qué página de los servicios está visitando, y establecer una
            cookie para activar la función. Las interacciones del usuario con estas funciones se regirán por
            la política de privacidad de la empresa de terceros.
          </p>

          <p className="alert alert-info">
            <strong> Registro y autenticación: </strong>
            inicio de sesión con redes sociales: Haciendo uso de sus respectivas API que estarán sujetos a la
            política de privacidad de Facebook o Google, según sea el caso.
          </p>

          <p className="alert alert-info">
            <strong> Datos personales con registro por correo, contraseña: </strong>
            Nombre de usuario, correo electrónico, foto de perfil.
          </p>
        </div>

      </section>

      <section id="sectionSix">
        <h2><span className="numberColor"> 6. </span> Seguridad:</h2>

        <p className="tall">
        Utilizaremos medidas de seguridad adecuadas en función del tipo de datos que se almacenen.
        Sin embargo, ningún sistema de seguridad es impenetrable, debido a esto, no le podemos garantizar la
        seguridad de nuestros servidores. Es también posible que la información que usted nos provea pueda
        ser interceptada durante la transmisión de datos. La contraseña del usuario es una parte importante de
        nuestro sistema de seguridad, y es responsabilidad del usuario protegerla. El usuario no deberá compartir
        su contraseña con terceros, y si cree que su contraseña o su cuenta están en peligro, deberá cambiarla
        inmediatamente y ponerse en contacto con <a href="mailto:info@protics.com.mx">info@protics.com.mx</a> para informar de sus inquietudes.
        </p>

        <p className="tall">
          protics.com.mx emplea una tecnología industrial estándar conocida como secure-socket-layer ("SSL") para proteger la transmisión de datos. Puede checar la seguridad de su conexión tras observar la barra de direcciones en su ventana de Navegador. Cuando usted acceda un servidor protegido por SSL, los primeros caracteres de la dirección cambiarán de "http" a "https".
        </p>

      </section>

      <section id="sectionSeven">
        <h2> <span className="numberColor"> 7. </span> Niños: </h2>
        <p className="tall">
          No pediremos conscientemente información personal de ninguna persona menor a trece (13) años sin el
          consentimiento de sus padres. Los padres que crean que hemos recopilado de forma involuntaria datos
          personales sobre sus hijos menores de edad deberán ponerse en contacto <a href="mailto:info@protics.com.mx">info@protics.com.mx</a> con nosotros para
          ayudarnos a eliminar dicha información.
        </p>
      </section>

      <section id="sectionEight">
        <h2> <span className="numberColor"> 8. </span> Enlaces a otras redes, servicios, páginas: </h2>
        <p className="tall">
           Los enlaces a otras redes son una parte integral de la funcionalidad de Internet, incluyendo nuestro
           sitio web. Cualquier hipervínculo que disponga de una URL de un dominio distinto a protics.com.mx
           en la barra de direcciones de su Navegador es un hipervínculo a una página fuera de nuestro servicio.
           Estas otras redes podrán enviar sus "cookies" a usted, recolectar data o solicitar información
           personal. protics.com.mx no puede garantizar y no se hace responsable por el contenido de otras
           redes o páginas.
        </p>
      </section>

      <section id="sectionNine">
        <h2> <span className="numberColor"> 9. </span> Cómo consultar, actualizar o borrar su información: </h2>
        <p className="tall">Cómo parte del uso de nuestro sitio web, usted es responsable de actualizar la información,
        borrar su perfil creado (esté se crea en el momento que se registra). Usted también podrá cancelar
        la suscripción a la lista de correos. Todas estas acciones las podrá realizar de la siguiente manera:</p>

        <div>
          <h4> Perfil </h4>
          <div className="alert alert-info">
            <li> Tiene que iniciar sesión </li>
            <li> ir al enlace : <Link to="https://protics.com.mx/profile" target="_blank"> Enlace </Link> </li>
            <li> Aqui podrá ver los datos que tenemos de usted: su foto de perfil, nombre de usuario y correo electrónico.</li>
            <li> Usted podrá actualizar su nombre, de usuario o foto de perfil.</li>
            <li> Si quiere eliminar su cuenta, la opción se encuentra dentro del perfil.</li>
          </div>
        </div>

        <div>
          <h4> Cancelar suscripción a la lista de correos.</h4>
          <p className="alert alert-info">
            Usted podrá cancelar la suscripción dando click  en la opción "Quitar suscripción al correo",
           esta opción se encuentra en la parte frontal izquierda del sitio web al darle click a su nombre
           se vera la opción junto con ( Perfil y Salir ).
           </p>
        </div>

        <div>
          <h4>Recuperar Contraseña:</h4>
          <p className="alert alert-info"> Si por algún motivo no recuerda su contraseña en este enlace podrá
          realizar el cambio <Link to="https://protics.com.mx/recover_password" target="_blank"> Enlace </Link></p>
         </div>


      </section>

      <section id="sectionTen">
        <h2><span className="numberColor"> 10. </span> Opciones del usuario con respecto al uso de sus datos: </h2>
        <p className="tall">
          El usuario puede decidir no proporcionarnos determinados datos, pero no podrá utilizar algunas
           funciones del sitio web.
        </p>

        <div className="alert alert-info">
          <li> Para dejar de recibir nuestras comunicaciones promocionales, el usuario puede excluirlas
            voluntariamente  mediante el mecanismo para cancelar suscripción.</li>
          <li> El navegador o el dispositivo del usuario pueden permitirle controlar las cookies y otros
          tipos de almacenamiento de datos local. </li>
          <li>
            Para dejar de permitir que Google Analytics utilicen sus datos para el análisis o el enriquecimiento,
             el usuario puede consultar <Link to="https://tools.google.com/dlpage/gaoptout" target="_blank"> Enlace </Link>
          </li>
        </div>
      </section>


      <h5> Protics se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.</h5>

    </div>
  )
}

export default Politics;
