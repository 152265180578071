import {
  GET_INFO_ITEM_POST
} from '../constants';

import { actions_requests } from '../requests';
import ErrorRequest from '../requests/ErrorRequest';

const getInfoItemPost = payload => { return { type: GET_INFO_ITEM_POST, payload }};

export const listInfoItemPost = ( post_guid ) => {

  return ( dispatch, getState ) => {
    let url_request = process.env.REACT_APP_API_POSTS_NOTIFICATIONS;
    let body = `post_guid=${post_guid}`;

    actions_requests(body, url_request, 'POST')
      .then( respo => {

        if ( respo.response === 'success' ) {
          dispatch(getInfoItemPost(respo.data));
        }

      })
      .catch( err => {
        ErrorRequest(err, null, 3);
      });
  }

}
