import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  address : PropTypes.string.isRequired,
  phone   : PropTypes.string.isRequired,
  email   : PropTypes.string.isRequired
}

const InfoContact = ({ address, phone, email }) => {
  return (
    <ul className = "list-unstyled push-top">
      <li><i className="fa fa-map-marker"></i> <strong>Dirección:</strong> { address }</li>
      <li><i className="fa fa-phone"></i> <strong>Teléfono: </strong> { phone } </li>
      <li><i className="fa fa-envelope"></i> <strong>Correo electrónico: </strong> { email } </li>
    </ul>
  )
}

InfoContact.propTypes = propTypes;

export default InfoContact;
