import React from 'react';
import PropTypes from 'prop-types';

import Solution from './Solution';

const propTypes = {
  type_solutions: PropTypes.array.isRequired
}

const renderTypeSolutions = (type_solutions) => {
  return (
    type_solutions.map( ts => {
      return (
        <div className="col-xs-6 col-md-6" key = { ts.Sys_GUID }>
          <div className="panel-info">
            <div className="panel-heading">
              <h3 className="panel-title">
                { ts.type_solution }
              </h3>
            </div>
            <div className="panel-body">
              <div className="row">
                <Solution solutions = { ts.solutions } />
              </div>
            </div>
          </div>
        </div>
      )
    })
  )
}

const TypeSolutions = ({ type_solutions }) => {
  return (
    <div className = "panel-body">
      <div className = "row">
        { renderTypeSolutions(type_solutions) }
      </div>
    </div>
  )
}

TypeSolutions.propTypes = propTypes;

export default TypeSolutions;
