import React, { Component } from 'react';

import FirebaseClass from '../../classes/FirebaseClass';
import { _sweetAlert } from '../../services/SweetAlert2';
import { isOnline, MessageConnection } from '../../functions/NavigatorOnline';

let objFirebase = new FirebaseClass("Anónimo");

class RecoverPassword extends Component {

  constructor () {
    super();

    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  async handleResetPassword () {

    if ( isOnline() === true ) {

      let email_recover = document.getElementById('email_recover').value;
      let respo = await objFirebase.resetPassword(email_recover);

      if ( respo.response === 'success' ) {
        _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Aviso', messageResponse: respo.message, typeResponse: 'success' });
        document.getElementById('email_recover').value = '';
      }

    } else if ( isOnline() === false ) {
      MessageConnection();
    }
  }

  render () {
    return (
      <div className = "container">
        <div className = "row">
          <div className = "col-md-12">
            <div className = "text-center">
              <h3> <i className= "fa fa-lock fa-4x"></i> </h3>
              <h2>Se te olvidó tu contraseña</h2>
              <p> Te enviaremos un correo electrónico con un enlace para restablecer tu contraseña.</p>
              <div className = "panel-body">
                <div className = "form-group">
                  <div className = "input-group">
                    <span className="input-group-addon"><i className="fa fa-envelope color-blue"></i></span>
                    <input type="email" className="form-control" id="email_recover" name="email_recover"/>
                  </div>
                </div>
                <div className = "form-group">
                  <div className = "input-group">
                    <button className="btn btn-lg btn-primary btn-block" onClick={ this.handleResetPassword  }> Restablecer la contraseña</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RecoverPassword;
