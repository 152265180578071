import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

import { filterSubcategories, updateFilters } from '../../actions/filters_subcategories';

const propTypes = {
  handleFilter : PropTypes.func.isRequired
}

class TreeData extends Component {

  componentDidMount () {
    if ( this.props.filters_posts === null ) {
      this.props.filterSubcategories();
    }
  }

  renderTreeData () {
    if ( typeof(this.props.filters_posts) !== 'undefined' && this.props.filters_posts !== null ) {
      return (
        <SortableTree
          treeData = { this.props.filters_posts }
          onChange = { cat_sub => this.props.updateFilters(cat_sub) }
          generateNodeProps={rowInfo => ({
              onClick: (event) =>  {
                if(event.target.className.includes('collapseButton') || event.target.className.includes('expandButton')) {
                } else {
                  if ( rowInfo.parentNode === null ) {
                    this.props.handleFilter(rowInfo.node.categoryId, 'category_service/categoryId');
                  } else {
                    this.props.handleFilter(rowInfo.node.id, 'subcategory_service/subcategoryId');
                  }

                }
              }
          })}
        />
      )
    }
  }

  render () {
    return (
      <div>
        <ul>
          <li>
            <h5 onClick = { () => this.props.handleFilter('', 'Not Filter') }> Mostrar Todo </h5>
          </li>
        </ul>
        <div style={{ height: 500 }} >
          { this.renderTreeData() }
        </div>
      </div>
    )
  }
}

TreeData.propTypes = propTypes;

function mapStateToProps ( state ) {
  return {
    filters_posts : state.filters_posts
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    filterSubcategories,
    updateFilters
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(TreeData);
