import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { videosYoutube } from '../../actions/videos_youtube';
import ListVideos from './ListVideos';
import Spinner from '../Global/Spinner';
import Footer from './Footer';
import PlayVideo from './PlayVideo';
import MoreVideos from './MoreVideos';

class VideosContent extends Component {

  constructor () {
    super();

    this.state = { videoResults: 8 };
  }

  componentDidMount () {
    if ( this.props.videos_youtube === null ) {
      setTimeout( () => this.loadVideos(), 1000);
    }
  }

  loadVideos () {
    if ( typeof(this.props.channel_youtube) !== 'undefined' && this.props.channel_youtube !== null) {
      let uploads = this.props.channel_youtube.items[0].contentDetails.relatedPlaylists.uploads;
      this.props.videosYoutube(this.state.videoResults, uploads);
    }
  }

  renderComponentVideos () {
    if ( typeof(this.props.videos_youtube) !== 'undefined' && this.props.videos_youtube !== null ) {
      if ( this.props.videos_youtube.video_play === false ) {
        return (
          <div>
            <ListVideos
              videos_youtube = { this.props.videos_youtube }
            />
            <Footer
              previousPageToken = { this.props.videos_youtube.previousPageToken }
              nextPageToken     = { this.props.videos_youtube.nextPageToken }
            />
          </div>
        )
      } else if ( this.props.videos_youtube.video_play === true ) {

        return (
          <div className = "col-md-12">

            <div className = "col-md-9">
              <PlayVideo
                videoId      = { this.props.videos_youtube.idVideo }
                urlVideo     = { this.props.videos_youtube.urlVideo }
                descripVideo = { this.props.videos_youtube.descripVideo }
              />
            </div>

            <div className = "col-md-3">
              <MoreVideos
                videos_youtube = { this.props.videos_youtube }
                nextPageToken  = { this.props.videos_youtube.nextPageToken }
                uploads        = { this.props.channel_youtube.items[0].contentDetails.relatedPlaylists.uploads }
                video_play     = { this.props.videos_youtube.video_play }
              />
            </div>

          </div>
        )
      }
    } else {
      return ( <Spinner /> );
    }
  }

  render () {
    return (
      <div className = "col-lg-9 col-md-8 col-sm-8 col-xs-12">
        { this.renderComponentVideos() }
      </div>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    channel_youtube : state.channel_youtube,
    videos_youtube  : state.videos_youtube
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    videosYoutube
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(VideosContent);
