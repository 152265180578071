import React from 'react';
import PropTypes from 'prop-types';

import DetailSolutions from '../../components/Knowledge/Contpaq/DetailSolutions';
import Footer from '../../components/Global/Footer';

const propTypes = {
  solutionId  : PropTypes.string.isRequired,
  name_system : PropTypes.string.isRequired
}

const DetailSolutionsContainer = ({ solutionId, name_system }) => {
  return (
    <div>
      <DetailSolutions
        solutionId  = { solutionId }
        name_system = { name_system }
      />
      <Footer />
    </div>
  )
}

DetailSolutionsContainer.propTypes = propTypes;

export default DetailSolutionsContainer;
