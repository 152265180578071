import firebase from 'firebase/app';

import { setMessage } from '../constants/Message';
import { getItemLocalStorage }  from '../functions/VLocalStorage';

class TimeLineClass {

  constructor () {
    this.messages = [];
    this._start   = 0;
    this._end     = 3;
    this._n       = 3;
  }

  async returnMessages ( messagesRef ) {

    let promise = new Promise( (resolve, reject ) => {
      let messages = [];
      let increments = [];

      messagesRef.on('child_added',snap => {
        let message =  setMessage(snap);
        messages.push(message);
        increments.push(snap.val().increment);
      });

      let response = { messages, increments };
      setTimeout( () => resolve( response ), 3000 );

    });

    return await promise;
  }

  async loadMessagesCard ( PictureFirebase ) {
    let promise = new Promise( (resolve, reject) => {
      let messages = [];
      PictureFirebase.on('child_added', (snapshot) => {
        let message = setMessage(snapshot);
        messages.push(message);
      });

      setTimeout( () => resolve(messages), 2000);

    });

    return await promise;
  }

  handleUpdateLike (snapshotKey, numLikes) {
    firebase.database().ref(`messages/${snapshotKey}`).update({ likes: numLikes, liked: true });
    var newData = {
      date: Date.now(),
      liked: true,
      email: getItemLocalStorage('email'),
      displayName: getItemLocalStorage('displayName')
    }
    firebase.database().ref(`messages/${snapshotKey}`).child('likes_user').push(newData);
  }

  handleCheckUser (snapshotKey) {
    var valor = [];
    firebase.database().ref(`messages/${snapshotKey}`).child('likes_user').orderByChild('email').equalTo(getItemLocalStorage('email')).on('child_added', (sna) => {
      valor = sna.val().email;
    });

    return valor;
  }

  async likePost ( msgId, messages ) {

    let likes = messages.map( msg => {

      if (msg.id === msgId) {
        var numLikes = 0;

        firebase.database().ref('messages').orderByChild("id").equalTo(msgId).on('child_added', (snapshot) => {
            numLikes = snapshot.val().likes + 1;
            if(snapshot.hasChild("likes_user") === false) {
              msg.likes++;
              this.handleUpdateLike(snapshot.key, numLikes);
            } else {
              if(this.handleCheckUser(snapshot.key).length === 0) {
                msg.likes++;
                this.handleUpdateLike(snapshot.key, numLikes);
              } else {
                console.log("Solo puedes hacer un like por imágen");
              }
            }
        });

      }

      return msg;

    });

    return await likes;

  }

  async FilterHashtagLoadMessages ( hashtagsRef, messageRef ) {

    let promise = new Promise( (resolve, reject ) => {
      let messages = [];
      hashtagsRef.on('child_added', snapshot => {
        messageRef.equalTo(snapshot.val().id).on('child_added', snapshot2 => {
          let message = setMessage(snapshot2);
          messages.push(message);
        });
      });

      setTimeout( () => resolve(messages), 2000);

    });

    return await promise;

  }

}

export default TimeLineClass;
