import ReactGA from 'react-ga';
import { property_id } from '../../constants/analytics';

ReactGA.initialize(property_id());

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
  return null;
};
