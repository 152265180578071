import React from 'react';

import NotAuthorized from '../../components/Global/NotAuthorized';
import Footer from '../../components/Global/Footer';

const NotAuthorizedContainer = () => {
  return (
    <div>
      <NotAuthorized />
      <Footer />
    </div>
  )
}

export default NotAuthorizedContainer;
