import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { handleMenuCollapse } from '../../../../functions/MenuHeader';
import SignInHeader from '../SignInHeader';
import LoggedHeader from '../LoggedHeader';
import { isEmptyJSON } from '../../../../functions/ValidationState';
import { isOnline } from '../../../../functions/NavigatorOnline';

class MegaMenu extends Component {

  constructor () {
    super();

    this.handleMenuActive = this.handleMenuActive.bind(this);
  }

  checkMenuActive () {
    const el = document.querySelector('#mainMenu').children;

    for ( let i = 0; i <= el.length -1; i++) {

      if ( el[i].classList.contains('active') ) {
        el[i].classList.remove('active');
      }

    }

  }

  handleMenuActive ( evt ) {
    handleMenuCollapse();
    this.checkMenuActive();
    evt.target.parentNode.classList = 'active';
  }

  renderLogin () {

    if ( isEmptyJSON(this.props.info_user) === true ) {
      return (
        <LoggedHeader
          photoURL    = { this.props.info_user.photoURL }
          email       = { this.props.info_user.email }
          displayName = { this.props.info_user.displayName }
          providerId  = { this.props.info_user.providerId }
        />
      );
    } else if ( isEmptyJSON(this.props.info_user) === false ) {
      return ( <SignInHeader /> );
    }

  }

  renderRouteOnline ( route, name ) {
    if ( isOnline() === true ) {
      return (
        <Link to={ `/${route}` } target = "_blank"> { name } </Link>
      )
    } else if ( isOnline() === false ) {
      return ( <a href={  `/${route}` }> { name }</a> );
    }
  }

  render () {

    return (
      <ul className = "nav nav-pills nav-main" id = "mainMenu" >
        <li onClick = { this.handleMenuActive } >
          <Link to = "/tutorials">
            Hazlo tú mismo
          </Link>
        </li>
        {/* <li onClick = { this.handleMenuActive }>
          <Link to = "/opinions">
            Opiniones
          </Link>
        </li> */}
        <li onClick = { this.handleMenuActive }>
          <Link to = "/timeline">
            Qué estamos haciendo
          </Link>
        </li>
        <li onClick = { this.handleMenuActive }>
          <a href = { process.env.REACT_APP_URL_BLOG } target = "_blank" rel="noopener noreferrer">
            Blog
          </a>
        </li>
        <li onClick = { this.handleMenuActive }>
          <a href = { process.env.REACT_APP_URL_ECOMMERCE } target = "_blank" rel="noopener noreferrer">
              Tienda en línea
          </a>
        </li>
        { this.renderLogin() }
      </ul>
    )
  }
}


function mapStateToProps ( state ) {
  return {
    info_user: state.info_user
  };
}

export default connect( mapStateToProps, null)(MegaMenu);
