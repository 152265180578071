import React from 'react';

import Contpaq from '../../components/Knowledge/Contpaq';
import Footer from '../../components/Global/Footer';

const ContpaqContainer = () => {
  return (
    <div>
      <Contpaq />
      <Footer />
    </div>
  )
}

export default ContpaqContainer;
