import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { memberList } from '../../actions/members';
import ListMember from './ListMember';
import Navegation from '../Global/Navegation';
import Spinner from '../Global/Spinner';
import './css/members.css'

class AboutUs extends Component {

  componentDidMount () {

    if ( this.props.team_members === null ) {
      this.props.memberList();
    }

  }

  renderMembers () {
    if ( typeof(this.props.team_members) !== 'undefined' && this.props.team_members !== null ) {
      return (
        <ListMember team_members = { this.props.team_members } />
      )
    } else {
      return ( <Spinner />);
    }

  }

  render () {
    return (
      <div role = "main" className = "main">
        <Navegation
          title = { "Acerca de nosotros" }
          url   = { "/about_us" }
        />
        <div className = "container">
          <div className = "row">
            <div className = "col-md-12">
              <ul className = "team-list">
                { this.renderMembers() }
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    team_members: state.team_members
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    memberList
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(AboutUs);
