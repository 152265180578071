import React from 'react';

import Opinions from '../../components/Opinions';
import Footer from '../../components/Global/Footer';

const OpinionsContainer = () => {
  return (
    <div>
      <Opinions />
      <Footer />
    </div>
  )
}

export default OpinionsContainer;
