import {
  GET_CUSTOMERS_MAP
} from '../constants';

export const customers_map = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_CUSTOMERS_MAP: {
      return action.payload;
    }

    default:
      return state;

  }
}
