import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { infoContactCompany } from '../../actions/info_contact_company';
import ItemsInfo from './ItemsInfo';

class InformationCompany extends Component {

  componentDidMount () {

    if ( this.props.info_contact_company === null ) {
      this.props.infoContactCompany();
    }

  }

  renderInfoContact () {

    if ( typeof(this.props.info_contact_company) !== 'undefined' && this.props.info_contact_company !== null ) {
      return (
        <ItemsInfo info_contact_company = { this.props.info_contact_company } />
      )
    }

  }

  render () {
    return (
      <div className = "col-md-6">
        { this.renderInfoContact() }
      </div>
    )
  }
}


function mapStateToProps ( state ) {
  return {
    info_contact_company: state.info_contact_company
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    infoContactCompany
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(InformationCompany);
