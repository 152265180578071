import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';

import { cutText } from '../../functions/CutString';

const propTypes = {
  post_title   : PropTypes.string.isRequired,
  post_content : PropTypes.string.isRequired,
  post_guid    : PropTypes.string.isRequired,
  post_key     : PropTypes.string.isRequired,
  post_name    : PropTypes.string.isRequired
}

const PostContentShort = ({ post_title, post_content, post_guid, post_key, post_name }) => {
  let url_item_post = `${post_guid}/${post_key}/${post_name}`;
  return (
    <div className = "post-content">
      <h2> <Link to = { url_item_post }> { post_title } </Link> </h2>
      <div className = "text-justify">
        { ReactHtmlParser(cutText( post_content , 450)) }
      </div>
    </div>
  )
}

PostContentShort.propTypes = propTypes;

export default PostContentShort;
