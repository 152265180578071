import {
  GET_WEBSITE_INFO
} from '../constants';

export const website_info = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_WEBSITE_INFO: {
      return action.payload;
    }

    default:
      return state;

  }
}
