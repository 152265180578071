import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jobsList } from '../../actions/jobs';
import ListJobs from './ListJobs';
import Navegation from '../Global/Navegation';
import ContainerPrincipal from '../HOC/ContainerPrincipal';
import { existsProperty } from '../../functions/Properties';
import { isEmptyJSON } from '../../functions/ValidationState';

class Jobs extends Component {

   componentWillMount () {
      if ( this.props.profiles.data.length === 0 ) {
         this.props.jobsList();
      }
   }

   renderProfilesJobs ( empty_profiles ) {

      let data = existsProperty( this.props.profiles, 'data' );

      if ( typeof(data) !== 'undefined' && data.length > 0 ) {
         return (
            <ListJobs
               profiles = { this.props.profiles.data }
            />
         )
      } else {
         return (
            this.renderEmailCompany( empty_profiles )
         );
      }

   }

   renderEmailCompany ( empty_profiles ) {
      let { info_contact_company } = this.props;

      if ( isEmptyJSON(info_contact_company) ) {
         return (
            <p className = "lead">
               { empty_profiles } <a href = { `mailto:rrhh@protics.com.mx` } >
                  rrhh@protics.com.mx
               </a>
            </p>
         )
      } else {
         return null;
      }
   }

   render () {

      let title_profiles = "", description_profiles = "", empty_profiles = "";

      let { website_info } = this.props;
      
      if ( isEmptyJSON(website_info) ) {
         title_profiles       = website_info[41].info_value;
         description_profiles = website_info[42].info_value;
         empty_profiles       = website_info[43].info_value;
      }
       
      return (
         <div role = "main" className = "main">
            <Navegation
               title = { "Trabajos" }
               url   = { '/jobs' }
            />
            <div className = "container">
               <h2><strong>{ title_profiles }</strong></h2>

               <div className = "row">
                  <div className = "col-md-12">
                     <p className = "lead">
                        { description_profiles }
                     </p>
                  </div>
               </div>

               <hr />

               <div className = "row">
                  <div className = "col-md-12">
                     { this.renderProfilesJobs( empty_profiles ) }
                  </div>
                  {/*<div className = "col-md-4">
                        <div className = "featured-box featured-box-secundary">
                           <div className = "box-content">
                              <h4> Los Beneficios </h4>
                           </div>
                        </div>
                     </div> */ }
               </div>

            </div>
         </div>
      );
   }
}

function mapStateToProps ( state ) {
   return {
     profiles             : state.profiles,
     website_info         : state.website_info,
     info_contact_company : state.info_contact_company
   };
}
 
function mapDispatchToProps ( dispatch ) {
   return bindActionCreators({
      jobsList
   }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(ContainerPrincipal(Jobs));