import {
  GET_FILTER_SUBCATEGORIES,
  UPDATE_FILTERS_SUBCATEGORIES
} from '../constants';

import { actions_requests } from '../requests';
import ErrorRequest from '../requests/ErrorRequest';

const getFiltersSubcategories = payload => { return { type: GET_FILTER_SUBCATEGORIES, payload }};
const updateFiltersSubcategories = payload => { return { type: UPDATE_FILTERS_SUBCATEGORIES, payload }};

export const filterSubcategories = ( ) => {

  return ( dispatch, getState ) => {

    let url_request = process.env.REACT_APP_API_TREEDATA;

    actions_requests(null, url_request, 'GET')
      .then( respo => {

        if ( respo.response === 'success' ) {
          dispatch(getFiltersSubcategories(respo.data));
        }

      })
      .catch( err => {
        ErrorRequest(err, null, 3);
      });
  }

}

export const updateFilters = ( cat_sub ) => {

  return ( dispatch, getState ) => {
    dispatch(updateFiltersSubcategories(cat_sub));
  }

}
