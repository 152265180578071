import React from 'react';
import PropTypes from 'prop-types';

import TypeSolutions from './TypeSolutions';

const propTypes = {
  name_type      : PropTypes.string.isRequired,
  type_solutions : PropTypes.array.isRequired
}

const SystemType = ({ name_type, type_solutions }) => {
  return (
    <div className = "col-md-12">
      <div className = "panel-primary">
        <div className = "panel-heading">
          <h3 className = "text-center panel-title">
            { name_type }
          </h3>
        </div>
        <TypeSolutions type_solutions = { type_solutions } />
      </div>
    </div>
  )
}

SystemType.propTypes = propTypes;

export default SystemType;
