import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import InputForm from '../Global/InputForm';
import { actions_requests } from '../../requests';
import ErrorRequest from '../../requests/ErrorRequest';
import { isOnline, MessageConnection } from '../../functions/NavigatorOnline';

const propTypes = {
  sendEmail     : PropTypes.func.isRequired,
  handleCaptcha : PropTypes.func.isRequired
}

class ContactForm extends Component {

  constructor () {
    super();

    this.state = { recaptchaVerified: false };

    this.handleCaptcha = this.handleCaptcha.bind(this);
    this.sendEmail = this.sendEmail.bind(this);

  }

  sendEmail ( evt ) {
    evt.preventDefault();

    if ( isOnline() === true ) {

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

      let name    = document.getElementById('name').value;
      let email   = document.getElementById('email').value;
      let subject = document.getElementById('subject').value;
      let message = document.getElementById('message').value;
      let cellphoneNumber = document.getElementById('cellphoneNumber').value;

      if(this.state.recaptchaVerified === false) {
        alert("Debes resolver el captcha");
      } else {
        if(name === '') {
          alert("Debes ingresar el nombre");
        } else {
          if(email === '') {
            alert("Debes ingresar el correo electrónico");
          } else {
            if(subject === '') {
              alert("Debes ingresar el asunto del mensaje")
            } else {
              if(cellphoneNumber === '') {
                alert("Debes ingresar tu teléfono celular");
              } else {
                if( message === '') {
                  alert("Debes ingresar el mensaje");
                } else {
                  const body = `from_name=Protics&to_email=${email}&subject_message=Hola&subject_name=${name}&option_template=customer_contact`;
                  actions_requests(body, process.env.REACT_APP_API_SENDEMAIL, 'POST')
                    .then( respo => {
                      if ( respo.response === 'success' ) {
                        alert("Se ha enviado el correo correctamente");
                        let newBody = `from_name=Protics&to_email=${process.env.REACT_APP_EMAIL_VENTAS}&subject_message=${subject}&subject_name=Protics&option_template=company_contact&name=${name}&email=${email}&subject=${subject}&cellphoneNumber=${cellphoneNumber}&message=${message}`;
                        this.sendEmailCompany(newBody);
                      }
                    })
                    .catch( err => {
                      ErrorRequest(err, null, 3);
                    });

                }
              }
            }
          }
        }
      }

    } else if ( isOnline() === false ) {
      MessageConnection();
    }


  }

  handleCaptcha ( value ) {

   let body = {
     'g_recaptcha': value
   };

   fetch(process.env.REACT_APP_API_VERIFIED_CAPTCHA, {
     method: 'POST',
     headers: {
       "Content-Type": "application/json"
     },
     body: JSON.stringify(body)
   })
   .then(response => response.json())
   .then((data) => {
     if( data.verified === true ) {
       this.setState({ recaptchaVerified : data.verified });
     }
   })
   .catch(error => console.log(`Error: ${error}`));

  }

  sendEmailCompany (body) {

    actions_requests(body, process.env.REACT_APP_API_SENDEMAIL, 'POST' )
      .then( respo => {
        if ( respo.response === 'success' ) {
          document.getElementById('formContact').reset();
        }
      })
      .catch( err => {
        ErrorRequest(err, null, 3);
      });
  }

  renderRecaptcha () {
    if ( isOnline() === true ) {
      return (
        <ReCAPTCHA
          ref      = { "recaptcha" }
          sitekey  = { process.env.REACT_APP_API_SITE_KEY_RECAPTCHA }
          onChange = { this.handleCaptcha }
          theme    = { "dark" }
          type     = { "image" }
        />
      )
    } else if ( isOnline() === false ) {
      return null;
    }
  }

  render () {
    return (
      <form id = "formContact" onSubmit = { this.sendEmail }>

        <div className="row">
          <div className="form-group">
            <InputForm
              tamDiv = { "6" }
              title  = { "Nombre" }
              id     = { 'name' }
            />
            <InputForm
              tamDiv = { "6" }
              title  = { "Correo Electrónico" }
              id     = { 'email' }
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group">
            <InputForm
              tamDiv = { "6" }
              title  = { "Celular" }
              id     = { "cellphoneNumber" }
            />

            <InputForm
              tamDiv = { "6" }
              title  = { "Asunto" }
              id     = { "subject" }
            />
          </div>
        </div>


        <div className="row">
          <div className="form-group">
            <div className="col-md-12">
              <label>Mensaje</label>
              <textarea className="form-control" ref="message" id="message" rows="10"></textarea>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group">
            <div className="col-md-12">
              { this.renderRecaptcha() }
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <button className="btn btn-primary btn-lg" type="submit">Enviar Mensaje</button>
          </div>
        </div>

      </form>
    )
  }
}

ContactForm.propTypes = propTypes;

export default ContactForm;
