import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navegation from '../../Global/Navegation';
import ContainerItem from './ContainerItem';
import Spinner from '../../Global/Spinner';
import { listInfoItemPost } from '../../../actions/item_post';

const propTypes = {
  post_guid : PropTypes.string.isRequired,
  post_name : PropTypes.string.isRequired
}

class ItemPostNotification extends Component {

  componentDidMount () {
    if ( this.props.info_item_post === null ) {
      this.props.listInfoItemPost(this.props.post_guid);
    }
  }

  renderInfo () {
    if ( typeof(this.props.info_item_post) !== 'undefined' && this.props.info_item_post !== null ) {
      return (
        <ContainerItem info_item_post = { this.props.info_item_post } />
      )
    } else {
      return ( <Spinner /> );
    }
  }

  render () {
    return (
      <div>
        <Navegation
          title = "Notificaciones"
          url   = "/posts_notifications"
        />
        <div className = "container">
          <div className = "row">
            <div className = "col-md-9">
              <div className = "blog-posts single-post">
                { this.renderInfo() }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ItemPostNotification.propTypes = propTypes;

function mapStateToProps ( state ) {
  return {
    info_item_post: state.info_item_post
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    listInfoItemPost
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(ItemPostNotification);
