import React from 'react';
import PropTypes from 'prop-types';

import GeneralInfo from './GeneralInfo';
import InfoLocation from './InfoLocation';

const propTypes = {
  name_client  : PropTypes.string.isRequired,
  notes        : PropTypes.string.isRequired,
  url_facebook : PropTypes.string.isRequired,
  website      : PropTypes.string.isRequired,
  latitude     : PropTypes.string.isRequired,
  longitude    : PropTypes.string.isRequired,
  address      : PropTypes.string.isRequired,
  phone        : PropTypes.string.isRequired,
  email        : PropTypes.string.isRequired
}

const CustomerData = ({ name_client, notes, url_facebook, website, latitude, longitude, address, phone, email }) => {
  return (
    <div className = "row">
      <GeneralInfo
        name_client  = { name_client }
        notes        = { notes }
        url_facebook = { url_facebook }
        website      = { website }
      />
      <InfoLocation
        latitude     = { latitude }
        longitude    = { longitude }
        address      = { address }
        phone        = { phone }
        email        = { email }
      />
    </div>
  )
}

CustomerData.propTypes = propTypes;

export default CustomerData;
