export function quantityElements (object) {
  var count = {}
  for (var a in object ) {
    if(count[object[a]]) {
      count[object[a]]++;
    } else {
      count[object[a]] = 1;
    }
  }

  return count;
}

export function separateElements (obj, chunk ) {
  let index = obj.length;
  let results = [];
  let i, j;

  for (i=0, j=index; i<j; i+=chunk ) {
    results.push(obj.slice(i,i+chunk));
  }

  return results;
}
