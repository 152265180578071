import {
  GET_LIST_MEMBERS
} from '../constants';

export const team_members = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_LIST_MEMBERS: {
      return action.payload;
    }

    default:
      return state;

  }
}
