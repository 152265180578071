import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import FirebaseClass from "../../classes/FirebaseClass";
import { isOnline, MessageConnection } from '../../functions/NavigatorOnline';
import { dataUser } from '../../actions/user';
import { valid_input } from '../../functions/validationsParams';
import { _sweetAlert } from '../../services/SweetAlert2';
import './css/login.css';
let objFirebase = new FirebaseClass("Anónimo");


const propTypes = {
  section_option : PropTypes.string
}

const title_section = section_option => {
  if (section_option === 'timeline') {
    return (
      <div className="text-center">
        <p>
          Utiliza uno de los 3 métodos para iniciar sesión y podrás ver los <br/> trabajos que estámos haciendo.
        </p>
      </div>
    )
  } else {
    return (
      <span></span>
    )
  }
}

class Login extends Component {

  constructor () {
    super();

    this.onAuth = this.onAuth.bind(this);
    this.handleAuthWithEmailAndPassword = this.handleAuthWithEmailAndPassword.bind(this);
  }

  async onAuth ( evt ) {

    if ( isOnline() === true ) {
      let optionSocial = evt.target.value;
      let info_user = this.props.user || "Anónimo";
      let objFirebase = new FirebaseClass(info_user);

      let user = await objFirebase.AuthProviderSocial(optionSocial);

      this.props.dataUser( user );
    } else if ( isOnline() === false ) {
      MessageConnection();
    }

  }

  async handleAuthWithEmailAndPassword ( evt ) {

    evt.preventDefault();

    if ( isOnline() === true ) {

      let email_login = document.getElementById('email_sesion').value;
      let pass_login  = document.getElementById('pass_sesion').value;

      if ( !email_login ) {
        valid_input( email_login, 'correo Electrónico' );
      } else {
        if ( !pass_login ) {
          valid_input( pass_login, 'Contraseña' );
        } else {
          let user = await objFirebase.AuthWithEmailAndPassword(email_login, pass_login);

          if ( user.emailVerified === true ) {
            this.props.dataUser( user );
          } else if ( user.emailVerified === false )  {
            _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'Debes verificar tu correo, para poder iniciar sesión', typeResponse: 'warning' });
          }
        }
      }

    } else if ( isOnline() === false ) {
      MessageConnection();
    }

  }

  render () {
    return (
      <div className = "rootLogin rootBody">
        <div className = "wrapper fadeInDown">
          <div id = "formContent">
            <h2 className = "rootH2 active"> Iniciar Sesión</h2>
            { title_section(this.props.section_option) }
            <div className = "fadeIn first">
              <img src={ process.env.REACT_APP_LOGIN_USER } className="img-resposive" alt="User" id="icon"/>
            </div>

            <button type = "button" className = "btn btn-primary buttonFacebook" onClick = { this.onAuth } value = "1"> <i className = "fa fa-facebook"></i> Facebook </button>
            <button type = "button" className = "btn btn-danger buttonGoogle" onClick = { this.onAuth } value = "2"> <i className = "fa fa-google"></i> Google </button>

            <form method = "post" onSubmit = { this.handleAuthWithEmailAndPassword } >
              <input type = "text" className = "fadeIn second styleLogin otherStyle" id = "email_sesion" name = "email_sesion" placeholder = "Correo Electrónico" />
              <input type = "password" className = "fadeIn third styleLogin otherStyle" id = "pass_sesion" name = "pass_sesion" placeholder = "Contraseña" />
              <input type = "submit" className = "fadeIn fourth styleButton otherStyle" value = "Iniciar Sesión" />
            </form>

          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = propTypes;

function mapStateToProps ( state ) {
  return {
    info_user: state.info_user
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    dataUser
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(Login);
