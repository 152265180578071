import React from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import { Scrollbars } from 'react-custom-scrollbars';
import uuid from 'uuid';

import YoutubeUrls from './YoutubeUrls';

const propTypes = {
  videoId      : PropTypes.string.isRequired,
  urlVideo     : PropTypes.string.isRequired,
  descripVideo : PropTypes.string.isRequired
}

const descriptionText = descripVideo => {
  let tam = descripVideo.split("\n");
  let url = "http://";
  let url_secure = "https://";
  let arreglo = [];

  for ( let i = 0; i < tam.length; i++ ) {
    if ( tam[i].indexOf(url) > -1 || tam[i].indexOf(url_secure) > -1 ) {
      arreglo.push(
        <YoutubeUrls
          key     = { uuid.v4() }
          concept = { tam[i] }
        />
      )
    } else {
      arreglo.push(
        <span key = { uuid.v4() }>
          { tam[i] } <br />
        </span>
      )
    }
  }

  return (
    <pre>
      { arreglo }
    </pre>
  )
}

const PlayVideo = ({ videoId, urlVideo, descripVideo }) => {
  return (
    <div>
      <Iframe
        url       = { urlVideo }
        width     = "100%"
        height    = "300px"
        id        = { videoId }
        className = "myClassname"
        display   = "initial"
        position  = "relative"
        allowFullScreen
      />
      <Scrollbars style = {{ height: 300 }}>
        { descriptionText(descripVideo) }
      </Scrollbars>
    </div>
  )
}

PlayVideo.propTypes = propTypes;

export default PlayVideo;
