import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  icon    : PropTypes.string.isRequired,
  concept : PropTypes.string.isRequired
}

const ItemSchedule = ({ icon, concept }) => {
  return (
    <li>
      <i className={ icon }></i> { concept }
    </li>
  )
}

ItemSchedule.propTypes = propTypes;

export default ItemSchedule;
