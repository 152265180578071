import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  icon    : PropTypes.string.isRequired,
  title    : PropTypes.string.isRequired,
  concept : PropTypes.string.isRequired
}

const ItemInformation = ({ icon, title, concept }) => {
  return (
    <li>
      <i className={ icon }></i> <strong>{ title }</strong> { concept }
    </li>
  )
}

ItemInformation.propTypes = propTypes;

export default ItemInformation;
