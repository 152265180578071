function errors_show (err) {

  if ( typeof(err.message.errors) !== 'undefined' && err.message.errors.length >= 1 ) {
    return proccessErrors(err.message.errors);
  } else if ( typeof(err.message) !== 'undefined' && err.message.length >= 1 && err.message.indexOf() >= 0 ) {
    return proccessErrors(err.message);
  } else {

     if( Array.isArray(err.message) === true ) {
       return proccessErrors(err.message);
     } else if (Array.isArray(err.message) === false )  {

       if ( Object.keys(err.message).length > 1 ) {
         return proccessObject( err.message );
       } else {
         return err.message;
       }

     }

  }
}

function proccessErrors (errors) {
  let cad = "";

  errors.map( ( er, index ) => {
    cad += `Mensaje (${ index }): ${ er.message } <br />`;
    return true;
  })

  return cad;
}

function proccessObject ( err ) {
  return err;
}

export default errors_show;
