import React from 'react';

import { clusterStyles, stylesMapNight } from './MapTransform';
import MarkerMap from './MarkerMap';

const { compose, withProps, withHandlers } = require("recompose");
const { withScriptjs, withGoogleMap, GoogleMap } = require("react-google-maps");
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

export const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL: `${process.env.REACT_APP_KEY_MAP}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `520px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {
    }
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom     = { 6 }
    defaultCenter   = {{ lat: 18.51572, lng: -88.304222 }}
    defaultOptions  = {{ styles: stylesMapNight }}
  >
    <MarkerClusterer
      onClick ={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={60}
      styles = { clusterStyles }
    >
      {props.markers.map(marker => (
        <MarkerMap
          key         = { marker.Sys_GUID }
          id          = { marker.Sys_GUID }
          url_photo   = { marker.url_photo }
          latitude    = { marker.latitude }
          longitude   = { marker.longitude }
          name_client = { marker.name_client }
          address     = { marker.address }
          phone       = { marker.phone }
        />
      ))}
    </MarkerClusterer>
  </GoogleMap>
);
