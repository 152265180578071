import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { systemSolutions } from '../../../actions/systems_solutions';
import SystemType from './SystemType';
import Navegation from '../../../components/Global/Navegation';

class Contpaq extends Component {

  componentDidMount () {
    if ( this.props.system_solutions === null ) {
      this.props.systemSolutions();
    }
  }

  renderSystemSolutions () {
    if ( typeof(this.props.system_solutions) !== 'undefined' && this.props.system_solutions !== null ) {
      return (
        this.props.system_solutions.map( ss => {
          return (
            <SystemType
              key            = { ss.Sys_GUID }
              name_type      = { ss.name_type }
              type_solutions = { ss.type_solutions }
            />
          )
        })
      )
    }
  }

  render () {
    return (
      <div>
        <Navegation
          title = { 'Knowledge' }
          url   = { '/knowledge' }
        />
        { this.renderSystemSolutions() }
      </div>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    system_solutions: state.system_solutions
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    systemSolutions
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(Contpaq);
