import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { allDetailsSolution } from '../../../actions/details_solution';
import Navegation from '../../../components/Global/Navegation';

const propTypes = {
  solutionId   : PropTypes.string.isRequired,
  name_system  : PropTypes.string.isRequired
}

class DetailSolutions extends Component {

  componentDidMount () {
    this.props.allDetailsSolution(this.props.solutionId);
  }

  renderDetails () {
    if ( typeof(this.props.details_solution) !== 'undefined' && this.props.details_solution !== null ) {
      return (
        this.props.details_solution.map( deta => {
          return (
            <tr key = { deta.Sys_GUID }>
              <td> { moment(deta.date_actualization).format('LL') } </td>
              <td>{ deta.detail_description }</td>
              <td> { deta.size_system }</td>
              <td> <a href={ deta.link_system} download> <span className="fa fa-download fa-2x"> </span> </a> </td>
            </tr>
          )
        }).reverse()
      )
    }
  }

  render () {
    return (
      <div>
        <Navegation
          title = { 'Contpaq' }
          url   = { '/downloads_contpaq' }
        />
        <div className = "container">
          <table className = "table table-dark">
            <thead>
              <tr>
                <th> Fecha</th>
                <th> Sistema</th>
                <th> Tamaño </th>
                <th> Enlace</th>
              </tr>
            </thead>
            <tbody>
              { this.renderDetails() }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

DetailSolutions.propTypes = propTypes;

function mapStateToProps ( state ) {
  return {
    details_solution: state.details_solution
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    allDetailsSolution
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(DetailSolutions);
