import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import Measure from 'react-measure';

const propTypes = {
  photos : PropTypes.arrayOf(PropTypes.object).isRequired
}

class FiveImages extends Component {

  constructor (props) {
    super(props);

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      width: -1
    }

    this.openLightbox  = this.openLightbox.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoPrevious  = this.gotoPrevious.bind(this);
    this.gotoNext      = this.gotoNext.bind(this);
  }

  openLightbox (event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox () {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious () {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext () {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  render () {

    const width = this.state.width;

    return (
      <div>
        <Measure bounds onResize={(contentRect) => this.setState({ width: contentRect.bounds.width })}>
          {
          ({measureRef}) => {
            if (width < 1 ){
              return <div ref={measureRef}></div>;
            }
            let columns = 1;
            if (width >= 500) {
              columns = 2;
            }
            if (width >= 1024) {
              columns = 3;
            }
            if (width >= 1824) {
              columns = 4;
            }

            return <div ref={measureRef}><Gallery photos= {this.props.photos} columns={columns}  onClick= { this.openLightbox } /></div>

          }
        }
        </Measure>
        <Lightbox images = { this.props.photos }
          onClose        = { this.closeLightbox }
          onClickPrev    = { this.gotoPrevious }
          onClickNext    = { this.gotoNext }
          currentImage   = { this.state.currentImage }
          isOpen         = { this.state.lightboxIsOpen }
        />
      </div>
    )
  }

}

FiveImages.propTypes = propTypes;

export default FiveImages;
