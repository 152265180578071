import React, { Component } from 'react';

import HeaderContainer from './HeaderContainer';
import NavbarContainer from './NavbarContainer';

class Header extends Component {
  render () {
    return (
      <div className = "body">
        <header id = "header" role="banner">
          <HeaderContainer />
          <NavbarContainer />
        </header>
      </div>
    )
  }
}

export default Header;
