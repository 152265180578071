import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string.isRequired,
  url:   PropTypes.string.isRequired,
  isDifferent : PropTypes.bool,
  title2 : PropTypes.string
}

const Navegation = ({ title, url, isDifferent = false, title2 = "" }) => {

  let text_ = title;

  if ( isDifferent ) {
    text_ = title2;
  }

  return (
    <section className="page-top">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul className="breadcrumb">
              <li ><Link to="/">Inicio</Link></li>
              <li><Link to={ url }>{ title }</Link></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h1>{ text_ }</h1>
          </div>
        </div>
      </div>
    </section>
  )
}

Navegation.propTypes = propTypes;

export default Navegation;
