import React from 'react';
import PropTypes from 'prop-types';

import InformationUser from './InformationUser';


const propTypes = {
  onHandleUpdateUserProfile  : PropTypes.func.isRequired,
  onHandleUploadProfileImage : PropTypes.func.isRequired,
  percentageImage            : PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

function FormProfile ({ onHandleUpdateUserProfile, onHandleUploadProfileImage, percentageImage }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
            <div className="row featured-boxes login">

              <div className="col-sm-12">
                <div className="featured-box featured-box-secundary default info-content">
                  <div className="box-content">

                    <InformationUser />

                    <h4>Editar Información del usuario</h4>
                      <div className="row">
                        <div className="form-group">
                          <div className="col-md-12">
                            <label> Nombre de usuario</label>
                            <input type="text" id="displayName" className="form-control input-lg" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group">
                          <div className="col-md-12">
                            <label> Foto de perfil </label>
                            <input
                              type      = "file"
                              className = "form-control input-lg"
                              accept    = "image/png, .jpeg, .jpg, image/gif"
                              name      = "photoURL"
                              id        = "photoURL"
                              onChange  = { onHandleUploadProfileImage }
                            />
                            <progress value={ percentageImage } max="100"></progress> { percentageImage } %
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <button className="btn btn-primary pull-right push-bottom" onClick={ onHandleUpdateUserProfile }> Actualizar </button>
                        </div>
                      </div>

                  </div>
                </div>
              </div>

            </div>
        </div>
      </div>
    </div>
  )
}

FormProfile.propTypes = propTypes;

export default FormProfile;
