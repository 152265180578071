import React from 'react';

import ItemSocialNetwork from './ItemSocialNetwork';

const SocialNetworks = () => {
  return (
    <ul className = "social-icons">

      <ItemSocialNetwork
        classItem = { "instagram" }
        url       = {  process.env.REACT_APP_URL_INSTAGRAM }
        label     = { 'Página Instagram' }
      />

      <ItemSocialNetwork
        classItem = { "facebook" }
        url       = { process.env.REACT_APP_URL_FACEBOOK }
        label     = { "Página de Facebook" }
      />

      <ItemSocialNetwork
        classItem = { "twitter" }
        url       = { process.env.REACT_APP_URL_TWITTER }
        label     = { "Página de Twitter" }
      />

      <ItemSocialNetwork
        classItem = { "youtube" }
        url       = { process.env.REACT_APP_URL_YOUTUBE }
        label     = { "Canal de Youtube" }
      />

    </ul>
  )
}

export default SocialNetworks;
