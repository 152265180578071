import React from 'react';

import PrivacyPolicies from '../../components/Global/Footer/PrivacyPolicies';
import Footer from '../../components/Global/Footer';

const PrivacyPoliciesContainer = () => {
  return (
    <div>
      <PrivacyPolicies />
      <Footer />
    </div>
  )
}

export default PrivacyPoliciesContainer;
