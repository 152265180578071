import {
  GET_SLIDE_DATA
} from '../constants';

export const slide_data = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_SLIDE_DATA: {
      return action.payload;
    }

    default:
      return state;

  }
}
