import {
  GET_INFO_CUSTOMER_MAP
} from '../constants';

export const customer_info_location = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_INFO_CUSTOMER_MAP: {
      return action.payload;
    }

    default:
      return state;

  }
}
