import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import moment from 'moment';
import 'moment/locale/es';

import PictureCardHeader from './PictureCardHeader';
import PictureCardFooter from './PictureCardFooter';
import TypeCard from './TypeCard';
import ModalPlace from './ModalPlace';

import './css/partner.css';
import './css/input_comment.css';
import './css/share_social_button.css';

const propTypes = {
  id                  : PropTypes.string.isRequired,
  category            : PropTypes.string.isRequired,
  datePublication     : PropTypes.number.isRequired,
  description         : PropTypes.string.isRequired,
  liked               : PropTypes.bool.isRequired,
  likes               : PropTypes.number.isRequired,
  url                 : PropTypes.array.isRequired,
  avatar              : PropTypes.string.isRequired,
  username            : PropTypes.string.isRequired,
  p_username          : PropTypes.string.isRequired,
  keyChildren         : PropTypes.string.isRequired,
  number_comments     : PropTypes.number,
  partner             : PropTypes.array,
  subcategory         : PropTypes.string.isRequired,
  place               : PropTypes.string.isRequired,
  placeKey            : PropTypes.string.isRequired,
  firstMessage        : PropTypes.bool.isRequired,
  service             : PropTypes.string,
  handleLike          : PropTypes.func.isRequired,
  handleFilterHashtag : PropTypes.func.isRequired
}

class PictureCard extends Component {

  constructor (props) {
    super(props);

    this.state = {
      likes_user : {},
      number_comments: this.props.number_comments,
      modalOpenPlace: false
    }

    this.handleOpenModal  = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillMount () {
    this.getLikesUser();
  }

  renderDescription () {
    var tam = this.props.description.split(" ");
    var res = [];
    var _hastag = "#";

    for (let i  = 0; i < tam.length; i++) {
      if (tam[i].indexOf(_hastag) > -1) {
        res.push(
          <span
            key          = { i }
            data-hashtag = { tam[i] }
            className    = "pictureCard-hashtag"
            onClick      = { this.props.handleFilterHashtag }
          >
            { tam[i] }
          </span>
        )
      } else {
        let d = tam[i] + ' ';
        res.push(
          d
        )
      }
    }

    return (res);

  }

  getLikesUser () {
    var likes_user = [];

    this.firebaseRef2 = firebase.database().ref('messages').child(`${this.props.keyChildren}/likes_user`);
    this.firebaseRef2.on('child_added', snapshot => {
      let like_user = {
        displayName : snapshot.val().displayName,
        email       : snapshot.val().email
      };

      likes_user.push(like_user);
      this.setState({ likes_user });
    });
  }

  RenderPictures () {
    return (
      <TypeCard
        images = { this.props.url }
      />
    )
  }

  handleOpenModal () {
    this.setState({ modalOpenPlace : true });
  }

  handleCloseModal () {
    this.setState({ modalOpenPlace : false });
  }

  render () {
    let datePublication =  moment(this.props.datePublication).fromNow();
    let link_post = `/detail_post/${this.props.keyChildren}`;
    let url_post  =  window.location.href.split('/')[2] + link_post;

    let textComments = "";

    if (this.props.number_comments === 0 && this.props.number_comments <= 1 ) {
      textComments = "comentario";
    } else {
      textComments = "comentarios";
    }

    return (
      <div className = "col-lg-12" key = { this.props.keyChildren }>
        <aside>
          <div className = "panel-default panel-google-plus">
            <PictureCardHeader
              partner         = { this.props.partner }
              avatar          = { this.props.avatar }
              username        = { this.props.username }
              p_username      = { this.props.p_username }
              place           = { this.props.place }
              category        = { this.props.category }
              subcategory     = { this.props.subcategory }
              datePublication = { datePublication }
              link_post       = { link_post }
              service         = { this.props.service }
              handleOpenModal = { this.handleOpenModal }
            />

            <div className = "panel-body">
              <p> { this.renderDescription() } </p>
            </div>

            {  this.RenderPictures() }

            <PictureCardFooter
              likes_user      = { this.state.likes_user }
              likes           = { this.props.likes }
              textComments    = { textComments }
              keyChildren     = { this.props.keyChildren }
              id              = { this.props.id }
              avatar          = { this.props.avatar }
              handleLike      = { this.props.handleLike }
              number_comments = { this.props.number_comments }
              firstMessage    = { this.props.firstMessage }
              description     = { this.props.description }
              url             = { this.props.url }
              url_post        = { url_post }
            />
          </div>
          <div id="main_modal"></div>
          <ModalPlace
            modalOpenPlace   = { this.state.modalOpenPlace }
            handleCloseModal = { this.handleCloseModal }
            placeKey         = { this.props.placeKey }
          />
        </aside>
      </div>
    )
  }
}

export default PictureCard;

PictureCard.propTypes = propTypes;
