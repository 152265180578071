import React from 'react';
import { isEmptyJSON } from '../../functions/ValidationState';
import LikeUser from './LikeUser';

export const renderLikesUser = (likes_user) => {
  if ( isEmptyJSON(likes_user) === true ) {
    let tam = likes_user.length;
    if ( tam < 6 ) {
      return (
        <span className="caja">
          <span className="info">
          {
            likes_user.map( (likes) => {
                return (
                  <LikeUser
                    key         = { likes.email }
                    displayName = { likes.displayName }
                  />
                )
            })
          }
          </span>
        </span>
      )
    } else {
      var tamReal = tam - 5;
      let _text = "";

      if ( tamReal > 1) {
        _text = "personas";
      } else if ( tamReal === 1 ) {
        _text = "persona";
      }

      return (
        <span className="caja">
          <span className="info">
            {
              likes_user.map( (likes, index) => {
                if ( (index ) < 5 ) {
                  return (
                    <LikeUser
                      key         = { likes.email }
                      displayName = { likes.displayName }
                    />
                  )
                }
                return true;
              })
            }
            <div> y { tamReal } { _text } más </div>
          </span>
        </span>
      )
    }
  } else if ( isEmptyJSON(likes_user) === false ) {
    return (
      <span></span>
    )
  }
}
