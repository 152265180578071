import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProviderSocial from './ProviderSocial';
import FormLogIn from './FormLogIn';
import FirebaseClass from "../../../classes/FirebaseClass";
import { dataUser } from '../../../actions/user';
import { isOnline, MessageConnection } from '../../../functions/NavigatorOnline';

class SignInHeader extends Component {

  constructor () {
    super();

    this.state = { signUp: false };

    this.onAuth = this.onAuth.bind(this);

  }

  async onAuth ( evt ) {

    if ( isOnline() === true ) {
      let optionSocial = evt.target.value;
      let info_user = this.props.user || "Anónimo";
      let objFirebase = new FirebaseClass(info_user);

      let user = await objFirebase.AuthProviderSocial(optionSocial);

      this.props.dataUser( user );
    } else if ( isOnline() === false ) {
      MessageConnection();
    }

  }

  renderForm () {
    let { signUp } = this.state;

    if ( signUp === true ) {
      return (
        <div> Formulario SignUp </div>
      )
    } else if ( signUp === false ) {
      return (
        <FormLogIn />
      )
    }
  }

  render () {
    return (
      <li className = "dropdown mega-menu-item mega-menu-signin signin logged">
        <a href="#signin" className = "dropdown-toggle">
          <i className = "fa fa-user"></i> Iniciar Sesión
          <i className = "fa fa-angle-down"></i>
        </a>
        <ul className = "dropdown-menu">
          <li>
            <div className = "mega-menu-content">
              <div className = "row">
                <div className = "col-md-12">
                  <div className = "signin-form">
                    <span className = "mega-menu-sub-title"> Selecciona un proveedor</span>

                    <div className = "row">
                      <div className = "form-group">

                        <ProviderSocial
                          btnColor       = { "primary" }
                          onAuth         = { this.onAuth }
                          valueSocial    = { 1 }
                          icon_social    = { "facebook" }
                          social_network = { "Facebook" }
                        />

                        <ProviderSocial
                          btnColor       = { "danger" }
                          onAuth         = { this.onAuth }
                          valueSocial    = { 2 }
                          icon_social    = { "google" }
                          social_network = { "Google" }
                        />

                      </div>
                    </div>

                    { this.renderForm() }

                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    info_user: state.info_user
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    dataUser
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(SignInHeader);
