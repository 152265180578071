import React, { Component } from 'react';
import firebase from 'firebase/app';

import Navegation from './../Navegation';
import FormProfile from './FormProfile';
import { actions_requests } from '../../../requests';
import { _sweetAlert } from '../../../services/SweetAlert2';

class Profile extends Component {

  constructor () {
    super();

    this.state = {
      percentageImage : 0,
      url_image : null
    }

    this.handleUpdateUserProfile  = this.handleUpdateUserProfile.bind(this);
    this.handleUploadProfileImage = this.handleUploadProfileImage.bind(this);
  }

  handleUpdateUserProfile () {
    let user = firebase.auth().currentUser;
    let displayName_input = document.getElementById('displayName').value;
    let displayName = "", photoURL = "", alert_success = "";
    let { url_image } = this.state;

    if ( displayName_input === '' && url_image === null ) {
      alert_success = "No se ha realizado ningún cambio";
    } else {
      alert_success = "Se ha actualizado la información";
    }

    if ( displayName_input === '' ) {
      displayName = user.displayName;
    } else {
      displayName = displayName_input;
    }

    if ( url_image === null ) {
      photoURL = user.photoURL;
    } else {
      photoURL = url_image;
    }

    user.updateProfile({
      displayName,
      photoURL
    })
    .then( () => {
      
      let url_request = process.env.REACT_APP_API_AUTHENTICATIONS;
      let body = `displayName=${ displayName }&photoURL=${ photoURL }&email=${ user.email }`;

      actions_requests( body, url_request, 'PUT' )
        .then( respo => {

          if ( respo.response === 'success' ) {
            _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Buen Trabajo!!', messageResponse: alert_success, typeResponse: 'success' });
          }

        })
        .catch( err => {
          console.log(err);
        });
    })
    .catch( (err) => {
      console.log(err);
    });
  }

  handleUploadProfileImage (evt) {
    const file = evt.target.files[0];
    const storageRef = firebase.storage().ref(`/fotos_perfil/${file.name}`);
    const task  = storageRef.put(file);

    task.on('state_changed', snapshot => {
      let percentageImage = (( snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);

      snapshot.ref.getDownloadURL().then( downloadURL => {
        
        this.setState({
          percentageImage,
          url_image : downloadURL
        });

        localStorage.setItem("avatar", downloadURL);
      });
      
    },
    error => { console.log(error.message)
    }, () => {
      this.setState({ percentageImage: 100 });
    });
    console.log(file);
  }

  render () {
    return (
      <div>
        <Navegation
          title = { 'Perfil' }
          url   = { '/profile' }
        />
        <FormProfile
          onHandleUpdateUserProfile  = { this.handleUpdateUserProfile }
          onHandleUploadProfileImage = { this.handleUploadProfileImage }
          percentageImage            = { this.state.percentageImage }
        />
      </div>
    )
  }
}

export default Profile;
