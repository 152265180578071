import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  concept : PropTypes.string.isRequired
}

const YoutubeUrls = ({ concept }) => {
  return (
    <span>
      <a
        href = { concept }
        target  = { "_blank"}
      >
        { concept }
      </a>
      <br />
    </span>
  )
}

YoutubeUrls.propTypes = propTypes;

export default YoutubeUrls;
