import React from 'react';

import MegaMenu from './MegaMenu';

const NavbarContainer = () => {
  return (
    <div className = "navbar-collapse nav-main-collapse collapse" id = "divMenu">
      <div className="container">
        <nav className="nav-main mega-menu" id="navMenu" role = "navigation">
          <MegaMenu />
        </nav>
      </div>
    </div>
  )
}

export default NavbarContainer;
