import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { videosNext } from '../../actions/videos_youtube';
import { isEmptyJSON } from '../../functions/ValidationState';

const propTypes = {
  nextPageToken : PropTypes.string,
  uploads       : PropTypes.string.isRequired,
  video_play    : PropTypes.bool.isRequired
}

class ButtonMoreVideo extends Component {

  constructor () {
    super();

    this.nextVideos = this.nextVideos.bind(this);
  }

  nextVideos () {
    this.props.videosNext(this.props.nextPageToken, 8, this.props.uploads, true );
  }

  render () {
    if ( isEmptyJSON(this.props.nextPageToken) === true ) {
      return (
        <div className = "text-center">
          <button type = "button" onClick = { this.nextVideos } className = "btn"> Mostrar Más Videos </button>
        </div>
      )
    } else if ( isEmptyJSON(this.props.nextPageToken) === false ) {
      return ( null );
    }
  }
}

ButtonMoreVideo.propTypes = propTypes;

function mapStateToProps ( state ) {
  return {
    channel_youtube : state.channel_youtube,
    videos_youtube  : state.videos_youtube
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    videosNext
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(ButtonMoreVideo);
