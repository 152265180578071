import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import Videos from './Videos';
import ButtonMoreVideo from './ButtonMoreVideo';

const propTypes = {
  videos_youtube : PropTypes.object.isRequired,
  nextPageToken  : PropTypes.string,
  uploads        : PropTypes.string.isRequired,
  video_play     : PropTypes.bool.isRequired
}

const MoreVideos = ({ videos_youtube, nextPageToken, uploads, video_play }) => {
  return (
    <div>
      <Scrollbars style = {{ width: 200, height: 500 }}>
        <Videos
          videos_youtube = { videos_youtube }
          onClassVideo   = { false }
        />
      </Scrollbars>
      <ButtonMoreVideo
        nextPageToken = { nextPageToken }
        uploads       = { uploads }
        video_play    = { video_play }
      />
    </div>
  )
}

MoreVideos.propTypes = propTypes;

export default MoreVideos;
