import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const propTypes = {
  post_guid    : PropTypes.string.isRequired,
  post_title   : PropTypes.string.isRequired,
  post_content : PropTypes.string.isRequired
}

const PostContent = ({ post_guid, post_title, post_content }) => {
  return (
    <div className = "post-content">
      <h2>
        <a href={ post_guid }>{ post_title }</a>
      </h2>
      <div className = "text-justify">
        { ReactHtmlParser(post_content) }
      </div>
    </div>
  )
}

PostContent.propTypes = propTypes;

export default PostContent;
