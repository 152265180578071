import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import StyleModal from './StyleModal';
import InfoCustomer from './InfoCustomer';

const propTypes = {
  modalOpenPlace   : PropTypes.bool.isRequired,
  handleCloseModal : PropTypes.func.isRequired,
  placeKey         : PropTypes.string.isRequired
}


const ModalPlace = ({ modalOpenPlace, handleCloseModal, placeKey  }) => {
  let main_modal = document.getElementById('main_modal');
  return (
    <Modal
      appElement     = { main_modal }
      isOpen         = { modalOpenPlace }
      style          = { StyleModal }
      onRequestClose = { handleCloseModal }
      contentLabel   = 'Lugar'
    >
      <InfoCustomer placeKey = { placeKey } />
    </Modal>
  )
}

ModalPlace.propTypes = propTypes;

export default ModalPlace;
