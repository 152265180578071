import React from 'react';
import PropTypes from 'prop-types';

import PostImage from './ItemPostNotification/PostImage';
import PostDate from './ItemPostNotification/PostDate';
import PostContentShort from './PostContentShort';
import PostMeta from './PostMeta';

const propTypes = {
  post_key     : PropTypes.string.isRequired,
  post_date    : PropTypes.string.isRequired,
  post_title   : PropTypes.string.isRequired,
  post_content : PropTypes.string.isRequired,
  post_guid    : PropTypes.string.isRequired,
  post_name    : PropTypes.string.isRequired
}

const ItemPost = ({ post_key, post_date, post_title, post_content, post_guid, post_name }) => {
  return (
    <article className = "post post-large">
      <PostImage />
      <PostDate post_date = { post_date } />
      <PostContentShort
        post_title   = { post_title }
        post_content = { post_content }
        post_guid    = { post_guid }
        post_key     = { post_key }
        post_name    = { post_name } 
      />
      <PostMeta
        post_key  = { post_key }
        post_guid = { post_guid }
        post_name = { post_name }
      />
    </article>
  )
}

ItemPost.propTypes = propTypes;

export default ItemPost;
