import React from 'react';

import Knowledge from '../../components/Knowledge';
import Footer from '../../components/Global/Footer';

const KnowledgeContainer = () => {
  return (
    <div>
      <Knowledge />
      <Footer />
    </div>
  )
}

export default KnowledgeContainer;
