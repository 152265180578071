import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { detailsJobs } from '../../actions/jobs';
import { isEmptyJSON } from '../../functions/ValidationState';
import ListPoints from './ListPoints';
import Navegation from '../Global/Navegation';
import ContainerPrincipal from '../HOC/ContainerPrincipal';
import { existsProperty } from '../../functions/Properties';
import { NewString } from '../../functions/ReplaceCharacter';

const propTypes = {
   id_profile   : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
   ]),
   uuid_profile : PropTypes.string.isRequired
}

class Details extends Component {

   componentWillMount () {
      this.props.detailsJobs( this.props.id_profile );
   }

   renderRequirements () {

      let requirements = existsProperty(this.props.profiles, "details.profile_requirements");

      if ( typeof(requirements) !== 'undefined' ) {
         return (
            <ListPoints
               list_points    = { this.props.profiles.details.profile_requirements }
               chunk          = { 4 }
               name_attribute = { 'requirements' }
               class_div      = { 'col-sm-6' }
            />
         )
      }

   }

   renderTechnicalCareers () {

      let technical_careers = existsProperty(this.props.profiles, "details.technical_careers");

      if ( typeof( technical_careers ) !== 'undefined' ) {
         return (
            <ListPoints
               list_points    = { this.props.profiles.details.technical_careers }
               chunk          = { 2 }
               name_attribute = { 'technical_carees' }
               class_div      = { 'col-sm-12' }
            />
         )
      }

   }

   renderCareers () {

      let careers = existsProperty(this.props.profiles, "details.careers");

      if ( typeof( careers ) !== 'undefined' ) {
         return (
            <ListPoints
               list_points    = { this.props.profiles.details.careers }
               chunk          = { 2 }
               name_attribute = { 'carees' }
               class_div      = { 'col-sm-12'}
            />
         )
      }
   }

   renderOffers ( ) {

      let offers = existsProperty( this.props.profiles, "details.offers");

      if ( typeof(offers) !== 'undefined' ) {
         return (
            <ListPoints
               list_points    = { this.props.profiles.details.offers }
               chunk          = { 2 }
               name_attribute = { 'offers' }
               class_div      = { 'col-sm-12' }
            />
         )
      }

   }

   renderDetails () {
      if ( isEmptyJSON(this.props.profiles.details) ) {
         let { details } = this.props.profiles;
         return (
            <div className = "row">
               <div className = "col-md-8">
                  <h4>{ details.name_profile }</h4>
                  <p className = "lead">
                     <pre>
                        { details.description_profile }
                     </pre>
                  </p>

                  <hr />

               </div>
               <div className = "col-md-4">
                  <h4> Perfil Requerido </h4>
                  <ul className = "list-unstyled push-top">
                     <li><i className = "fa fa-map-marker"></i> <strong>Ubicación: </strong> { details.location }</li>
                     <li><i className = "fa fa-clock-o"></i> <strong>Horario: </strong> { details.profile_schedule.schedule }</li>
                     <li><i className = "fa fa-user"></i> <strong>Edad : </strong> { details.age } </li>
                     <li><i className = "fa fa-users"></i> <strong>Género: </strong>{ details.gender.gender }</li>
                     <li><i className = "fa fa-address-book"></i> <strong>Estado Civil: </strong> { details.status_civile.status_point }</li>
                     <li><i className = "fa fa-money"></i> <strong>Sueldo: </strong> { details.salary } </li>
                  </ul>
                  <Link
                     className = "btn btn-success"
                     to = { `/apply_job/${ this.props.id_profile }/${ NewString( details.name_profile , 1) }` }
                 > Aplicar para la vacante </Link>
               </div>
            </div>
         )
      } else if ( !isEmptyJSON(this.props.profiles.details) ) {
         return ( null );
      }
   }

   render () {

      let name_profile = "Trabajos";

      if ( isEmptyJSON(this.props.profiles.details) ) {
         name_profile = this.props.profiles.details.name_profile;
      }

      return (
         <div>
            <Navegation
               title = { 'Trabajos' }
               url   = { '/jobs' }
               isDifferent = { true }
               title2 = { name_profile }
            />
            <div className = "container">
               <hr className="tall"/>
               { this.renderDetails() }
               <div className = "row">
                  <div className = "col-md-8">
                     <h2> Requerimientos </h2>
                     { this.renderRequirements() }
                  </div>
                  <div className = "col-md-4">
                     <h2> Ofrecemos </h2>
                     { this.renderOffers() }
                  </div>
                  
                  <div className = "col-md-8">
                     <div className = "row">                     
                        <div className = "col-md-6">
                           <h2> Carreras Técnicas </h2>
                           { this.renderTechnicalCareers() }
                        </div>
                        <div className = "col-md-6">
                           <h2> Carreras </h2>
                           { this.renderCareers() }
                        </div>
                     </div>
                  </div> 

               </div>
            </div>
         </div>
      )
   }
}

Details.propTypes = propTypes;

function mapStateToProps ( state ) {
   return {
     profiles: state.profiles
   };
}
 
function mapDispatchToProps ( dispatch ) {
   return bindActionCreators({
      detailsJobs
   }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(ContainerPrincipal(Details));