import React from 'react';
import PropTypes from 'prop-types';

import Videos from './Videos';

const propTypes = {
  videos_youtube : PropTypes.object.isRequired
}

const ListVideos = ({ videos_youtube }) => {
  return (
    <div>
      <div className = "ads-bg-top">
        <span>
          <img className = "img-responsive" width = "970" height = "250" src = { process.env.REACT_APP_BANNER_YOUTUBE } alt = "Protics - Youtube" />
        </span>
      </div>
      <div className = "title-topic">
        <span> Videos Subidos </span>
      </div>
      <Videos
        videos_youtube = { videos_youtube }
        onClassVideo   = { true }
      />
    </div>
  )
}

ListVideos.propTypes = propTypes;

export default ListVideos;
