import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const propTypes = {
  description_company : PropTypes.string.isRequired,
  button_company      : PropTypes.string.isRequired
}

const Company = ({ description_company, button_company }) => {
  return (
    <div>
      <div className = "backgroundCompany">
        <div className = "container ptxxxl pbxxxl">
          <div className = "row">

            <div className = "col-md-5 text-center">
              <LazyLoad>
                <img className="img-responsive" src={ process.env.REACT_APP_LOGO_WHITE } alt="Protics" width="350px" />
              </LazyLoad>
            </div>

            <div className="col-md-7 text-center">
              <p className="textCompany">
                { description_company }
              </p>
              <p>
                <Link to="/contact_us">
                  <button className="buttonComment styleButton">
                    <span className="lettersButton">
                      { button_company }
                    </span>
                  </button>
                </Link>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

Company.propTypes = propTypes;

export default Company;
