import React from 'react';

import AboutUs from '../../components/AboutUs';
import Footer from '../../components/Global/Footer';

const AboutUsContainer = () => {
  return (
    <div>
      <AboutUs />
      <Footer />
    </div>
  )
}

export default AboutUsContainer;
