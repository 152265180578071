import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { isOnline } from '../../functions/NavigatorOnline';
import './css/recomendation.css';

const propTypes = {
  opinion     : PropTypes.string,
  date        : PropTypes.string.isRequired,
  numberStars : PropTypes.number.isRequired,
  username    : PropTypes.string.isRequired,
  avatar      : PropTypes.string.isRequired
}

const renderOpinion = ( opinion ) => {

  if ( typeof(opinion) === 'undefined' || opinion === '' ) {
    return ( 'Sólo ha dejado puntuación' );
  } else {
    return opinion;
  }
}

const renderAvatar = ( avatar, username ) => {

  if ( isOnline() === true ) {
    return ( <img className="img-circle pull-left" src={ avatar } width="30px;" height="30px;" alt={ username } /> )
  } else if ( isOnline() === false ) {
    return null;
  }

}

const Recomendation = ({ opinion, date, numberStars, username, avatar }) => {

  let dateFormat = moment(date).format('LL');
  return (
    <div className="col-md-4 divFooter">
      <div className="panel-default panel-google-plus">
        <div className="panel-heading">
          { renderAvatar( avatar, username ) }
          <h3>
            <span className="letterCapitealize">
              { username }
            </span>
            <span className="colorOpinion"> ha opinado de </span>
            <span className="colorCompany"> Protics: </span>
            <span className="oval">
              <span className="colorStar"> { numberStars }</span> <i className="colorStar fa fa-star"></i>
            </span>
          </h3>
          <h5>
            { dateFormat } <i className="fa fa-globe"></i>
          </h5>
        </div>
        <div className="panel-body">
          { renderOpinion(opinion) }
        </div>
      </div>
    </div>
  )

}

Recomendation.propTypes = propTypes

export default Recomendation;
