import { isEmptyJSON } from '../functions/ValidationState';
import ErrorRequest from '../requests/ErrorRequest';
import {
  GET_VIDEOS_YOUTUBE,
  GET_VIDEOS_NEXT_PAGE,
  GET_VIDEOS_PREVIOUS_PAGE,
  SET_VIDEO_PLAY
} from '../constants';

const getVideosYoutube = payload => { return { type: GET_VIDEOS_YOUTUBE, payload }};
const nextPage         = payload => { return { type: GET_VIDEOS_NEXT_PAGE, payload }};
const previousPage     = payload => { return { type: GET_VIDEOS_PREVIOUS_PAGE, payload }};
const setVideoPlay     = payload => { return { type: SET_VIDEO_PLAY, payload }};

export const videosYoutube = ( videoResults, uploads ) => {

  return ( dispatch, getState ) => {

    let url_request = `${process.env.REACT_APP_API_YOUTUBE_VIDEOS}${ videoResults }&playlistId=${ uploads }&key=${ process.env.REACT_APP_KEY_YOUTUBE }`;

    fetch( url_request )
      .then( response => response.json() )
      .then( data => {

        if ( isEmptyJSON(data) === true ) {
          let previousPageToken;
          let nextPageToken = data.nextPageToken;

          if ( typeof(data.prevPageToken) !== 'undefined' ) {
            previousPageToken = data.prevPageToken;
          } else {
            previousPageToken = null;
          }
          let youtubeData = data.items;
          let video_id = "";

          for ( let i in youtubeData ) {
            video_id += youtubeData[i].snippet.resourceId.videoId + ",";
          }

          video_id = video_id.substring(0, video_id.length-1);

          fetch(`${process.env.REACT_APP_API_YOUTUBE_STATISTICS}id=${video_id}&key=${process.env.REACT_APP_KEY_YOUTUBE}`)
            .then( response => response.json() )
            .then( data => {
              let obj = Object.assign( {}, { previousPageToken } , { nextPageToken }, { videos: data.items }, { video_play: false } );
              dispatch(getVideosYoutube(obj));
            });
        }
      })
      .catch( err => {
        ErrorRequest(err, null, 1);
      });

  }

}

export const videosNext = ( nextPageToken, videoResults, uploads, video_play ) => {

  return ( dispatch, getState ) => {


    let url_request = `${process.env.REACT_APP_API_YOUTUBE_TOKEN}${nextPageToken}&part=snippet&maxResults=${videoResults}&playlistId=${uploads}&key=${process.env.REACT_APP_KEY_YOUTUBE}`;

    fetch(url_request, {
      method: 'GET'
    })
    .then( response => response.json() )
    .then( data => {
      let nextPageToken;

      if ( isEmptyJSON(data.nextPageToken) === true ) {
        nextPageToken = data.nextPageToken;
      } else {
        nextPageToken = null;
      }

      let youtubeData = data.items;
      let video_id = "";

      for ( let i in youtubeData ) {
        video_id += youtubeData[i].snippet.resourceId.videoId + ",";
      }

      video_id = video_id.substring(0, video_id.length-1);

      fetch(`${process.env.REACT_APP_API_YOUTUBE_STATISTICS}id=${video_id}&key=${process.env.REACT_APP_KEY_YOUTUBE}`)
        .then( response => response.json() )
        .then( data2 => {
          let obj;

          if ( video_play === false ) {
            obj = Object.assign( {}, { previousPageToken: data.prevPageToken } , { nextPageToken }, { videos: data2.items }, { video_play: video_play } );
          } else if ( video_play === true ) {
            obj = Object.assign(
              {},
              { previousPageToken: data.prevPageToken },
              { nextPageToken },
              { videos: data2.items },
              { video_play: video_play },
              { urlVideo: getState().videos_youtube.urlVideo },
              { idVideo : getState().videos_youtube.idVideo },
              { descripVideo : getState().videos_youtube.descripVideo }
            );
          }

          dispatch(nextPage(obj));
        });

    });

  }
}

export const videosPrevious = ( previousPageToken, videoResults, uploads, video_play ) => {

  return ( dispatch, getState ) => {


    let url_request = `${process.env.REACT_APP_API_YOUTUBE_TOKEN}${previousPageToken}&part=snippet&maxResults=${videoResults}&playlistId=${uploads}&key=${process.env.REACT_APP_KEY_YOUTUBE}`;

    fetch(url_request, {
      method: 'GET'
    })
    .then( response => response.json() )
    .then( data => {
      let previousPageToken;

      if ( isEmptyJSON(data.previousPageToken) === true ) {
        previousPageToken = data.previousPageToken;
      } else {
        previousPageToken = null;
      }

      let youtubeData = data.items;
      let video_id = "";

      for ( let i in youtubeData ) {
        video_id += youtubeData[i].snippet.resourceId.videoId + ",";
      }

      video_id = video_id.substring(0, video_id.length-1);

      fetch(`${process.env.REACT_APP_API_YOUTUBE_STATISTICS}id=${video_id}&key=${process.env.REACT_APP_KEY_YOUTUBE}`)
        .then( response => response.json() )
        .then( data2 => {
          let obj = Object.assign( {}, { previousPageToken } , { nextPageToken: data.nextPageToken }, { videos: data2.items }, { video_play } );
          dispatch(previousPage(obj));
        });

    });

  }
}

export const changeVideoPlay = ( urlVideo, idVideo, descripVideo ) => {

  return ( dispatch, getState ) => {
    let obj = Object.assign(
      {},
      { previousPageToken: getState().videos_youtube.previousPageToken },
      { nextPageToken: getState().videos_youtube.nextPageToken },
      { videos: getState().videos_youtube.videos },
      { video_play: true },
      { urlVideo },
      { idVideo },
      { descripVideo }
    )
    dispatch( setVideoPlay( obj ) );
  }

}
