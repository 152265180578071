export function setDate () {
  const f = new Date();
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre',
    'Octubre', 'Noviembre', 'Diciembre'];

  return f.getDate() + " de " + months[f.getMonth()] + " de " + f.getFullYear();
}

function divideDate ( param_date ) {

  let time_date = param_date.split('T');
  let date = time_date[0].split('-');
  return date;
}

export function getDayDate ( param_date ) {

  let date = divideDate(param_date);
  let day  = date[2];
  return day;
}

export function getCurrentYear () {
  let date = new Date();

  return date.getFullYear();
}

export function getMonthDate ( param_date ) {
  let date = divideDate(param_date);
  let month = date[1];

  switch (month) {

    case '01':
      return 'Ene';
    case '02':
      return 'Feb';
    case '03':
      return 'Mar';
    case '04':
      return 'Abr';
    case '05':
      return 'May';
    case '06':
      return 'Jun';
    case '07':
      return 'Jul';
    case '08':
      return 'Ago';
    case '09':
      return 'Sept';
    case '10':
      return 'Oct';
    case '11':
      return 'Nov';
    case '12':
      return 'Dic';
    default:
      return 'No hay Fecha registrada';
  }

}
