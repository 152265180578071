import { _sweetAlert } from '../services/SweetAlert2';

export const valid_input = ( id, name ) => {
  _sweetAlert({
    typeMessage     : 'sweet_message',
    sweet_message   : 'Validación',
    messageResponse : `${ name } es requerido`,
    typeResponse    : 'warning'
  });
}
