import React, { Component } from 'react';
import firebase from 'firebase/app';

import { isEmptyJSON } from '../../../functions/ValidationState';
import { setRemoveLocalStorage, getItemLocalStorage} from '../../../functions/VLocalStorage';

class InformationUser extends Component {

  constructor () {
    super();

    this.state = { user_info : null };

    this.deletedAccount = this.deletedAccount.bind(this);
    this.fetchDeletedAccount = this.fetchDeletedAccount.bind(this);
  }

  deletedAccount () {
    var user = firebase.auth().currentUser;

    user.delete().then( () => {
      this.fetchDeletedAccount();
    })
    .catch( err => {
      alert(err.message);
    });
  }

  fetchDeletedAccount () {
    let body = `email=${localStorage.email}`;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    fetch(process.env.REACT_APP_API_AUTHENTICATIONS, {
      method : 'DELETE',
      headers: myHeaders,
      body
    })
    .then( response => response.json() )
    .then( data => {

      if ( data.response === 'success' ) {
        alert(data.message);
        setRemoveLocalStorage();
        window.location.reload();
      }

    })
    .catch( err => {
      console.log(err);
    });
  }


  componentWillMount () {
    let photoURL    = getItemLocalStorage('photoURL');
    let displayName = getItemLocalStorage('displayName');
    let email       = getItemLocalStorage('email');

    let user_info = {
      avatar: photoURL,
      displayName,
      email
    };

    this.setState({ user_info });

  }

  renderInfoUser () {
    let { user_info } = this.state;

    if ( isEmptyJSON(user_info) === true ) {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="row">

              <div className="col-md-6">
                <div className="">
                  <div className="thumbnail">
                    <img className="img-responsive" src={ decodeURI(user_info.avatar) } alt = { user_info.displayName } />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="summary entry-summary">
                  <h1 className="shorter"><strong>{ user_info.displayName }</strong></h1>
                  <p className="price">
                    <span className="amount"> <strong>{  user_info.email } </strong> </span>
                  </p>

                  <p className="taller">
                    Esta acción no podrá deshacerse, se eliminará sus datos personales
                    (los que se visualizan en está pantalla) que tenemos en nuestros servidores.
                  </p>

                  <div className="text-center">
                    <p>
                      <button className="btn btn-danger" type="button" onClick={ this.deletedAccount }> Eliminar cuenta </button>
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      )
    } else if ( isEmptyJSON(user_info) === false ) {
      return ( null );
    }
  }


  render () {

    return (
      <div> { this.renderInfoUser() }</div>
    )
  }
}

export default InformationUser;
