import React from 'react';

import TutorialsContent from './TutorialsContent';
import './css/index.css';

const Tutorials = () => {
  return (
    <section className = "testBackground">
      <div className = "tb-padding">
        <div className = "container">
          <TutorialsContent />
        </div>
      </div>
    </section>
  )
}

export default Tutorials;
