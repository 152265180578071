import {
  GET_INFO_CONTACT_COMPANY
} from '../constants';

import { actions_requests } from '../requests';
import ErrorRequest from '../requests/ErrorRequest';

const getInfoContactCompany = payload => { return { type: GET_INFO_CONTACT_COMPANY, payload }};

export const infoContactCompany = () => {

  return ( dispatch, getState ) => {
    let url_request = process.env.REACT_APP_API_WEBSITEINFO_CONTACT;

    actions_requests(null, url_request, 'GET')
      .then( respo => {

        if ( respo.response === 'success' ) {
          dispatch(getInfoContactCompany(respo.data));
        }

      })
      .catch( err => {
        ErrorRequest(err, null, 1);
      });
  }

}
