import React from 'react';

import Header from './Header';
import ImportantItems from './ImportantItems';
import ListItems from './ListItems';
import Politics from './Politics';

const PrivacyPolicies = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <ImportantItems />
        <ListItems />
        <Politics />
      </div>
    </div>
  )
}

export default PrivacyPolicies;
