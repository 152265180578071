import {
  GET_DETAILS_SOLUTION
} from '../constants';

import { actions_requests } from '../requests';
import ErrorRequest from '../requests/ErrorRequest';

const getDetailsSolutions = payload => { return { type: GET_DETAILS_SOLUTION, payload }};

export const allDetailsSolution = ( solutionId ) => {

  return ( dispatch, getState ) => {
    let url_request = process.env.REACT_APP_API_DETAIL_SOLUTIONS;
    let body = `solutionId=${solutionId}`;

    actions_requests(body, url_request, 'POST')
      .then( respo => {

        if ( respo.response === 'success' ) {
          dispatch(getDetailsSolutions(respo.data));
        }

      })
      .catch( err => {
        ErrorRequest(err, null, 3);
      });
  }

}
