import React, { Component } from 'react';
import {  BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from '../components/Global/Header';
import ProjectsContainer from '../containers/ProjectsContainer';
import CustomersContainer from '../containers/CustomersContainer';
import HomeContainer from '../containers/HomeContainer';
import AboutContainer from '../containers/AboutUsContainer';
import ContactUsContainer from '../containers/ContactUsContainer';
import TutorialsContainer from '../containers/TutorialsContainer';
import OpinionsContainer from '../containers/OpinionsContainer';
import FormSignUpContainer from '../containers/FormSignUpContainer';
import RecoverPasswordContainer from '../containers/RecoverPasswordContainer';
import NotFoundContainer from '../containers/NotFoundContainer';
import PrivacyPoliciesContainer from '../containers/PrivacyPoliciesContainer';
import KnowledgeContainer from '../containers/KnowledgeContainer';
import ContpaqContainer from '../containers/ContpaqContainer';
import DetailSolutionsContainer from '../containers/DetailSolutionsContainer';
import NotAuthorizedContainer from '../containers/NotAuthorizedContainer';
import ProfileContainer from '../containers/ProfileContainer';
import LoginContainer from '../containers/LoginContainer';
import Timeline from '../components/Timeline';
import DetailPost from '../components/Timeline/DetailPost';
import PostsNotificationsContainer from '../containers/PostsNotificationsContainer';
import ItemPostNotificationContainer from '../containers/ItemPostNotificationContainer';
import { isEmptyJSON } from '../functions/ValidationState';
import { isOnline } from '../functions/NavigatorOnline';
import { logPageView } from '../components/Global/Analytics';
import Jobs from '../components/Jobs';
import Details from '../components/Jobs/Details';
import ApplyJob from '../components/Jobs/ApplyJob';


class Routes extends Component {

  render () {
    return (
      <Router>
        <div>
          <Header />
          <Route path = "/" component = { logPageView } />

          <Switch>

            <Route exact path = "/" component = { () => {
              return ( <HomeContainer /> )
            }} />

            <Route path = "/about_us" component = { () => {
              return ( <AboutContainer /> )
            }} />

            <Route path = "/contact_us" component = { () => {
              return ( <ContactUsContainer /> )
            }} />

            <Route path = "/tutorials" component = { () => {
              return ( <TutorialsContainer /> )
            }} />

            <Route path = "/opinions" component = { () => {
              return ( <OpinionsContainer /> )
            }} />

            <Route path = "/signup" component = { () => {
              return ( <FormSignUpContainer /> )
            }} />

            <Route path = "/customers_map" component = { () => {
              if ( isOnline() === true ) {
                return ( <CustomersContainer /> )
              } else if ( isOnline() === false ) {
                return null;
              }
            }} />

            <Route path = "/customer/:id" component = { (param) => {
              if ( isOnline() === true ) {
                let id = param.match.params.id;
                return (
                  <ProjectsContainer id = { id } />
                )
              } else if ( isOnline() === false ) {
                return null;
              }

            }} />

            <Route path = "/recover_password" component = { () => {

              if ( isOnline() === true ) {
                return ( <RecoverPasswordContainer /> );
              } else if ( isOnline() === false ) {
                return null;
              }
            }} />

            <Route path = "/privacyPolicy" component = { () => {
              return ( <PrivacyPoliciesContainer /> );
            }} />

            <Route path = "/profile" component = { () => {

              if ( isOnline() === true ) {
                if (  isEmptyJSON(this.props.info_user) === true ) {
                  return ( <ProfileContainer /> );
                } else if ( isEmptyJSON(this.props.info_user) === false ) {
                  return ( <LoginContainer section_option = { "" } /> );
                }
              } else if ( isOnline() === false ) {
                return null;
              }
            }} />

            <Route path = "/knowledge" component = { () => {

              if ( isOnline() === true ) {
                if ( isEmptyJSON(this.props.info_user) === true ) {
                  if ( this.props.info_user.email.split('@')[1] === 'protics.com.mx' ) {
                    return ( <KnowledgeContainer /> );
                  } else {
                    return ( <NotAuthorizedContainer /> );
                  }
                } else if ( isEmptyJSON(this.props.info_user) === false ) {
                  return ( <LoginContainer section_option = { "" } /> );
                }
              } else if ( isOnline() === false ) {
                return null;
              }
            }} />

            <Route path = "/downloads_contpaq" component = { () => {

              if ( isOnline() === true ) {
                if ( isEmptyJSON(this.props.info_user) === true ) {
                  if ( this.props.info_user.email.split('@')[1] === 'protics.com.mx' ) {
                    return ( <ContpaqContainer /> );
                  } else {
                    return ( <NotAuthorizedContainer /> );
                  }
                } else if ( isEmptyJSON( this.props.info_user) === false ) {
                  return ( <LoginContainer section_option = { "" } /> );
                }
              } else if ( isOnline() === false ) {
                return null;
              }
            }} />

            <Route path = "/system/:id/:name_system" component = { (param) => {

              if ( isOnline() === true ) {
                if ( isEmptyJSON(this.props.info_user) === true ) {
                  if ( this.props.info_user.email.split('@')[1] === 'protics.com.mx') {
                    let id          = param.match.params.id;
                    let name_system = param.match.params.name_system;
                    return (
                      <DetailSolutionsContainer
                        solutionId  = { id }
                        name_system = { name_system }
                      />
                    )
                  } else {
                    return ( <NotAuthorizedContainer /> );
                  }
                } else if ( isEmptyJSON(this.props.info_user) === false ) {
                  return (<LoginContainer section_option = { "" } />);
                }
              } else if ( isOnline() === false ) {
                return null;
              }

            }} />

            <Route path = "/timeline" component = { () => {
              if ( isOnline() === true ) {
                return (
                  <Timeline />
                )
              } else if ( isOnline() === false ) {
                return null;
              }
            }} />

            <Route path = "/detail_post/:id" component = { (param) => {
              let id = param.match.params.id;

              if ( isOnline() === true ) {
                if ( isEmptyJSON(this.props.info_user) === true ) {
                  return (
                    <DetailPost keyChildren = { id } />
                  )
                } else if ( isEmptyJSON(this.props.info_user) === false ) {
                  return ( <LoginContainer section_option = { "" } /> );
                }
              } else if ( isOnline() === false ) {
                return null;
              }

            }} />

            <Route path = "/posts_notifications" component = { () => {
              return (
                <PostsNotificationsContainer />
              )
            }}/>

            <Route path = "/item_post/:post_guid/:post_name" component = { (param) => {
              let post_guid = param.match.params.post_guid;
              let post_name = param.match.params.post_name;
              return (
                <ItemPostNotificationContainer
                  post_guid = { post_guid }
                  post_name = { post_name }
                />
              );
            }} />

            <Route path = "/webmail" component = { () => {
              if ( isOnline() === true ) {
                window.location = process.env.REACT_APP_WEBMAIL;
              } else if ( isOnline() === false ) {
                return null;
              }

            }} />

            <Route path = "/soporte" component = { () => {
              if ( isOnline() === true ) {
                window.location = process.env.REACT_APP_URL_SOPORTE;
              } else if ( isOnline() === false ) {
                return null;
              }
            }} />

            <Route path = "/protics_system" component = { () => {

              if ( isOnline() === true ) {
                window.location = process.env.REACT_APP_LINK_BACKOFFICE;
              } else if ( isOnline() === false ) {
                return null;
              }
            }} />

            <Route path = "/jobs" component = { () => {
              return ( <Jobs /> );
            }} />

            <Route path = "/job_details/:id_profile/:uuid_profile" component = { ( param ) => {
              let id_profile = param.match.params.id_profile;
              let uuid_profile = param.match.params.uuid_profile;
                return ( <Details id_profile = { id_profile } uuid_profile = { uuid_profile } /> );
            }} />

            <Route path = "/apply_job/:id_profile/:name_profile" component = { (param) => {
              let id_profile = param.match.params.id_profile;
              let name_profile = param.match.params.name_profile;
              return ( <ApplyJob id_profile = { id_profile } name_profile = { name_profile } /> );
            }} />

            <Route component = { NotFoundContainer } />

          </Switch>

        </div>
      </Router>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    info_user: state.info_user
  };
}

export default connect( mapStateToProps, null)(Routes);
