import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';

import PictureCard from './PictureCard';
import Spinner from '../Global/Spinner';
import { listPost } from '../../actions/timeline';
import { getItemLocalStorage } from '../../functions/VLocalStorage';
import TimeLineClass from '../../classes/TimeLineClass';

const propTypes = {
  handleFilterHashtag : PropTypes.func.isRequired
}

class PictureList extends Component {

  constructor () {
    super();

    this.handleLike = this.handleLike.bind(this);
  }

  handleCheckUser (snapshotKey) {
    var valor = [];
    firebase.database().ref(`messages/${snapshotKey}`).child('likes_user').orderByChild('email').equalTo(getItemLocalStorage('email')).on('child_added', (sna) => {
      valor = sna.val().email;
    });

    return valor;
  }

 async handleLike ( msgId ) {

    let objTimeLine = new TimeLineClass();
    let firebaseLike = await objTimeLine.likePost( msgId, this.props.posts_timeline.messages);
    let user = Object.assign({}, this.props.posts_timeline.user);
    user.likes.push(msgId);
    const onlyOnce = firebaseLike.filter( messa => messa.id === msgId );

    if( this.handleCheckUser(onlyOnce[0].keyChildren).length === 0 ) {
      let messages = { messages: firebaseLike, increments:this.props.posts_timeline.increments };
      setTimeout( () => this.props.listPost(messages, user), 1000);
    }

  }

  renderPosts () {

    if ( typeof(this.props.posts_timeline) !== 'undefined' && this.props.posts_timeline !== null ) {
      return (
       this.props.posts_timeline.messages.map( m => {
         return (
           <PictureCard
            key                 = { m.id }
            id                  = { m.id }
            category            = { m.category_service.categoryName }
            datePublication     = { m.date }
            description         = { m.description }
            liked               = { m.liked }
            likes               = { m.likes }
            url                 = { m.url }
            avatar              = { m.user.avatar }
            username            = { m.user.username }
            p_username          = { m.user.username }
            keyChildren         = { m.keyChildren }
            number_comments     = { m.number_comments }
            partner             = { m.partner }
            subcategory         = { m.subcategory_service.subcategoryName }
            place               = { m.place.name }
            placeKey            = { m.place.key }
            firstMessage        = { m.firstMessage }
            service             = { m.service.serviceName }
            handleLike          = { () => this.handleLike(m.id) }
            handleFilterHashtag = { this.props.handleFilterHashtag }
           />
         )
       }).reverse()
      )
    } else {
      return ( <Spinner />);
    }

  }

  render () {
    return (
      this.renderPosts()
    )
  }
}

PictureList.propTypes = propTypes;

function mapStateToProps ( state ) {
  return {
    posts_timeline : state.posts_timeline,
    info_user      : state.info_user
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    listPost
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(PictureList);
