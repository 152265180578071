import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { infoContactCompany } from '../../../actions/info_contact_company';

class ContactCompany extends Component {

  componentDidMount () {
    if ( this.props.info_contact_company === null ) {
      this.props.infoContactCompany();
    }
  }

  renderInfoContact () {
  if ( typeof(this.props.info_contact_company) !== 'undefined' && this.props.info_contact_company !== null ) {
      let email = `mailto:${this.props.info_contact_company[4].info_value}`;
      return (
        <ul className = "contact">
          <li>
            <p>
              <i className="fa fa-map-marker"></i> <strong>Dirección:</strong>
              { this.props.info_contact_company[0].info_value }
            </p>
          </li>
          <li>
            <p>
              <i className="fa fa-phone"></i> <strong>Teléfono:</strong>
              <a href = {`tel:+52${ this.props.info_contact_company[1].info_value }`}> { this.props.info_contact_company[1].info_value } </a>
            </p>
          </li>
          <li>
            <p>
              <i className="fa fa-envelope"></i> <strong>Correo Electrónico:</strong> <a href={email}>{ this.props.info_contact_company[4].info_value }</a>
            </p>
          </li>
          <li>
            <p>
              <a href={ process.env.REACT_APP_API_WHATSAPP } target="_blank" rel="noopener noreferrer">
                <i className="fa fa-whatsapp"></i>
                Enviar mensaje
              </a>
            </p>
          </li>
        </ul>
      )
    }
  }

  render () {
    return (
      <div>
        { this.renderInfoContact() }
      </div>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    info_contact_company: state.info_contact_company
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    infoContactCompany
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(ContactCompany);
