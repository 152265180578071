import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import firebase from 'firebase/app';
import moment from 'moment';

import InputResponse from './InputResponse';
import ItemComment from './ItemComment';

const propTypes = {
  avatar   : PropTypes.string.isRequired,
  username : PropTypes.string.isRequired,
  date     : PropTypes.string.isRequired,
  comment  : PropTypes.string.isRequired,
  id       : PropTypes.string.isRequired
}

class Comment extends Component {

  constructor (props) {
    super(props);

    this.state = {
      responses : [],
      openText: false
    }

    this.handleResponseComment = this.handleResponseComment.bind(this);
    this.handleOpenText        = this.handleOpenText.bind(this);
  }

  componentWillMount () {

    this.refComments = firebase.database().ref('messages').child(`${this.props.keyMessage}/comments/${this.props.keyComment}/response`);
    this.refComments.on('value', snapshot => {
      var responses = [];
      snapshot.forEach((childSnapshot) => {
        responses.push(childSnapshot.val());
      });
      setTimeout(() => {
        this.setState({
          responses
        });
      }, 1000 );

    });
  }

  componentWillUnmount () {
    this.refComments.off();
  }


  handleResponseComment (id, keyMessage) {
    var response = document.getElementById(id).value;
    let newResponse = {
      response: response,
      id: uuid.v4(),
      date: Date.now(),
      user: {
        username: localStorage.getItem('displayName'),
        avatar:   localStorage.getItem('avatar')
      }
    }
    var number_comments = 0;
      firebase.database().ref(`/messages/${keyMessage}`).child('number_comments').on('value', (snap) => {
      number_comments = snap.val();
    });
    number_comments += 1;
    firebase.database().ref(`/messages/${keyMessage}`).update({ number_comments });


    firebase.database().ref(`messages/${this.props.keyMessage}/comments`).orderByChild("id").equalTo(id).on('child_added', (snapshot) => {
      firebase.database().ref(`messages/${this.props.keyMessage}/comments/${snapshot.key}`).child('response').push(newResponse);
      this.setState({
        responses: this.state.responses.concat([newResponse]),
        openText: false
      });
    });

    document.getElementById(id).value = '';
  }

  handleOpenText (event) {
    event.preventDefault();
    this.setState({ openText: true })
  }

  renderInputResponse () {
    if(this.state.openText) {
      return (
        <InputResponse
          buttonId          = { this.props.id }
          onResponseComment = {() => this.handleResponseComment(this.props.id, this.props.keyMessage) }
        />
      )
    }else {
      return (
        <div onClick={ this.handleOpenText }>
          <i className="fa fa-comments-o" ></i> Responder
        </div>
      )
    }
  }

  render () {
    return (
      <ul className="comments-list">
        <li className="comment">
          <a href = "#avatar" className="pull-left">
            <img className="avatar" src={ this.props.avatar } alt="avatar"/>
          </a>
          <div className="comment-body">
            <div className="comment-heading">
                <h4 className="user">{ this.props.username }</h4>
                <h5 className="time"> { this.props.date }</h5>
            </div>
            <p>{ this.props.comment }</p>
            { this.renderInputResponse() }
          </div>
          { this.state.responses.map(res => {
            let dateFormat = moment(res.date).fromNow();
            return (
              <ItemComment
                key        = { res.id }
                avatar     = { res.user.avatar}
                username   = { res.user.username }
                dateFormat = { dateFormat }
                response   = { res.response }
              />
            )
          }) }
        </li>
      </ul>
    )
  }
}

Comment.propTypes = propTypes;

export default Comment;
