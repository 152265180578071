import React from 'react';
import PropTypes from 'prop-types';

import InfoStars from './InfoStars';
import { quantityElements } from '../../functions/Functions';
import './css/ranking.css';

const propTypes = {
  onNumberStars : PropTypes.number.isRequired,
  stars         : PropTypes.arrayOf(PropTypes.number).isRequired
}

const InfoRecomendations = ({ onNumberStars, stars }) => {

  var d = [];
  var total = 0;
  var average = 0;
  var obj = quantityElements(stars);
  for (var i in obj ) {
    total += obj[i];
    average += (i * obj[i]);
     d.push(
       <li key = { i }>
          <i className="colorStar_ fa fa-star"></i>
          <strong> <span className="starItem"> { i } </span> </strong> <span className="backgroundStar"> { obj[i] } ....</span>
       </li>
     )
  }

  average = parseFloat((average / total).toFixed(2));

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="text-center">
            <h1> ¿Qué opinan nuestros clientes ? </h1>
          </div>
          <InfoStars
            average     = { average }
            total       = { total }
            starNumbers = { d }
          />
        </div>
      </div>
    </div>
  )
}

InfoRecomendations.propTypes = propTypes;

export default InfoRecomendations;
