import React from 'react';
import PropTypes from 'prop-types';

import { isOnline } from '../../functions/NavigatorOnline';
import './css/twitter.css';

const propTypes = {
  title_suscribe_twitter       : PropTypes.string.isRequired,
  description_suscribe_twitter : PropTypes.string.isRequired
}

const renderOnline = () => {

  if ( isOnline() === true ) {
    return (
      <iframe
        src={ process.env.REACT_APP_IFRAME_TWITTER }
        title="Follow TwitterDev on Twitter"
        width="80"
        height="30"
        className="buttonFollow"
        >
      </iframe>
    )
  } else if ( isOnline() === false ) {
    return (
      <div>
        No hay conexión Internet
      </div>
    )
  }

}

const Twitter = ({ title_suscribe_twitter, description_suscribe_twitter }) => {
  return (
    <div>
      <div className="backgroundTwitter">
        <div className="container ptxxxl pbxxxl">
          <div className="row">

            <div className="col-md-6 text-center">
              <h1 className="titleTwitter">{ title_suscribe_twitter }</h1>
              <p className="contentTwitter"> { description_suscribe_twitter }</p>
              { renderOnline() }
            </div>

            <div className="col-md-6 text-center">
              <a href = { process.env.REACT_APP_URL_TWITTER } target="_blank" rel="noopener noreferrer" aria-label = "Página de Twitter" >
                <i className="fa fa-twitter banner-font-image" aria-hidden="true"></i>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

Twitter.propTypes = propTypes;

export default Twitter;
