import React from 'react';
import PropTypes from 'prop-types';

import ItemInformation from './ItemInformation';
import ItemSchedule from './ItemSchedule';

const propTypes = {
  info_contact_company : PropTypes.array.isRequired
}

const ItemsInfo = ({ info_contact_company }) => {
  return (
    <div>
      <h4 className="push-top"> { info_contact_company[9].info_value } </h4>
      <p> { info_contact_company[10].info_value } </p>
      <hr />
      <h4><strong> { info_contact_company[11].info_value } </strong></h4>
      <ul className="lis-unstyled">
        <ItemInformation
          icon    = "fa fa-map-marker"
          title   = "Dirección: "
          concept = { info_contact_company[0].info_value }
        />

        <ItemInformation
          icon    = "fa fa-phone"
          title   = "Teléfono: "
          concept = { info_contact_company[1].info_value }
        />

        <ItemInformation
          icon    = "fa fa-phone"
          title   = "Teléfono Oficina: "
          concept = { info_contact_company[2].info_value }
        />

        <ItemInformation
          icon    = "fa fa-whatsapp"
          title   = "Whatsapp: "
          concept = { info_contact_company[3].info_value }
        />

        <ItemInformation
          icon    =  " fa fa-envelope"
          title   = "Correo Electrónico :"
          concept = { info_contact_company[4].info_value }
        />
      </ul>

      <h4><strong> { info_contact_company[5].info_value } </strong></h4>
      <ul className="lis-unstyled">
        <ItemSchedule
          icon    = "fa fa-clock-o"
          concept = { info_contact_company[6].info_value }
        />

        <ItemSchedule
          icon    = "fa fa-clock-o"
          concept = { info_contact_company[7].info_value }
        />

        <ItemSchedule
          icon    = "fa fa-clock-o"
          concept = { info_contact_company[8].info_value }
        />

      </ul>
    </div>
  )
}

ItemsInfo.propTypes = propTypes;

export default ItemsInfo;
