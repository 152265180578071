import {
  GET_DETAILS_SOLUTION
} from '../constants';

export const details_solution = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_DETAILS_SOLUTION: {
      return action.payload;
    }

    default:
      return state;

  }
}
