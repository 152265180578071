import React from 'react';

import ContentForm from './ContentForm';
import InformationCompany from './InformationCompany';
import Map from './Map';
import { isOnline } from '../../functions/NavigatorOnline';

const RenderMap = () => {

  if ( isOnline() === true ) {
    return ( <Map /> )
  } else if ( isOnline() === false ) {
    return null;
  }

}

const Contact = () => {
  return (
    <div>
      { RenderMap() }
      <div className = "container">
        <ContentForm />
        <InformationCompany />
      </div>
    </div>
  )
}

export default Contact;
