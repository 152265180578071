import {
  GET_CUSTOMERS
} from '../constants';

export const customers = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_CUSTOMERS: {
      return action.payload;
    }

    default:
      return state;

  }
}
