import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

const propTypes = {
  title       : PropTypes.string.isRequired,
  description : PropTypes.string.isRequired,
  url_image   : PropTypes.string.isRequired
}

const FeatureBox = ({ title, description, url_image }) => {
  return (
    <div className = "feature-box secundary">
      <div className = "feature-box-info">
        <div className="text-center spaceImage">
          <LazyLoad>
            <img className = "img-responsive" src = { url_image } alt = { title } />
          </LazyLoad>
        </div>
        <div className = "text-center space_title_mission">
          <span className = "title_prin_mission"> { title }</span>
        </div>
        <p className = "tall contentPrincipal">{ description }</p>
      </div>
    </div>
  )
}

FeatureBox.propTypes = propTypes;

export default FeatureBox;
