export function shareFacebook (url, title, description, image) {
    const FB = window.FB;
    FB.ui({
      method: 'share_open_graph',
      action_type: 'og.shares',
      action_properties: JSON.stringify({
  			object: {
  				'og:url'         : 'https://' + url,
  				'og:title'       : title,
  				'og:description' : description,
  				'og:image'       : image
  			}
  		}),
    }, function(response){});
}
