import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  avatar     : PropTypes.string.isRequired,
  username   : PropTypes.string.isRequired,
  dateFormat : PropTypes.string.isRequired,
  response   : PropTypes.string.isRequired
}

const ItemComment = ({ avatar, username, dateFormat, response }) => {
  return (
    <ul className="comments-list">
      <li className="comment">
        <a href = "#avatar" className="pull-left">
          <img src={ avatar } className="avatar" alt="avatar" />
        </a>
        <div className="comment-body">
          <div className="comment-heading">
            <h4 className="user"> { username } </h4>
            <h5 className="time"> { dateFormat } </h5>
          </div>
          <p>{ response }</p>
        </div>
      </li>
    </ul>
  )
}

ItemComment.propTypes = propTypes;

export default ItemComment;
