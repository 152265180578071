import Capitalize from 'capitalize-it';

export function NewString (cad, option) {
  cad = cleanString( cad );

  let new_cad = "";
  if ( option === 1 ) {
    new_cad = StringLower(cad.replace(/\s/g,"-"));
  } else if ( option === 2 ) {
    new_cad = StringUpper(cad.replace(/-/g,' '))
  } else if ( option === 3 ) {
    new_cad = capitalLetter(cad.replace(/-/g,' '));
  } else if ( option === 4 ) {
    new_cad = capitalLetter(cad.toLowerCase());
  }
  return new_cad;
}

function StringLower (cad) {
  let new_cad = cad.toLowerCase();
  return new_cad;
}

function StringUpper (cad) {
  let new_cad = cad.toUpperCase();
  return new_cad;
}

function capitalLetter ( cad ) {
  return Capitalize(cad);
}

function cleanString (string) {
  // delete acents
  string = string.replace(/á/gi, "a");
  string = string.replace(/é/gi, "e");
  string = string.replace(/í/gi, "i");
  string = string.replace(/ó/gi, "o");
  string = string.replace(/ú/gi, "u");

  return string;
}
