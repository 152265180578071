import React, { Component } from 'react';
import firebase from 'firebase/app';

import Routes from './Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmptyJSON } from './functions/ValidationState';
import { dataUser } from './actions/user';
import { setLocalStorage } from './functions/VLocalStorage';

class App extends Component {

  componentWillMount () {

    firebase.auth().onAuthStateChanged( user => {

      if ( isEmptyJSON(user) === true ) {

        if ( user.emailVerified === true ) {
          let photoURL = "";

          if ( user.providerData[0].providerId === 'google.com' || user.providerData[0].providerId === 'password' ) {
            photoURL = user.photoURL;
          } else if ( user.providerData[0].providerId === 'facebook.com' ) {
            photoURL = user.providerData[0].photoURL;
          }

          setLocalStorage( user.displayName, photoURL, user.email, user.providerData[0].providerId );
          let objUser = Object.assign( {}, { displayName: user.displayName } , { photoURL }, { email: user.email }, { providerId: user.providerData[0].providerId } );
          this.props.dataUser(objUser);
        }
      }

    });

  }

  render() {
    return (
      <div>
        <Routes />
      </div>
    );
  }
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    dataUser
  }, dispatch)
}

export default connect( null, mapDispatchToProps)(App);
