import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import { customerList } from '../../actions/customers';
import { separateElements } from '../../functions/Functions';
import { settings } from '../../constants/settings_slick';
import { isOnline } from '../../functions/NavigatorOnline';
import './css/clients_place.css';

const propTypes = {
  title_clients : PropTypes.string.isRequired
}

class CustomersPlace extends Component {

  componentDidMount () {

    if ( this.props.customers === null ) {
      this.props.customerList();
    }

  }

  renderCustomers () {
    if ( typeof(this.props.customers) !== 'undefined' && this.props.customers !== null ) {
      let results = separateElements(this.props.customers, 18 );
      var arrayComponent = [];
      for (var x = 0; x < results.length; x++) {
        arrayComponent.push(
          <div className=" text-center layout-company-logos" key={ x }>
           {  results[x].map( (c) => {
                let url_customer = `/customer/${c.Sys_GUID}`;
                return (
                  <Link to={ url_customer } key={ c.Sys_GUID }>
                    <LazyLoad>
                      <img key={ c.Sys_GUID } className="img-resposive" src={ c.url_photo } alt={ c.url_photo } />
                    </LazyLoad>
                  </Link>
                )
              })
            }
          </div>
        )
      }
      return(arrayComponent);

    }

  }

  renderCustomersGoogleMaps () {
    if ( isOnline() === true ) {
      return (
        <Link to="/customers_map">
          <LazyLoad>
            <img src={ process.env.REACT_APP_ICON_GOOGLE_MAP } className="img-resposive" alt="clientes-protics-mapas" />
          </LazyLoad>
          <span className="titleCustomerMaps"> Visualizar clientes en google maps </span>
        </Link>
      )
    } else if ( isOnline() === false ) {
      return null;
    }
  }

  render () {
    return (
      <div>
        <div className = "backgroundClientsPlace">
          <div className = "container ptxxxl pbxxxl" >
            <div className = "text-center section-love-header">
              <h4 className = "tittle_">{ this.props.title_clients }</h4>
              <div>
                { this.renderCustomersGoogleMaps() }
              </div>
            </div>
            {
              <Slider {...settings }>
                { this.renderCustomers() }
              </Slider>
            }
          </div>
        </div>
      </div>
    )
  }
}

CustomersPlace.propTypes = propTypes;

function mapStateToProps ( state ) {
  return {
    customers: state.customers
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    customerList
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(CustomersPlace);
