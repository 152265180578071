import React, { Component } from 'react';

import Footer from '../Global/Footer';

const ContainerPrincipal = ( WrappedComponent )  => {

   class Container extends Component {
      render () {
         return (
            <div>
               <WrappedComponent { ...this.props } />
               <Footer />
            </div>
         )
      }
   }

   return Container;

}

export default ContainerPrincipal;