import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  btnColor       : PropTypes.string.isRequired,
  onAuth         : PropTypes.func.isRequired,
  valueSocial    : PropTypes.number.isRequired,
  icon_social    : PropTypes.string.isRequired,
  social_network : PropTypes.string.isRequired
}

const ProviderSocial = ({ btnColor, onAuth, valueSocial, icon_social, social_network }) => {
  return (
    <div className = "col-md-4">
      <button
        className = { ` btn btn-${btnColor} btn-lg` }
        onClick   = { onAuth }
        value     = { valueSocial }
      >
        <i className = { `fa fa-${icon_social}` }></i> { social_network }
      </button>
    </div>
  )
}

ProviderSocial.propTypes = propTypes;

export default ProviderSocial;
