import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title_suscribe_youtube       : PropTypes.string.isRequired,
  description_suscribe_youtube : PropTypes.string.isRequired
}

const Youtube = ({ title_suscribe_youtube, description_suscribe_youtube }) => {
  return (
    <div>
      <div id = "divYoutube" className = "" >
        <div className = "backgroundYoutube">
          <div className = "container ptxxxl pbxxxl">
            <div className = "row">

              <div className = "col-md-6 text-center">
                <a href = { process.env.REACT_APP_URL_YOUTUBE } target="_blank" rel="noopener noreferrer" aria-label = "Canal de Youtube">
                  <i className="fa fa-youtube banner-font-image"></i>
                </a>
              </div>

              <div className = "col-md-6 text-center">
                <h2 className = "titleYoutube"> { title_suscribe_youtube }</h2>
                <p className = "descriptionYoutube">
                  <b>
                    { description_suscribe_youtube }
                  </b>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Youtube.propTypes = propTypes;

export default Youtube;
