import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  displayName : PropTypes.string.isRequired
}

const LikeUser = ({ displayName }) => {
  return (
    <div> { displayName } </div>
  )
}

LikeUser.propTypes = propTypes;

export default LikeUser;
