import {
  GET_USER
} from '../constants';

const getUser = payload => { return { type: GET_USER, payload }};

export const dataUser = ( user ) => {

  return ( dispatch, getState ) => {
    dispatch( getUser(user) );
  }

}
