import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getYoutubeLikeToDisplay } from './FunctionsYoutube';

const propTypes = {
  title_short : PropTypes.string.isRequired,
  duration    : PropTypes.string.isRequired,
  viewCount   : PropTypes.string.isRequired,
  publishedAt : PropTypes.string.isRequired
}

const StatisticsVideo = ({ title_short, duration, viewCount, publishedAt }) => {
  return (
    <div>
      <h2>{ title_short }</h2>
      <span className="duration">{ getYoutubeLikeToDisplay(moment.duration(duration).as('milliseconds')) }</span>
      <p className="styleViews">
        { viewCount } vistas <i className="fa fa-caret-right"></i> { moment(publishedAt).fromNow() }
      </p>
    </div>
  )
}

StatisticsVideo.propTypes = propTypes;

export default StatisticsVideo;
