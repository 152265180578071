import {
  GET_INFO_CHANNEL_YOUTUBE
} from '../constants';

export const channel_youtube = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_INFO_CHANNEL_YOUTUBE: {
      return action.payload;
    }

    default:
      return state;

  }
}
