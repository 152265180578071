import {
   GET_LIST_JOBS,
   GET_DETAILS_PROFILE_JOB,
   UPDATE_PROFILE_DOCUMENTS,
   CLEAN_PROSPECT_PROFILE
 } from '../constants';
 
 export const profiles = ( state= {}, action ) => {
 
   switch ( action.type ) {
 
     case GET_LIST_JOBS: {
       return action.payload;
     }

     case GET_DETAILS_PROFILE_JOB : {
       return action.payload;
     }

     case UPDATE_PROFILE_DOCUMENTS : {
       return action.payload;
     }

     case CLEAN_PROSPECT_PROFILE : {
       return action.payload;
     }
 
     default:
       return state;
 
   }
}