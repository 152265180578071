import React, { Component } from 'react';

var map,mar,marf, start, end;
const google = window.google;
//varibales para los servicios de rutas de google mpas
var directionsDisplay;
var directionsService = new google.maps.DirectionsService();

class Map extends Component {

  componentDidMount () {
    map = this.cargarMapa(map,process.env.REACT_APP_LATITUDE, process.env.REACT_APP_LONGITUDE);
    //cargamos los marcadores al mapa
    mar = this.cargarMarcador(mar,map,process.env.REACT_APP_LATITUDE,process.env.REACT_APP_LONGITUDE,false,process.env.REACT_APP_CLOUDINARY_MARKER_RED);
    // Asignar ruta del usuario
    this.myLocation();
    this.obtenerRuta();
  }

  cargarMapa(map,lat,lon) {
    var styles = {
    night: [
          {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
          {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
          {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{color: '#d59563'}]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{color: '#d59563'}]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{color: '#263c3f'}]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{color: '#6b9a76'}]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{color: '#38414e'}]
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{color: '#212a37'}]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{color: '#9ca5b3'}]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{color: '#746855'}]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{color: '#1f2835'}]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{color: '#f3d19c'}]
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{color: '#2f3948'}]
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{color: '#d59563'}]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{color: '#17263c'}]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{color: '#515c6d'}]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{color: '#17263c'}]
          }
        ]
      };
      var mapOptions = {
          center: new google.maps.LatLng(lat, lon),
          zoom: 15,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          zoomControlOptions: {
              style: google.maps.ZoomControlStyle.DEFAULT,
              position: google.maps.ControlPosition.LEFT_CENTER
          },
          scrollwheel: true,
          panControl:true,
          streetViewControl:true,
          styles: styles.night
      };
      map = new google.maps.Map(document.getElementById('map'),mapOptions);
    return map;
  }

  //funcion que centra el mapa
  centrarMapa(map,lat,lon){
      map.panTo(new google.maps.LatLng(lat, lon));
  }

  //funcion para poner un marcador
cargarMarcador(marker,map,lat,lon,dr,im){
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lon),
      draggable:dr,
      icon: im,
    });
    //marker.setMap(map);
    this.centrarMapa(map,lat,lon);
    return marker;
}

myLocation () {
  var _latitude = "", _longitude = "";

  if ( sessionStorage.Latitude === undefined && sessionStorage.Longitude === undefined ) {
    _latitude  = process.env.REACT_APP_LATITUDE_CENTRAL;
    _longitude = process.env.REACT_APP_LONGITUDE_CENTRAL;
  } else {
    _latitude  = sessionStorage.Latitude;
    _longitude = sessionStorage.Longitude;
  }

  marf = this.cargarMarcador(marf,map,_latitude,_longitude,false,process.env.REACT_APP_CLOUDINARY_MARKER_BLUE);
}


obtenerRuta(){
  directionsDisplay = new google.maps.DirectionsRenderer();
  directionsDisplay.setMap(map);

  //agregamos evento para que al terminar de arrastrar los marcadores se marque la ruta
  start = new google.maps.LatLng(mar.position.lat(),mar.position.lng());
  end = new google.maps.LatLng(marf.position.lat(),marf.position.lng());
    var request = {
      origin:start,
      destination:end,
      travelMode: google.maps.TravelMode.DRIVING
    };
    directionsService.route(request, function(result, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(result);
         google.maps.event.addListener(mar, 'click', function() {
         //window.open("https://www.google.es/maps/place/Protics/@process.env.REACT_APP_LATITUDE,process.env.REACT_APP_LONGITUDE,17z/data=!3m1!4b1!4m2!3m1!1s0x8f5ba49ff8535cb5:0x7570327010d5d7e4!6m1!1e1", '_blank');
   });
      }
    });

        //inicializamos el punto de partida y de llegada con las posiciones de los marcadores
        start = new google.maps.LatLng(mar.position.lat(),mar.position.lng());
        end = new google.maps.LatLng(marf.position.lat(),marf.position.lng());
        //hacemos una peticion al servicio de rutas
        var request2 = {
            origin:start,
            destination:end,
            travelMode: google.maps.TravelMode.DRIVING
          };
          directionsService.route(request2, function(result, status) {
            //si la peticion fue exitosa pintamos la ruta
            if (status === google.maps.DirectionsStatus.OK) {
              directionsDisplay.setDirections(result);
            }
          });

}

  render () {
    return (
      <div id="map" className="google-map"></div>
    )
  }
}

export default Map;
