import React from 'react';

const ImportantItems = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <h1> Puntos Importantes de las Políticas de Privacidad </h1>

        <div className="feature-box">

          <div className="feature-box-icon"></div>
          <div className="feature-box-info">
            <h4 className="shorter">1.</h4>
            <p className="tall">Esta compañía está comprometida con la seguridad de los datos de sus usuarios.
            Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado,
            lo hacemos asegurando que sólo se emplea de acuerdo con los términos de este documento.
            Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le
            recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con
            dichos cambios.</p>
          </div>

        </div>

        <div className="feature-box">
          <div className="feature-box-icon"></div>
          <div className="feature-box-info">
          <h4 className="shorter"> 2. </h4>
          <p className="tall">Al utilizar los servicios, el usuario acepta los términos de esta política de privacidad.
          El usuario no deberá utilizar los Servicios si no está de acuerdo con esta Política de privacidad o
          con cualquier otro acuerdo que rija el uso que hace de los Servicios.
          </p>
          </div>
        </div>

        <div className="feature-box">
          <div className="feature-box-icon"></div>
          <div className="feature-box-info">
            <h4 className="shorter"> 3. </h4>
            <p className="tall">
              Si algún concepto requiere más explicación, no dudes en escribirnos un correo a <a href="mailto:info@protics.com.mx">info@protics.com.mx</a>.
            </p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ImportantItems;
