import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import ItemMember from './ItemMember';

const propTypes = {
  team_members : PropTypes.array.isRequired
}

const ListMember = ({ team_members }) => {
  return (
    team_members.map( tm => {
      return (
        <ItemMember
          key            = { uuid.v4() }
          short_name     = { tm.short_name }
          photo          = { tm.photo }
          work_position  = { tm.work_position }
          description    = { tm.description }
          email          = { tm.email }
        />
      )
    })
  )
}

ListMember.propTypes = propTypes;

export default ListMember;
