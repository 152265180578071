import {
  GET_INFO_ITEM_POST
} from '../constants';

export const info_item_post = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_INFO_ITEM_POST: {
      return action.payload;
    }

    default:
      return state;

  }
}
