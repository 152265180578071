import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import firebase from 'firebase/app';

import Navegation from '../../components/Global/Navegation';
import ContainerOptions from './ContainerOptions';
import TimeLineClass from "../../classes/TimeLineClass";
import { listPost, scrollInfinitePosts, dataFilterHashtags } from '../../actions/timeline';

let objTimeLine = new TimeLineClass();
class Timeline extends Component {

  constructor (props) {
    super(props);

    this.state = {
      _start : 0,
      _end : 3,
      _n : 3,
      hasMoreItems : true
    }

    this.handleScrollCallback = this.handleScrollCallback.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFilterHashtag = this.handleFilterHashtag.bind(this);
  }

  componentWillMount () {
    if ( this.props.posts_timeline === null ) {
      this.loadMessages();
    }
  }

  async loadMessages () {
    var children     = sessionStorage.children || '';
    var childConcept = sessionStorage.childConcept || "category_service/categoryId";
    var hashtag_filter = sessionStorage.hashtag_filter || "";

    if ( children !== '' ) {
      this.messagesRef = firebase.database().ref('messages').orderByChild(childConcept).equalTo(children).limitToLast(this.state._n); //.limitToLast(this.state._n);
    } else if ( hashtag_filter !== '' ) {
      this.loadMessagesHashtags(hashtag_filter,this.state._n);
    } else {
      this.messagesRef = firebase.database().ref().child('messages').orderByKey().limitToLast(this.state._n);
    }

    if ( hashtag_filter !== '' ) {

   } else {
     let messages = await objTimeLine.returnMessages( this.messagesRef );
     messages.increments = messages.increments.pop();
     this.props.listPost(messages, this.props.info_user);
   }

  }

  async loadPictureCards () {

    var children     = sessionStorage.children || '';
    var childConcept = sessionStorage.childConcept || "category_service/categoryId";
    var hashtag_filter = sessionStorage.hashtag_filter || "";

    var new_n = this.state._n + 3;
    let increments = 0;

    if ( this.props.posts_timeline === null ) {
      increments = 3;
    } else {
      increments = this.props.posts_timeline.increments;
    }

    if ( this.state._n > 0 && this.state._n <= increments ) {

      if ( children !== '' ) {
       this.PictureFirebase = firebase.database().ref().child('messages/').orderByChild(childConcept).equalTo(children).limitToLast(new_n);
     } else if (hashtag_filter !== '') {
       this.loadMessagesHashtags(hashtag_filter, new_n);
     } else {
       this.PictureFirebase = firebase.database().ref().child('messages/').orderByKey().limitToLast(new_n);
     }

      if (hashtag_filter !== '') {

      } else {
        let messages = await objTimeLine.loadMessagesCard( this.PictureFirebase );
        this.props.scrollInfinitePosts(messages);
      }

      this.setState({ _n: new_n });

    } else {
      if ( children !== '' ) {
        this.setState({ _n: 3, hasMoreItems: true });
      } else if ( hashtag_filter !== '' ) {
        this.setState({ _n: 3, hasMoreItems: true });
      } else {
        this.setState({ _n: 0, hasMoreItems: false });
      }
    }

  }

  async loadMessagesHashtags ( hashtag, numElements ) {

    this.hashtagsRef = firebase.database().ref().child("hashtags").orderByChild("text").equalTo(hashtag).limitToLast(numElements);
    this.messageRef  = firebase.database().ref().child("messages").orderByChild("id");
    let messages = await objTimeLine.FilterHashtagLoadMessages( this.hashtagsRef, this.messageRef );
    this.props.dataFilterHashtags( messages );

  }

  handleFilterHashtag ( evt ) {
    let hashtag = evt.target.getAttribute('data-hashtag');
    sessionStorage.removeItem('children');
    sessionStorage.removeItem('childConcept');
    sessionStorage.setItem("hashtag_filter", hashtag);

    this.loadMessagesHashtags( hashtag, 3);
  }

  componentWillUnmount () {
    //this.messagesRef.off();
  }

  handleFilter (children, childConcept) {
    this.setState({ _n: 3 });

    if ( childConcept === 'Not Filter' ) {
      sessionStorage.removeItem('children');
      sessionStorage.removeItem('childConcept');
      sessionStorage.removeItem('hashtag_filter');
    } else if ( childConcept !== 'Not Filter' ) {
      sessionStorage.removeItem('hashtag_filter');
      sessionStorage.setItem('children', children);
      sessionStorage.setItem('childConcept', childConcept);
    }

    setTimeout( () => this.loadMessages(), 500);
  }

  handleScrollCallback () {

    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;

    if(windowBottom >= docHeight ){
      if( this.state.hasMoreItems ) {
        this.loadPictureCards();
      }
    }

  }

  render () {
    return (
      <div className = "main">
        <Navegation
          title = { "Qué estamos haciendo" }
          url   = { "/timeline" }
        />
        <section id = "blog-section">
          <div className = "container">
            <ContainerOptions
              handleScrollCallback = { this.handleScrollCallback }
              handleFilter         = { this.handleFilter }
              handleFilterHashtag  = { this.handleFilterHashtag }
            />
          </div>
        </section>
      </div>
    )
  }

}

function mapStateToProps ( state ) {
  return {
    info_user : state.info_user,
    posts_timeline : state.posts_timeline
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    listPost,
    scrollInfinitePosts,
    dataFilterHashtags
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(Timeline);
