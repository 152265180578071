import { _sweetAlert } from '../services/SweetAlert2';
import errors_show from '../functions/ErrorsMap';

function ErrorRequest ( err, item, typeResponse = null, closeWindow = null ) {

  if ( err.response === 'Forbidden' ) {
    OptionsTypeError(item, typeResponse, err.response, err.message, closeWindow, 'error', 1);
  } else if ( err.response === 'Invalid Token' ) {
    OptionsTypeError( item, typeResponse, err.response, err.message, closeWindow, 'error', 1);
  } else if ( err.response === 'TokenExpiredError' ) {
    OptionsTypeError( item, typeResponse, err.response, err.message, closeWindow, 'error', 1);
  }else if ( err.response === 'Bad Request' ) {
    OptionsTypeError( item, typeResponse, err.response, err.message, closeWindow, 'warning', 1 );
  } else if ( err.response === 'error' ) {
    OptionsTypeError( item, typeResponse, err.response, err.message, closeWindow, 'error', 2 );
  } else if ( err.response === 'without permission' ) {
    if ( typeResponse === 1 ) {
      item.setState({ authorization: false, data_permit: err, preloader: false });
    } else {
      _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Sin Autorización', messageResponse:`NO CUENTAS CON EL PERMISO DE ${err.description}`, typeResponse: 'warning' });
    }
  } else if ( err.response === 'Not Found' ) {
    _sweetAlert({ typeMessage: 'sweet_message', titleResponse: err.response, messageResponse: err.message, typeResponse: 'warning' });
  } else if ( err.response === 'without changes' ) {
    _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Precaución', messageResponse: err.message , typeResponse: 'warning' });
  }

}

/*
*  typeError :
   {
      1 : El error se va a mostrar en el componente
      2 : El error se muestra en un SweetAlert2 y se cierrºa el modal que este abierto
      3 : El mensaje de error se muestra en un SweetAlert2
   }
*/

function OptionsTypeError ( item, typeError, response, message, closeWindow, colorAlert, typeMessageError ) {
  if ( typeError === 1) {
     optionAlertMessage(typeMessageError, response, message, colorAlert);
  } else if ( typeError === 2 ) {
     closeWindow();
     optionAlertMessage(typeMessageError, response, message, colorAlert);
  } else if ( typeError === 3 ) {
     optionAlertMessage(typeMessageError, response, message, colorAlert);
  }

}

/*
*
*  typeMessageError :
*  {
*    1: Solo es el mensaje de error
*    2: Es un array de objetos
*  }
*
*/

function optionAlertMessage ( typeMessageError, response, message, colorAlert ) {

  if ( typeMessageError === 1 ) {
    _sweetAlert({ typeMessage: 'sweet_message', titleResponse: response, messageResponse: message, typeResponse: colorAlert });

  } else if ( typeMessageError === 2 ) {
    _sweetAlert({ typeMessage: 'sweet_message', titleResponse: response, messageResponse: errors_show(message), typeResponse: colorAlert });

  }

}

export default ErrorRequest;
