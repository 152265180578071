import React, { Component } from 'react';
import PropTypes from 'prop-types';

var map,mar,marf, start, end;
const google = window.google;
//varibales para los servicios de rutas de google mpas
var directionsDisplay;
var directionsService = new google.maps.DirectionsService();

const propTypes = {
  latitude  : PropTypes.string.isRequired,
  longitude : PropTypes.string.isRequired
}

class Map extends Component {

  componentDidMount () {
    map = this.cargarMapa(map,this.props.latitude, this.props.longitude);
    //cargamos los marcadores al mapa
    mar = this.cargarMarcador(mar,map,this.props.latitude,this.props.longitude,false,process.env.REACT_APP_CLOUDINARY_MARKER_RED);
    // Asignar ruta del usuario
    this.myLocation();
    this.obtenerRuta();
  }

  cargarMapa(map,lat,lon) {
      var mapOptions = {
          center: new google.maps.LatLng(lat, lon),
          zoom: 17,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          zoomControlOptions: {
              style: google.maps.ZoomControlStyle.DEFAULT,
              position: google.maps.ControlPosition.LEFT_CENTER
          },
          scrollwheel: true,
          panControl:true,
          streetViewControl:true
      };
      map = new google.maps.Map(document.getElementById('map'),mapOptions);
    return map;
  }

  //funcion que centra el mapa
  centrarMapa(map,lat,lon){
      map.panTo(new google.maps.LatLng(lat, lon));
  }

  //funcion para poner un marcador
cargarMarcador(marker,map,lat,lon,dr,im){
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lon),
      draggable:dr,
      icon: im,
    });
    marker.setMap(map);
    this.centrarMapa(map,lat,lon);
    return marker;
}

myLocation () {
  let latitude, longitude;

  if ( typeof(sessionStorage.Latitude) === 'undefined' ) {
    latitude = '18.51572';
  } else {
    latitude = sessionStorage.Latitude
  }

  if ( typeof(sessionStorage.Longitude) === 'undefined' ) {
    longitude = '-88.306416';
  } else {
    longitude = sessionStorage.Longitude;
  }

  marf = this.cargarMarcador(marf,map,latitude,longitude,false,process.env.REACT_APP_CLOUDINARY_MARKER_BLUE);
}

obtenerRuta() {
  directionsDisplay = new google.maps.DirectionsRenderer();
  directionsDisplay.setMap(map);

  //agregamos evento para que al terminar de arrastrar los marcadores se marque la ruta
  start = new google.maps.LatLng(mar.position.lat(),mar.position.lng());
  end = new google.maps.LatLng(marf.position.lat(),marf.position.lng());
    var request = {
      origin:start,
      destination:end,
      travelMode: google.maps.TravelMode.DRIVING
    };
    directionsService.route(request, function(result, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(result);
         google.maps.event.addListener(mar, 'click', function() {
         //window.open("https://www.google.es/maps/place/Protics/@18.5156322,-88.3042788,17z/data=!3m1!4b1!4m2!3m1!1s0x8f5ba49ff8535cb5:0x7570327010d5d7e4!6m1!1e1", '_blank');
   });
      }
    });

        //inicializamos el punto de partida y de llegada con las posicicones de los marcadores
        start = new google.maps.LatLng(mar.position.lat(),mar.position.lng());
        end = new google.maps.LatLng(marf.position.lat(),marf.position.lng());
        //hacemos una peticion al servicio de rutas
        var request2 = {
            origin:start,
            destination:end,
            travelMode: google.maps.TravelMode.DRIVING
          };
          directionsService.route(request2, function(result, status) {
            //si la peticion fue exitosa pintamos la ruta
            if (status === google.maps.DirectionsStatus.OK) {
              directionsDisplay.setDirections(result);
            }
          });

}

  render () {
    return (
      <div id="map" className="google-map small"></div>
    )
  }
}

Map.propTypes = propTypes;

export default Map;
