import React from 'react';

import NotFound from '../../components/Global/NotFound';
import Footer from '../../components/Global/Footer';

const NotFoundContainer = () => {
  return (
    <div>
      <NotFound />
      <Footer />
    </div>
  )
}

export default NotFoundContainer;
