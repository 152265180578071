import React from 'react';
import PropTypes from 'prop-types';

import InfoBoxContent from './InfoBoxContent';
import InfoBoxCarousel from './InfoBoxCarousel';
import { isEmptyJSON } from '../../../functions/ValidationState';
import './css/infobox.css';

const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");

var myOptions = {
  disableAutoPan: false,
  maxWidth: 0,
  pixelOffset: new window.google.maps.Size(-163, -460),
  zIndex: null,
  closeBoxURL: "https://www.google.com/intl/en_us/mapfiles/close.gif",
  infoBoxClearance: new window.google.maps.Size(1, 1),
  isHidden: false,
  pane: "floatPane",
  enableEventPropagation: true
};

const propTypes = {
  id            : PropTypes.string.isRequired,
  name_client   : PropTypes.string.isRequired,
  address       : PropTypes.string.isRequired,
  jobs_pictures : PropTypes.array.isRequired
}

const working_title = jobs_pictures => {
  if ( isEmptyJSON(jobs_pictures) === true ) {
    return (
      <div></div>
    )
  } else {
    return (
      <div className="text-center infoTitleWorking">
        Realizando Proyectos
      </div>
    )
  }
}


const MapInfoBox = ({ id, name_client, address, jobs_pictures }) => {
  return (
    <InfoBox
      options = { myOptions }
    >
      <div className="infoBoxContainer">
        <InfoBoxCarousel
          jobs_pictures = { jobs_pictures }
        />
        { working_title(jobs_pictures) }
        <InfoBoxContent
          id          = { id }
          name_client = { name_client }
          address     = { address }
        />
      </div>
    </InfoBox>
  )
}

MapInfoBox.propTypes = propTypes;

export default MapInfoBox;
