import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { channelYoutubeInfo } from '../../actions/channel';
import Sidebar from './Sidebar';
import VideosContent from './VideosContent';

class TutorialsContent extends Component {

  componentDidMount () {

    if ( this.props.channel_youtube === null ) {
      this.props.channelYoutubeInfo();
    }

  }

  render () {
    return (
      <div className = "row">
        <Sidebar />
        <VideosContent />
      </div>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    channel_youtube   : state.channel_youtube
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    channelYoutubeInfo
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(TutorialsContent);
