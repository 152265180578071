import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  buttonId          : PropTypes.string.isRequired,
  onResponseComment : PropTypes.func.isRequired
}

const InputResponse = () => {
  return (
    <div className="input-group">
      <input className="form-control" name="comment" id={ this.props.buttonId } placeholder="Agregar Comentario" type="text" />
      <span className="input-group-addon">
        <button onClick={ this.props.onResponseComment }><i className="fa fa-edit"></i> Responder </button>
      </span>
    </div>
  )
}

InputResponse.propTypes = propTypes;

export default InputResponse;
