import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import { separateElements } from '../../functions/Functions';

const propTypes = {
   list_points    : PropTypes.array.isRequired,
   chunk          : PropTypes.number.isRequired,
   name_attribute : PropTypes.string.isRequired,
   class_div      : PropTypes.string.isRequired
}

class ListPoints extends Component {
   render () {

      let arrayComponent = [];

      let data = separateElements( this.props.list_points, this.props.chunk );

      for ( let i = 0; i < data.length; i++ ) {
         arrayComponent.push(
            <div className = { this.props.class_div } key = { uuid.v4() }>
               {
                  data[i].map( d => {

                     let point_name = "";

                     if ( this.props.name_attribute === 'requirements' ) {
                        point_name = d.description_point;
                     } else if ( this.props.name_attribute === 'carees' ) {
                        point_name = d.career_point;
                     } else if ( this.props.name_attribute === 'technical_carees' ) {
                        point_name = d.technical_point;
                     } else if ( this.props.name_attribute === 'offers' ) {
                        point_name = d.offer_point;
                     }

                     return (
                        <div className = "feature-box" key = { uuid.v4() }>
                           <div className = "feature-box-icon">
                              <i className = "fa fa-check"></i>
                           </div>
                           <div className = "feature-box-info">
                              <p className = "tall">{ point_name }</p>
                           </div>
                        </div>
                     )
                  })
               }
            </div>
         )
      }

      return ( arrayComponent );
   }
}

ListPoints.propTypes = propTypes;

export default ListPoints;