import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';

import ItemPost from './ItemPost';
import { isEmptyJSON } from '../../functions/ValidationState';
import { Scrollbars } from 'react-custom-scrollbars';

const propTypes = {
  keyChildren        : PropTypes.string.isRequired,
  handleUpdatePostId : PropTypes.func.isRequired
}

class RecentPosts extends Component {

  constructor (props) {
    super(props);

    this.state = {
      new_messages : []
    }
  }

  componentWillMount () {
    this.loadNewMessages(this.props.keyChildren);
  }

  componentWillReceiveProps ( nextProps ) {
    if (nextProps.keyChildren !== this.props.keyChildren) {
      this.loadNewMessages(nextProps.keyChildren);
    }
  }

  loadNewMessages ( keyChildren ) {
    this.messagesRef = firebase.database().ref().child('messages');
    let new_messages = [];
    this.messagesRef.on('child_added', snapshot => {
      if (snapshot.key !== keyChildren) {
        let newNessages = {
          keyChildren : snapshot.key,
          description : snapshot.val().description,
          url : snapshot.val().url,
          date : snapshot.val().date,
          id : snapshot.val().id
        }
        new_messages.push(newNessages);
      }

      this.setState({
        new_messages
      });
    });
  }

 renderNewMessages () {
   let { new_messages } = this.state;

   if ( isEmptyJSON(new_messages) === true ) {
     return(
       <Scrollbars style={{ height: 665 }}> {
             new_messages.map( (nm) => {
             return (
               <ItemPost
                 key                = { nm.keyChildren }
                 keyChildren        = { nm.keyChildren }
                 url                = { nm.url[0] }
                 description        = { nm.description }
                 date               = { nm.date }
                 handleUpdatePostId = { this.props.handleUpdatePostId }
               />
             )
           }).reverse()
         }
       </Scrollbars>
     )
   } else if ( isEmptyJSON(new_messages) === false ) {
     return (
       <li>Cargando ...</li>
     )
   }
  }

  render () {
    return (
      <div className="tabs">
        <ul className="nav nav-tabs">
          <li>
            <a href = "#post" data-toggle="tab">
              <i className="fa fa-star"> Publicaciones Anteriores </i>
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane active">
            <ul className="simple-post-list">
              { this.renderNewMessages() }
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

RecentPosts.propTypes = propTypes;

export default RecentPosts;
