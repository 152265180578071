import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import { isEmptyJSON } from '../../functions/ValidationState';
import { filterPagination } from '../../actions/posts_notifications';

class PaginationPost extends Component {

  constructor (props) {
    super(props);

    let _total = 0;

    if ( isEmptyJSON(this.props.posts_notifications) === true ) {
      if ( typeof(this.props.posts_notifications) !== 'undefined' && this.props.posts_notifications !== null ) {
        _total = this.props.posts_notifications.posts_data.length;
      } else {
        _total = 0;
      }
    }

    this.state = {
      _start   : 0,
      _final   : 6,
      pageSize : 6,
      _total   : _total
    }

    this.handlePagination = this.handlePagination.bind(this);

  }

  componentWillReceiveProps ( nextProps ) {
    if ( isEmptyJSON(nextProps.posts_notifications) === true ) {
      if ( nextProps.posts_notifications.posts_data !== this.props.posts_notifications.posts_data ) {
        this.setState({ _total: nextProps.posts_notifications.posts_data.length });
      }
    }
  }

  handlePagination ( current, pageSize ) {

    let _final = 0;
    let _start = 0;

    if ( current === 1 ) {
      _start = 0;
      _final = 6;
      this.setState({
        _start,
        _final
      })
    } else {
       _final = ( current * pageSize );
       _start = ( _final - 6 );

      this.setState({
        _start,
        _final
      });
    }
    let obj = [];

    if ( isEmptyJSON(this.props.posts_notifications.posts_data) === true ) {
      obj = this.props.posts_notifications.posts_data;
      obj = obj.slice( _start, _final );
    }

    this.props.filterPagination(obj);

  }

  render () {
    return (
      <div className = "pagination pagination-lg pull-right">
        <div className = "shop_page_nav d-flex flex-row">
          <Pagination
            defaultPageSize = { this.state.pageSize }
            showTotal       = { ( total, range) =>  `${range[0]} - ${range[1]} de ${total} elementos `}
            total           = { this.state._total }
            onChange        = { this.handlePagination }
          />
        </div>
      </div>
    )
  }
}


function mapStateToProps ( state ) {
  return {
    posts_notifications: state.posts_notifications
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    filterPagination
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(PaginationPost);
