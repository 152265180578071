import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  tamDiv          : PropTypes.string,
  thumbnails      : PropTypes.string.isRequired,
  url             : PropTypes.string.isRequired,
  videoId         : PropTypes.string.isRequired,
  title           : PropTypes.string.isRequired,
  description     : PropTypes.string.isRequired,
  handleViewVideo : PropTypes.func.isRequired
}

const MiniatureVideo = ({ tamDiv, thumbnails, url, videoId, title, description, handleViewVideo }) =>  {
  return (
    <img
      className     = "img-responsive"
      height        = "130px"
      src           = { thumbnails }
      data-url      = { url }
      data-video_id = { videoId }
      data-descrip  = { description }
      alt           = { title }
      onClick       = { handleViewVideo }
    />
  )
}

MiniatureVideo.propTypes = propTypes;

export default MiniatureVideo;
