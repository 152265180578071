import React from 'react';
import PropTypes from 'prop-types';

import PostImage from './PostImage';
import PostDate from './PostDate';
import PostContent from './PostContent';

const propTypes = {
  info_item_post : PropTypes.object.isRequired
}

const ContainerItem = ({ info_item_post }) => {
  return (
    <article className = "post post-large blog-single-post">
      <PostImage />
      <PostDate post_date = { info_item_post.createdAt } />
      <PostContent
        post_guid    = { info_item_post.post_guid }
        post_title   = { info_item_post.post_title }
        post_content = { info_item_post.post_content }
      />
    </article>
  )
}

ContainerItem.propTypes = propTypes;

export default ContainerItem;
