import React from 'react';
import PropTypes from 'prop-types';

import Newsletter from './Newsletter';
import ContactCompany from './ContactCompany';
import SocialNetworks from '../Header/SocialNetworks';
import Copyright from './Copyright';
// import Tweets from './../Tweets';

const propTypes = {
  website_info : PropTypes.array.isRequired
}

const FooterContainer = ({ website_info }) => {
  return (
    <div id = "footer">
      <div className="container">
        <div className = "row">

          <div className = "footer-ribbon">
            <span> Estar en contacto</span>
          </div>

          <div className = "col-md-3">
            <Newsletter
              title_newsletter = { website_info[32].info_value }
              info_newsletter  = { website_info[33].info_value }
            />
          </div>

          <div className = "col-md-4">
            {/* <h4> Últimos Tweets </h4>
            <Tweets /> */}
          </div>

          <div className = "col-md-3">
            <h4> Contáctanos </h4>
            <ContactCompany />
          </div>

          <div className = "col-md-2">
            <h4>Síguenos</h4>
            <div className = "social-icons">
              <SocialNetworks />
            </div>
          </div>

        </div>
      </div>
      <Copyright />
    </div>
  )
}

FooterContainer.propTypes = propTypes;

export default FooterContainer;
