import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';

import MapInfoBox from './MapInfoBox';
import { isOnline } from '../../../functions/NavigatorOnline';

const { Marker }  = require("react-google-maps");

const propTypes = {
  id          : PropTypes.string.isRequired,
  url_photo   : PropTypes.string.isRequired,
  latitude    : PropTypes.string.isRequired,
  longitude   : PropTypes.string.isRequired,
  name_client : PropTypes.string.isRequired,
  address     : PropTypes.string.isRequired,
  phone       : PropTypes.string.isRequired
}

class MarkerMap extends Component {

  constructor () {
    super();

    this.state = {
      isOpen: false,
      jobs_pictures: []
    }

    this.onToggleOpen = this.onToggleOpen.bind(this);
  }

  onToggleOpen () {
    this.setState({
      isOpen: !this.state.isOpen
    });
    this.getImagesProjects();
  }

  getImagesProjects () {
    let { id } = this.props;
    this.messagesRef = firebase.database().ref('/messages').orderByChild('place/key').equalTo(id);
    let array_images = [];

    this.messagesRef.on('child_added', snapshot => {
      let urls = snapshot.val().url;

      for ( var i in urls) {
        if ( i === '0') {
          array_images.push({ 'url':urls[i] });
        }
      }
      this.setState({ jobs_pictures: array_images });
    });
  }

  render () {
    return (
      <Marker
        position = {{ lat: parseFloat(this.props.latitude,10), lng: parseFloat(this.props.longitude,10) }}
        icon     = { process.env.REACT_APP_MARKER_CUSTOMER_MAP }
        title    = { this.props.name_client }
        onClick  = { this.onToggleOpen }
      >
      {
        this.state.isOpen && isOnline() &&
        <MapInfoBox
          id            = { this.props.id }
          name_client   = { this.props.name_client }
          address       = { this.props.address }
          jobs_pictures = { this.state.jobs_pictures }
        />
      }
      </Marker>
    )
  }
}

MarkerMap.propTypes = propTypes;

export default MarkerMap;
