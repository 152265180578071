//const prevArrow = <img src='https://www.creativehunt.co/wp-content/uploads/2016/02/Previous-300x300.png' alt = "prev" />;
//const nextArrow = <img src='https://www.creativehunt.co/wp-content/uploads/2016/02/Next-300x300.png' alt = "next" />;

export const settings = {
  autoplay: true,
  rtl: false,
  dots: false,
  infinite: true,
  arrow: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1
};
