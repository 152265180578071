import React from 'react';
import PropTypes from 'prop-types';

import Login from '../../components/Global/Login';
import Footer from '../../components/Global/Footer';

const propTypes = {
  section_option : PropTypes.string
}

const LoginContainer = ({ section_option }) => {
  return (
    <div>
      <Login section_option = { section_option } />
      <Footer />
    </div>
  )
}

LoginContainer.propTypes = propTypes;

export default LoginContainer;
