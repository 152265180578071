import React from 'react';
import PropTypes from 'prop-types';

import { getDayDate, getMonthDate } from '../../../functions/DateFormat';

const propTypes = {
  post_date : PropTypes.string.isRequired
}

const PostDate = ({ post_date }) => {
  return (
    <div className = "post-date">
      <span className = "day">{ getDayDate(post_date) }</span>
      <span className = "month">{ getMonthDate(post_date) }</span>
    </div>
  )
}

PostDate.propTypes = propTypes;

export default PostDate;
