import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../../components/Global/Footer';
import ItemPostNotification from '../../components/PostsNotifications/ItemPostNotification';

const propTypes = {
  post_guid : PropTypes.string.isRequired,
  post_name : PropTypes.string.isRequired
}

const ItemPostNotificationContainer = ({ post_guid, post_name }) => {
  return (
    <div>
      <ItemPostNotification
        post_guid = { post_guid }
        post_name = { post_name }
      />
      <Footer />
    </div>
  )
}

ItemPostNotificationContainer.propTypes = propTypes;

export default ItemPostNotificationContainer;
