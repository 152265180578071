import React from 'react';

import Profile from '../../components/Global/Profile';
import Footer from '../../components/Global/Footer';

const ProfileContainer = () => {
  return (
    <div>
      <Profile />
      <Footer />
    </div>
  )
}

export default ProfileContainer;
