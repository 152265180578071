import {
  GET_USER
} from '../constants';

export const info_user = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_USER: {
      return action.payload;
    }

    default:
      return state;

  }
}
