import React from 'react';

import ItemMenuNav from './ItemMenuNav';

const MenuNav = () => (
  <nav>
    <ul className = "nav nav-pills nav-top">

      <ItemMenuNav
        route_path    = { '/about_us' }
        icon          = { 'fa-angle-right' }
        title         = { 'Acerca de nosotros' }
        classEspecial = { '' }
      />

      <ItemMenuNav
        route_path = { '/contact_us' }
        icon       = { 'fa-angle-right' }
        title      = { 'Contáctanos' }
      />

      <li className="phone">
        <a href={ process.env.REACT_APP_API_WHATSAPP } target="_blank" rel="noopener noreferrer" style={{ background: '#00F478', color: '#fff' }} ><i className="fa fa-whatsapp fa-2x"></i> Enviar Mensaje </a>
      </li>

    </ul>
  </nav>
)

export default MenuNav;
