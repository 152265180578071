import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { NewString } from '../../../functions/ReplaceCharacter';

const propTypes = {
  solutions : PropTypes.array
}

const renderSolutions = (solutions) => {
  return (
    solutions.map( sol => {
      let route_path = `/system/${sol.id}/${NewString(sol.solution, 1)}`;
      return (
        <div className="col-md-4" key={ sol.Sys_GUID }>
          <div className="panel-default">
            <div className="panel-heading text-center">
              <Link to={ route_path }>
                <img src={ sol.url_picture } alt={ sol.solution } className="img-responsive" />
              </Link>
            </div>
          </div>
        </div>
      )
    })
  )
}

const Solution = ({ solutions }) => {
  return (
    <div>
      { renderSolutions(solutions) }
    </div>
  )
}

Solution.propTypes = propTypes;

export default Solution;
