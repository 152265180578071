import React from 'react';
import PropTypes from 'prop-types';

import ListPosts from './ListPosts';
import PaginationPost from './PaginationPost';

const propTypes = {
  posts_notifications : PropTypes.array.isRequired
}

const ContainerPosts = ({ posts_notifications }) => {
  return (
    <div className = "container">
      <div className = "row">
        <div className = "col-md-9">
          <div className = "blog-posts">
            <ListPosts posts_notifications = { posts_notifications } />
            <PaginationPost />
          </div>
        </div>
      </div>
    </div>
  )
}

ContainerPosts.propTypes = propTypes;

export default ContainerPosts;
