import {
  GET_LIST_TWEETS,
  GET_LIST_HASHTAGS
} from '../constants';

export const tweets = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_LIST_TWEETS: {
      return action.payload;
    }

    case GET_LIST_HASHTAGS : {
      return action.payload;
    }

    default:
      return state;

  }
}
