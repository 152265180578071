import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id             : PropTypes.string.isRequired,
  url            : PropTypes.string.isRequired,
  title          :  PropTypes.string.isRequired,
  changePlayList : PropTypes.func.isRequired
}

const ItemPlayList = ({ id, url, title, changePlayList }) => {
  return (
    <li onClick = { changePlayList } data-id = { id } >
      <a href="#item" data-id = { id } >
        <img src = { url } className = "spaceTopElement" data-id = { id } width = "80" height = "60" alt = "img" />
        <span className = "spaceElements" data-id = { id }> { title }</span>
      </a>
    </li>
  )
}

ItemPlayList.propTypes = propTypes;

export default ItemPlayList;
