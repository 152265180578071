import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';

import Navegation from '../../Global/Navegation';
import FirebaseClass from "../../../classes/FirebaseClass";
import { valid_input } from '../../../functions/validationsParams';
import { isOnline, MessageConnection } from '../../../functions/NavigatorOnline';

let objFirebase = new FirebaseClass("Anónimo");

class FormSignUp extends Component {

  constructor () {
    super();

    this.state = {
      imgProfileUpload: 0
    }

    this.handleCreateUser = this.handleCreateUser.bind(this);
    this.handleUploadImg  = this.handleUploadImg.bind(this);
  }


  handleUploadImg ( evt ) {

    if ( isOnline() === true ) {
      const file = evt.target.files[0];

      const storageRef = firebase.storage().ref(`/fotos_perfil/${file.name}`);
      const task = storageRef.put(file);

      task.on('state_changed', snapshot => {
        let percentage = ( snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          imgProfileUpload: percentage
        });
        task.snapshot.ref.getDownloadURL().then( downloadURL => {
          localStorage.setItem("photoURLRegister", downloadURL);
        });
     },
     error => { console.log(error.message)
     }, () => {
       this.setState({ imgProfileUpload: 100 });
     });
    } else if ( isOnline() === false ) {
      MessageConnection();
    }

  }

 async handleCreateUser ( evt ) {
    evt.preventDefault();

    if ( isOnline() === true ) {

      let displayName = document.getElementById('displayName').value;
      let email       = document.getElementById('email').value;
      let pass        = document.getElementById('pass').value;

      if ( !displayName ) {
        valid_input( displayName, 'Nombre de usuario' );
      } else {
        if ( !email ) {
          valid_input( email, 'Correo Electrónico' );
        } else {
          if ( !pass ) {
            valid_input( pass, 'contraseña' );
          } else {
            return await objFirebase.saveUser( email, pass, displayName );
          }
        }
      }

    } else if ( isOnline() === false ) {
      MessageConnection();
    }

  }

  render () {
    return (
      <div>
        <Navegation
          title = { 'Registrarse' }
          url   = { '/signup' }
        />
        <div className = "container">
          <span className = "mega-menu-sub-title"> Crear una cuenta </span>
          <form method = "POST" onSubmit = { this.handleCreateUser }>

            <div className = "row">

              <div className = "col-md-4">
                <label> Usuario</label>
                <input type = "text" className = "form-control" name = "displayName" id = "displayName" />
              </div>

              <div className = "col-md-4">
                <label> Correo Electrónico </label>
                <input type = "text" className = "form-control input-lg" name = "email" id = "email" />
              </div>

              <div className = "col-md-4">
                <label> Contraseña </label>
                <input type = "password" className = "form-control input-lg" name = "pass" id = "pass" />
              </div>

              <div className = "col-md-4">
                <label> Imágen de Perfil </label>
                <input type="file" className="form-control input-lg" accept="image/png, .jpeg, .jpg, image/gif" name="photoURL" id="photoURL" onChange={ this.handleUploadImg } />
                <progress value={ this.state.imgProfileUpload } max="100"></progress>
              </div>

              <div className = "col-md-12">
                <button type = "submit" className = "btn btn-primary"> Crear Cuenta</button>
              </div>

              <div className = "col-md-12">
                <span className="colorText">Al registrarte, aceptas la <Link to="/privacyPolicy"><span className="colorHighlight">Política de privacidad</span></Link></span>
              </div>

            </div>

          </form>
        </div>
      </div>
    )
  }

}

export default FormSignUp;
