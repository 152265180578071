import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './../reducers';

import { initStateProfiles } from '../dateState/profiles';

const initialState = {
  slide_data             : null,
  website_info           : null,
  customers              : null,
  info_contact_company   : null,
  tweets                 : null,
  team_members           : null,
  channel_youtube        : null,
  playLists_youtube      : null,
  videos_youtube         : null,
  facebook_ratings       : null,
  info_user              : null,
  system_solutions       : null,
  details_solution       : null,
  customers_map          : null,
  posts_timeline         : null,
  filters_posts          : null,
  customer_info_location : null,
  posts_notifications    : null,
  info_item_post         : null,
  profiles               : initStateProfiles()
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore( reducers, initialState, composeEnhancers(applyMiddleware(thunk)));
