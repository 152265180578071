import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { isEmptyJSON } from '../../functions/ValidationState';

const propTypes = {
  partner         : PropTypes.array,
  avatar          : PropTypes.string.isRequired,
  username        : PropTypes.string.isRequired,
  p_username      : PropTypes.string.isRequired,
  place           : PropTypes.string.isRequired,
  category        : PropTypes.string.isRequired,
  subcategory     : PropTypes.string.isRequired,
  datePublication : PropTypes.string.isRequired,
  link_post       : PropTypes.string.isRequired,
  service         : PropTypes.string,
  handleOpenModal : PropTypes.func.isRequired
}

const partnerData = (partner) => {
  return (
    partner.map( (par, index) => {
      if ( index > 0 ) {
        return (
          <div key={ index }>{ par }<br/></div>
        )
      }
      return true;
    })
  )
}

const renderPartner = (partner) => {
  let partnerEmpty = isEmptyJSON(partner);

  if ( partnerEmpty === true ) {

    var tam = partner.length;
    var tamReal = tam-1;
    var letters = "";

    if (tam > 2) {
      letters = "personas";
    } else {
      letters = "persona";
    }

    if ( tam >= 2 && tam <= 7 ) {
        return (
          partner.map( (par, index) => {
            if (index === 0) {
              return (
                <span key={ index }> <span className="colorPartner">con</span> <a href = "#apartner" className="aPartner">{ par } </a> <span className="colorPartner">y</span> </span>
              )
            } else if (index === 1 ) {
              return (
                  <span key={ index } className="caja">
                   { tamReal } { letters } más
                   <span className="info">
                      { partnerData(partner) }
                   </span>
                  </span>
              )
            }
            return true;
          })
        )
    } else if (tam > 0 && tam < 2) {
      return (
        <a href = "#partner" className="aPartner"> <span className="colorPartner">con</span> { partner[0] }</a>
      )
    }
  } else if ( partnerEmpty === false) {
    return (<div></div>)
  }

}

const renderService = (service) => {
  if ( service !== '' && service !== null ) {
    return (
      <span><i className="colorIconCategory fa fa-caret-right"></i> { service }</span>
    )
  } else {
    return(null);
  }
}

const PictureCardHeader = ({ partner, avatar, username, p_username, place, category, subcategory, datePublication, link_post, service, handleOpenModal }) => {
  return (
    <div className = "panel-heading">
      <img className = "img-circle pull-left" src = { avatar } width="30px;" height="30px;" alt = { username } />
      <h3>
        { p_username }
        <span className = "colorPartner"> está en </span>
        <i className = "fa fa-map-marker"></i>
        <span onClick = { handleOpenModal }> { place }</span>
        { renderPartner(partner) }
      </h3>
      <h3 className = "aPartner">
        { category } <i className = "colorIconCategory fa fa-caret-right"></i> { subcategory } { renderService(service) }
      </h3>
      <h5>
        <i className = "fa fa-clock-o fa-2x"></i>
        <span> { datePublication }</span>
        <Link to = { link_post }>
          <i className = "fa fa-link pull-right"></i>
        </Link>
      </h5>
    </div>
  )
}

PictureCardHeader.propTypes = propTypes;

export default PictureCardHeader;
