import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  post_key  : PropTypes.string.isRequired,
  post_guid : PropTypes.string.isRequired,
  post_name : PropTypes.string.isRequired
}

const PostMeta = ({ post_key, post_guid, post_name }) => {
  let url_item_post = `${post_guid}/${post_key}/${post_name}`;
  return (
    <div className = "post-meta">
      <span></span>
      <span></span>
      <span></span>
      <Link to = { url_item_post } className = "btn btn-xs btn-primary pull-right">Leer más ...</Link>
    </div>
  )
}

PostMeta.propTypes = propTypes;

export default PostMeta;
