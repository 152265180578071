import { combineReducers } from 'redux';

import { slide_data } from './slideReducer';
import { website_info }  from './websiteInfoReducer';
import { customers } from './customersReducer';
import { info_contact_company } from './infoContactCompanyReducer';
import { tweets } from './tweetsReducer';
import { team_members } from './membersReducer';
import { channel_youtube } from './channelReducer';
import { playLists_youtube } from './playListsReducer';
import { videos_youtube } from './videosYoutubeReducer';
import { facebook_ratings } from './facebookRatingsReducer';
import { info_user } from './infoUserReducer';
import { system_solutions } from './systemSolutionsReducer';
import { details_solution } from './detailsSolutionReducer';
import { customers_map } from './customersMapReducer';
import { posts_timeline } from './postsReducer';
import { filters_posts } from './filterSubcategoriesReducer';
import { customer_info_location } from './customerInfoReducer';
import { posts_notifications } from './postsNotificationsReducer';
import { info_item_post } from './infoItemPostReducer';
import { profiles } from './profilesJobsReducer';

export default combineReducers({
  slide_data,
  website_info,
  customers,
  info_contact_company,
  tweets,
  team_members,
  channel_youtube,
  playLists_youtube,
  videos_youtube,
  facebook_ratings,
  info_user,
  system_solutions,
  details_solution,
  customers_map,
  posts_timeline,
  filters_posts,
  customer_info_location,
  posts_notifications,
  info_item_post,
  profiles
});
