import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slider from 'react-slick';
import uuid from 'uuid';

import { settings } from '../../../constants/settings_slick';
import { getItemsSlide } from '../../../actions/slide';
import { isEmptyJSON } from '../../../functions/ValidationState';

import 'slick-carousel/slick/slick-theme.css';
import './slide.css';

class Slide extends Component {

   componentWillMount () {      
      if ( !isEmptyJSON(this.props.slide_data)  ) {
         this.props.getItemsSlide();
      }
   }

   renderButton ( titleButton, url ) {

      if ( titleButton !== "" ) {
         return (
            <a href = { url } target = "_blank" rel="noopener noreferrer" >
               <button className = "btn btn-default backColor text-uppercase">
                  { titleButton } <span className = "fa fa-chevron-right"></span>
               </button>
            </a>
         )
      } else {
         return ( null );
      }
   
   }

   renderStylesElement ( item ) {

      let top_, right_, width_, left_;
   
      if ( item.top !== '' ) {
         top_ = { "top": ` ${ item.top }%` };
      }
      
      if ( item.right !== '' ) {
         right_ = { "right": ` ${ item.right }% ` };
      }
   
      if ( item.width !== '' ) {
         width_ = { "width": ` ${ item.width }%` };
      }
   
      if ( item.left !== '' ) {
         left_ = { "left": ` ${ item.left }%` };
      }
   
      return Object.assign( top_, right_, width_, left_ );
   
   }

   render () {

      if ( isEmptyJSON(this.props.slide_data) ) {
         return (
            <Slider
            { ...settings }
            >  
               {
                  this.props.slide_data.map(( item, index ) => {

                     let styleImgProduct = JSON.parse( item.styleImgProduct );
                     let styleTextSlide  = JSON.parse( item.styleTextSlide );
                     let title1          = JSON.parse( item.title1 );
                     let title2          = JSON.parse( item.title2 );
                     let title3          = JSON.parse( item.title3 );

                     return (
                        <div className = "slide" key = { uuid.v4() }>
                           <img className = "cambiarFondo" src = { item.imgBackground } alt = "background_image" />
                           <div className = { `slideOpciones ${ item.kindSlide }` }>
                           <img
                              className = "imgProducto"
                              src   = { item.imgProduct }
                              alt   = { item.name_slide }
                              style = { this.renderStylesElement(styleImgProduct) }
                           />
                           <div className = "textosSlide"
                              style = { this.renderStylesElement( styleTextSlide ) }
                           >
                              <h1 style = {{ "color": title1.color }}> { title1.texto } </h1>
                              <h2 style = {{ "color": title2.color }}> { title2.texto } </h2>
                              <h3 style = {{ "color": title3.color }}> { title3.texto }</h3>

                              { this.renderButton( item.titleButton, item.url ) }
                           </div>
                           </div>
                        </div>
                     )
                  })
               }
            </Slider>
         )
      } else {
         return ( null );
      }
   }
}

function mapStateToProps ( state ) {
   return {
      slide_data: state.slide_data
   };
 }
 
 function mapDispatchToProps ( dispatch ) {
   return bindActionCreators({
      getItemsSlide
   }, dispatch)
 }

 export default connect( mapStateToProps, mapDispatchToProps)(Slide);
