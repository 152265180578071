import React from 'react';
import PropTypes from 'prop-types';

import { isOnline } from '../../functions/NavigatorOnline';
import './css/facebook.css';

const propTypes = {
  title_suscribe_facebook       : PropTypes.string.isRequired,
  description_suscribe_facebook : PropTypes.string.isRequired
}

const renderOnline = () => {

  if ( isOnline() === true ) {
    return (
      <iframe title="Protics" src={ process.env.REACT_APP_IFRAME_FACEBOOK } width="326" height="94" scrolling="no" className="followFacebook" frameBorder="0" ></iframe>
    )
  } else if ( isOnline() === false ) {
    return (
      <div>
        No hay Conexión a Internet
      </div>
    )
  }

}

const Facebook = ({ title_suscribe_facebook, description_suscribe_facebook }) => {
  return (
    <div>
      <div className = "backgroundFacebook">
        <div className = "container ptxxxl pbxxxl">
          <div className = "row">

            <div className = "col-md-6 text-center">
              <h2 className="titleFacebook"> { title_suscribe_facebook }</h2>
              <p className="contentFacebook"> { description_suscribe_facebook }</p>
              { renderOnline() }
            </div>

            <div className = "col-md-6 text-center">
              <a href = { process.env.REACT_APP_URL_FACEBOOK } target="_blank" rel="noopener noreferrer" aria-label = "Página de Facebook" >
                <i className="fa fa-facebook banner-font-image"></i>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

Facebook.propTypes = propTypes;

export default Facebook;
