import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import Lightboxx from 'react-image-lightbox';

const propTypes = {
  photos : PropTypes.arrayOf(PropTypes.object).isRequired,
  photosMore: PropTypes.array.isRequired
}

class PlusImages extends Component {

  constructor (props) {
    super(props);

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      isOpen: false,
      photoIndex: 0
    }

    this.openLightbox     = this.openLightbox.bind(this);
    this.closeLightbox    = this.closeLightbox.bind(this);
    this.gotoPrevious     = this.gotoPrevious.bind(this);
    this.gotoNext         = this.gotoNext.bind(this);
    this.handleMoreImages = this.handleMoreImages.bind(this);
  }

  openLightbox (event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox () {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious () {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext () {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  handleMoreImages (event) {
    this.setState({ isOpen: true });
  }

  render () {
    return (
      <div>
        <Gallery  photos = { this.props.photos } onClick={ this.openLightbox } />
        <Lightbox images = { this.props.photos }
          onClose        = { this.closeLightbox }
          onClickPrev    = { this.gotoPrevious }
          onClickNext    = { this.gotoNext }
          currentImage   = { this.state.currentImage }
          isOpen         = { this.state.lightboxIsOpen }
        />
        <div>
          <span className="fab sizeLetter" onClick={ this.handleMoreImages }>{ this.props.moreImages }+</span>
          { this.state.isOpen &&
              <div>
                 <Lightboxx
                     enableZoom = { false }
                     mainSrc={this.props.photosMore[this.state.photoIndex]}
                     nextSrc={this.props.photosMore[(this.state.photoIndex + 1) % this.props.photosMore.length]}
                     prevSrc={this.props.photosMore[(this.state.photoIndex + this.props.photosMore.length - 1) % this.props.photosMore.length]}

                     onCloseRequest={() => this.setState({ isOpen: false })}
                     onMovePrevRequest={() => this.setState({
                         photoIndex: (this.state.photoIndex + this.props.photosMore.length - 1) % this.props.photosMore.length,
                     })}
                     onMoveNextRequest={() => this.setState({
                         photoIndex: (this.state.photoIndex + 1) % this.props.photosMore.length,
                     })}
                 />
               </div>
             }
        </div>
      </div>
    )
  }
}

PlusImages.propTypes = propTypes;

export default PlusImages;
