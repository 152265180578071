import React from 'react';
import PropTypes from 'prop-types';
import ScrollEvent from 'react-onscroll';

import PictureList from './PictureList';
import TreeData from './TreeData';
import SharedUbication from '../Global/SharedUbication';

const propTypes = {
  handleScrollCallback : PropTypes.func.isRequired,
  handleFilter         : PropTypes.func.isRequired,
  handleFilterHashtag  : PropTypes.func.isRequired
}

const ContainerOptions = ({ handleScrollCallback, handleFilter, handleFilterHashtag }) => {
  return (
    <div className = "row">
      <div className = "col-lg-2">
        <SharedUbication />
      </div>
      <div className = "col-lg-6">
        <ScrollEvent handleScrollCallback = { handleScrollCallback } />
        <PictureList handleFilterHashtag = { handleFilterHashtag }  />
      </div>
      <div className = "col-lg-4">
        <TreeData handleFilter = { handleFilter } />
      </div>
    </div>
  )
}

ContainerOptions.propTypes = propTypes;

export default ContainerOptions;
