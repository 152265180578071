import React from 'react';

const ContentImage = () => {
  return (
    <div className = "col-md-7 text-center" >
      <img className=" img-responsive" src = { process.env.REACT_APP_IMAGE_COLABORATION } alt = "team_protics" />
    </div>
  )
}

export default ContentImage;
