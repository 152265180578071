import {
  GET_WEBSITE_INFO
} from '../constants';

import { actions_requests } from '../requests';
import ErrorRequest from '../requests/ErrorRequest';

const getWebsiteInfo = payload => { return { type: GET_WEBSITE_INFO, payload }};

export const websiteInfo = () => {

  return ( dispatch, getState ) => {
    let url_request = process.env.REACT_APP_API_WEBSITEINFO_HOME_CHOOSE;

    actions_requests(null, url_request, 'GET')
      .then( respo => {

        if ( respo.response === 'success' ) {
          dispatch(getWebsiteInfo(respo.data));
        }

      })
      .catch( err => {
        ErrorRequest(err, null, 1);
      });
  }

}
