import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import ItemJob from './ItemJob';

const propTypes = {
   profiles : PropTypes.array.isRequired
}

const renderList = profiles => {
   return (
      profiles.map( p => {
         return (
            <ItemJob
               key                 = { uuid.v4() }
               id_profile          = { p.id }
               uuid_profile        = { p.Sys_GUID }
               name_profile        = { p.name_profile }
               location            = { p.location }
               status_point        = { p.status_civile.status_point }
               description_profile = { p.description_profile }
            />
         )
      })
   )
}

const ListJobs = ({ profiles }) => {

   return (
      <div className = "toogle" data-plugin-toggle id = "div_profiles">
         { renderList(profiles) }
      </div>
   )
   
}

ListJobs.propTypes = propTypes;

export default ListJobs;