function removeImg ( text ) {
  let content = text.replace(/<img[^>]*>/g,"");
  return content;
}

export function cutText ( text, numberFinal ) {

  let textFinal = removeImg( text.substr(0, numberFinal ));
  return `${textFinal} . [...]`;
}
