import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  url_photo   : PropTypes.string.isRequired,
  name_client : PropTypes.string.isRequired
}

const CustomerLogo = ({ url_photo, name_client }) => {
  return (
    <div className = "row">
      <div className = "col-md-10">
        <center>
          <div className = "backgroundLogo">
            <img className = "img-responsive img-rounded" src = { url_photo } alt = { name_client } />
          </div>
        </center>
      </div>
    </div>
  )
}

CustomerLogo.propTypes = propTypes;

export default CustomerLogo;
