import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  url     : PropTypes.string.isRequired,
  alt_    : PropTypes.string.isRequired,
  title : PropTypes.string.isRequired
}

const IconRepresentation = ({ url, alt_, title }) => {
  return (
    <div className="divContentIcons">
      <div className="text-center">
        <img src={ url } className="img-responsive" alt={ alt_ } />
        <span className="infoTitleIcon">{ title }</span>
      </div>
    </div>
  )
}

IconRepresentation.propTypes = propTypes;

export default IconRepresentation;
