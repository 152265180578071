export const handleMenuCollapse = () => {

  const el = document.querySelector('#divMenu');

  if ( el.classList.contains('in') ) {
    el.classList.remove('in');
  } else {
    el.classList.add('in');
  }

}
