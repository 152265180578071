import {
  GET_INFO_CONTACT_COMPANY
} from '../constants';

export const info_contact_company = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_INFO_CONTACT_COMPANY: {
      return action.payload;
    }

    default:
      return state;

  }
}
