import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { websiteInfo } from '../../../actions/website_info';
import FooterContainer from './FooterContainer';

class Footer extends Component {

  componentDidMount () {

    if ( this.props.website_info === null ) {
      setTimeout( () => this.props.websiteInfo(), 3000);
    }

  }

  renderContainer () {
    if ( typeof(this.props.website_info) !== 'undefined' && this.props.website_info !== null ) {
      return (
        <FooterContainer
          website_info = { this.props.website_info }
        />
      );
    }
  }

  render () {
    return (
      <div>
        { this.renderContainer() }
      </div>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    website_info: state.website_info
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    websiteInfo
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(Footer);
