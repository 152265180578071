import React from 'react';
import PropTypes from 'prop-types';

import Map from './Map';
import InfoContact from './InfoContact';

const propTypes = {
  latitude  : PropTypes.string.isRequired,
  longitude : PropTypes.string.isRequired,
  address   : PropTypes.string.isRequired,
  phone     : PropTypes.string.isRequired,
  email     : PropTypes.string.isRequired
}

const InfoLocation = ({ latitude, longitude, address, phone, email }) => {
  return (
    <div className = "col-md-7">
      <h4> Ubicación </h4>
      <Map
        latitude  = { latitude }
        longitude = { longitude }
      />
      <InfoContact
        address = { address }
        phone   = { phone }
        email   = { email }
      />
    </div>
  )
}

InfoLocation.propTypes = propTypes;

export default InfoLocation;
