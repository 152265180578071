import React from 'react';
import PropTypes from 'prop-types';

import Projects from '../../components/Home/Customers/Projects';
import Footer from '../../components/Global/Footer';

const propTypes = {
  id : PropTypes.string.isRequired
}

const ProjectsContainer = ({ id }) => {
  return (
    <div>
      <Projects id = { id } />
      <Footer />
    </div>
  )
}

ProjectsContainer.propTypes = propTypes;

export default ProjectsContainer;
