import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  icon  : PropTypes.string.isRequired,
  title : PropTypes.string.isRequired,
  url   : PropTypes.string.isRequired
}

const renderUrl = (url) => {
  if ( url !== '' ) {
    return (
      <p> Enlace </p>
    )
  }
}

const InfoURL = ({ icon, title, url }) => {
  return (
    <div className="col-md-6">
      <div className="feature-box secundary">
        <div className="feature-box-icon">
          <i className={ icon }></i>
        </div>
        <div className="feature-box-info">
          <h4> { title } </h4>
          <a href = { url } target="_blank" rel="noopener noreferrer" >
            { renderUrl(url) }
          </a>
        </div>
      </div>
    </div>
  )
}

InfoURL.propTypes = propTypes;

export default InfoURL;
