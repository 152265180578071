import {
  GET_INFO_CUSTOMER_MAP
} from '../constants';

import { actions_requests } from '../requests';
import ErrorRequest from '../requests/ErrorRequest';

const getInfoCustomerMap = payload => { return { type: GET_INFO_CUSTOMER_MAP, payload }};

export const customerInfoLocation = ( customerKey ) => {

  return ( dispatch, getState ) => {
    let url_request = process.env.REACT_APP_CLIENT_BY_KEY;
    let body = `Sys_GUID=${customerKey}`;

    actions_requests(body, url_request, 'POST')
      .then( respo => {

        if ( respo.response === 'success' ) {
          dispatch(getInfoCustomerMap(respo.data));
        }

      })
      .catch( err => {
        ErrorRequest(err, null, 3);
      });
  }

}
