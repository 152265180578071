import React, { Component } from 'react';

import { showPosition } from '../../functions/Geolocation';

class SharedUbication extends Component {

  constructor () {
    super();

    this.handleSharedGeolocation = this.handleSharedGeolocation.bind(this);
  }

  handleSharedGeolocation () {
    showPosition();
  }

  renderButton () {
    return (
      <div className = "text-center">
        <h5>
          ¿Permites acceder a tu ubicación ?
        </h5>
        <button
          type      = "button"
          className = "btn btn-info"
          onClick   = { this.handleSharedGeolocation }
        >
          Obtener Localización
        </button>
      </div>
    )
  }

  conditionButton () {

    if ( typeof(sessionStorage.Latitude) === 'undefined' && typeof(sessionStorage.Longitude) === 'undefined' ) {
      return ( this.renderButton() );
    } else {
      if ( sessionStorage.Latitude === process.env.REACT_APP_LATITUDE_CENTRAL && sessionStorage.Longitude === process.env.REACT_APP_LONGITUDE_CENTRAL ) {
        return ( this.renderButton() );
      } else {
        return null;
      }
    }

  }

  render () {
    return (
      <div className = "text-center">
        { this.conditionButton() }
      </div>
    )
  }
}

export default SharedUbication;
