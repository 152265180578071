import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { isEmptyJSON } from '../../functions/ValidationState';
import { videosNext, videosPrevious } from '../../actions/videos_youtube';
import { isOnline, MessageConnection } from '../../functions/NavigatorOnline';

const propTypes = {
  previousPageToken : PropTypes.string,
  nextPageToken     : PropTypes.string
}

class Footer extends Component {

  constructor () {
    super();

    this.handleNextVideos     = this.handleNextVideos.bind(this);
    this.handlePreviousVideos = this.handlePreviousVideos.bind(this);
  }

  handleNextVideos () {

    if ( isOnline() === true ) {
      this.props.videosNext( this.props.videos_youtube.nextPageToken, 8, this.props.channel_youtube.items[0].contentDetails.relatedPlaylists.uploads, false );
    } else if (isOnline() === false ) {
      MessageConnection();
    }

  }

  handlePreviousVideos () {

    if ( isOnline() === true )  {
      this.props.videosPrevious( this.props.videos_youtube.previousPageToken, 8, this.props.channel_youtube.items[0].contentDetails.relatedPlaylists.uploads, false );
    } else if ( isOnline() === false ) {
      MessageConnection();
    }
  }

  renderButtonPrevious () {

    if ( isEmptyJSON(this.props.previousPageToken) === true ) {
      return (
        <li className = "previous">
          <span onClick = { this.handlePreviousVideos }> <i className = "fa fa-angle-left"></i> Anterior </span>
        </li>
      )
    } else {
      return null;
    }

  }

  renderButtonNext () {

    if ( isEmptyJSON(this.props.nextPageToken) === true ) {
      return (
        <li className = "next">
          <span onClick = { this.handleNextVideos }> Siguiente <i className = "fa fa-angle-right"></i> </span>
        </li>
      )
    }
  }

  render () {
    return (
      <div className = "panel-footer otherTest">
        <ul className = "pager">
          { this.renderButtonPrevious() }
          { this.renderButtonNext() }
        </ul>
      </div>
    )
  }
}

Footer.propTypes = propTypes;

function mapStateToProps ( state ) {
  return {
    channel_youtube : state.channel_youtube,
    videos_youtube  : state.videos_youtube
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    videosNext,
    videosPrevious
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(Footer);
