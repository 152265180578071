import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { handleMenuCollapse } from '../../../functions/MenuHeader';
import FirebaseClass from "../../../classes/FirebaseClass";
import { dataUser } from '../../../actions/user';
let objFirebase = new FirebaseClass("Anónimo");

const propTypes = {
  photoURL    : PropTypes.string.isRequired,
  email       : PropTypes.string.isRequired,
  displayName : PropTypes.string.isRequired,
  providerId  : PropTypes.string.isRequired
}

class LoggedHeader extends Component {

  constructor () {
    super();

    this.handleHideMenu = this.handleHideMenu.bind(this);
  }

  async handleLogout () {
    let respo = await objFirebase.logout();

    if ( respo === true ) {
      this.props.dataUser( null );
    }
  }

  handleHideMenu () {
    handleMenuCollapse();
  }

  render () {

    let { photoURL, email, displayName, providerId } = this.props;
    return (
      <li className = "dropdown mega-menu-item mega-menu-signin signin logged">
        <a href = "#logged" className = "dropdown-toggle">
          <div className = "root">
            <img src = { photoURL } className = "img-circle" width = "22px;" height = "22px;" alt = { displayName } /> &nbsp; { displayName }
            <i className = "fa fa-angle-down"></i>
          </div>
        </a>
        <ul className = "dropdown-menu">
          <li>
            <div className = "mega-menu-content">
              <div className = "row">

                <div className = "col-md-8">
                  <div className = "user-avatar">
                    <div className = "img-thumbnail">
                      <img src = { photoURL } alt = { email } className = "img-responsive" />
                    </div>
                    <p><strong> { email }</strong> <span> { providerId }</span></p>
                  </div>
                </div>

                <div className = "col-md-4">
                  <ul className = "list-account-options">
                    <li>
                      <Link to = "/profile" onClick = { this.handleHideMenu  }> Perfil </Link>
                    </li>
                    <li>
                      <span onClick = { () => this.handleLogout() }> Salir </span>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </li>
        </ul>
      </li>
    )
  }
}

LoggedHeader.propTypes = propTypes;

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    dataUser
  }, dispatch)
}

export default connect( null, mapDispatchToProps)(LoggedHeader);
