import React from 'react';

import Head from './Head';
import SidebarContent from './SidebarContent';

const Sidebar = () => {
  return (
    <div className = "col-lg-3 col-md-4 col-sm-4 col-xs-12">

      <div className = "mail-box">
        <aside className = "sm-side">
          <Head />
          <SidebarContent />
        </aside>
      </div>

    </div>
  )
}

export default Sidebar;
