import React from 'react';

import FormSignUp from '../../components/Global/Header/FormSignUp';
import Footer from '../../components/Global/Footer';

const FormSignUpContainer = () => {
  return (
    <div>
      <FormSignUp />
      <Footer />
    </div>
  )
}

export default FormSignUpContainer;
