import React from 'react';

import ContactUs from '../../components/ContactUs';
import Footer from '../../components/Global/Footer';

const ContactUsContainer = () => {
  return (
    <div>
      <ContactUs />
      <Footer />
    </div>
  )
}

export default ContactUsContainer;
