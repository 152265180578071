import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navegation from '../../components/Global/Navegation';
import Spinner from '../Global/Spinner';
import InfoRecomendations from './InfoRecomendations';
import { facebookRatings } from '../../actions/facebook_ratings';
import ListRecomendations from './ListRecomendations';

import './css/postStyle.css';

class Opinions extends Component {

  componentDidMount () {

    if ( this.props.facebook_ratings === null ) {
      this.props.facebookRatings();
    }

  }

  renderOpinions () {

    if ( typeof(this.props.facebook_ratings) !== 'undefined' && this.props.facebook_ratings !== null ) {
      return (
        <div>
          <InfoRecomendations
            onNumberStars = { 0 }
            stars         = { this.props.facebook_ratings.stars }
          />
          <br />
          <ListRecomendations
            ratings = { this.props.facebook_ratings.ratings }
          />
        </div>
      )
    } else {
      return ( <Spinner /> );
    }
  }

  render () {
    return (
      <div>
        <Navegation
          title = { "Opiniones" }
          url   = { "/opinions" }
        />
        { this.renderOpinions() }
      </div>
    )
  }
}

function mapStateToProps ( state ) {
  return {
    facebook_ratings: state.facebook_ratings
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    facebookRatings
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(Opinions);
