import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const propTypes = {
  pictures : PropTypes.array
}

const PicturesProject = ({ pictures }) => {
  return (
    <Carousel
        showStatus={false}
        showThumbs={false}
        infiniteLoop
        autoPlay
        className="carousel"
      >
      {
        pictures.map((pic, index) => {
          let img_name = `image${index}`;
          return (
            <div key={ img_name }>
              <img className="img-responsive" src={ pic } alt={ img_name } />
            </div>
          )
        })
      }
    </Carousel>
  )
}

PicturesProject.propTypes = propTypes;

export default PicturesProject;
