import {
   GET_LIST_JOBS,
   GET_DETAILS_PROFILE_JOB,
   UPDATE_PROFILE_DOCUMENTS,
   CLEAN_PROSPECT_PROFILE
} from "../constants";

import { actions_requests } from '../requests';
import { profiles_data } from '../dateState/profiles';

const getListJobs            = payload => { return { type: GET_LIST_JOBS, payload }};
const getDetailsProfileJob   = payload => { return { type: GET_DETAILS_PROFILE_JOB, payload }};
const updateProfileDocuments = payload => { return { type: UPDATE_PROFILE_DOCUMENTS, payload }};
const cleanPropspectProfile  = payload => { return { type: CLEAN_PROSPECT_PROFILE, payload }};

export const jobsList = () => {   

   return ( dispatch, getState ) => {
      let url_request = process.env.REACT_APP_API_PROFILES_JOB;

      actions_requests(null, url_request, 'GET')
         .then( respo => {

            if ( respo.response === 'success' ) {

               dispatch(getListJobs(
                  profiles_data(
                     respo.data,
                     [],
                     '',
                     '',
                     '',
                     '',
                     '',
                     '',
                     '',
                     '',
                     getState().profiles.title_curriculum,
                     getState().profiles.title_curp,
                     getState().profiles.title_proof_address,
                     getState().profiles.title_ife
                  )
               ));
            }

         })
         .catch( err => {
         });
   }
}

export const detailsJobs = ( profileId ) => {

   return ( dispatch, getState ) => {

      let url_request = process.env.REACT_APP_API_DETAILS_PROFILE;
      let body = `profileId=${ profileId }`;

      actions_requests( body, url_request, 'POST' )
         .then( respo => {

            if ( respo.response === 'success' ) {
               dispatch(getDetailsProfileJob(
                  profiles_data(
                     getState().profiles.data,
                     respo.data,
                     '',
                     '',
                     '',
                     '',
                     '',
                     '',
                     '',
                     '',
                     getState().profiles.title_curriculum,
                     getState().profiles.title_curp,
                     getState().profiles.title_proof_address,
                     getState().profiles.title_ife
                  )
               ));
            }

         })
         .catch( err => {
         });
   }
}

export const actionUploadDocumentsProfile = ( id, secure_url, public_id, name_file ) => {

   return ( dispatch, getState ) => {

      let url_curriculum = getState().profiles.url_curriculum,
      public_id_curriculum = getState().profiles.public_id_curriculum,
      url_curp = getState().profiles.url_curp,
      public_id_curp = getState().profiles.public_id_curp,
      url_proof_address = getState().profiles.url_proof_address,
      public_id_proof_address = getState().profiles.public_id_proof_address,
      url_ife = getState().profiles.url_ife,
      public_id_ife = getState().profiles.public_id_ife,
      title_curriculum = getState().profiles.title_curriculum,
      title_curp = getState().profiles.title_curp,
      title_proof_address = getState().profiles.title_proof_address,
      title_ife = getState().profiles.title_ife;
      
      if ( id === 'curriculum' ) {
         url_curriculum       = secure_url;
         public_id_curriculum = public_id;
         title_curriculum = name_file;
      } else if ( id === 'curp' ) {
         url_curp = secure_url;
         public_id_curp = public_id;
         title_curp = name_file;
      } else if ( id === 'proof_address' ) {
         url_proof_address = secure_url;
         public_id_proof_address = public_id;
         title_proof_address = name_file;
      } else if ( id === 'ife' ) {
         url_ife = secure_url;
         public_id_ife = public_id;
         title_ife = name_file;
      }

      dispatch(updateProfileDocuments(
         profiles_data(
            getState().profiles.data,
            getState().profiles.details,
            url_curriculum,
            public_id_curriculum,
            url_curp,
            public_id_curp,
            url_proof_address,
            public_id_proof_address,
            url_ife,
            public_id_ife,
            title_curriculum,
            title_curp,
            title_proof_address,
            title_ife
         )
      ));
   }
}

export const cleanInfoState = () => {
   
   return ( dispatch, getState ) => {
      dispatch(cleanPropspectProfile(
         profiles_data(
            getState().profiles.data,
            getState().profiles.details,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Curriculum',
            'Curp',
            'Comprobante de domicilio',
            'IFE'
         )
      ))
   }
}
