import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  route_path    : PropTypes.string.isRequired,
  icon          : PropTypes.string.isRequired,
  title         : PropTypes.string.isRequired,
  classEspecial : PropTypes.string
}

const ItemMenuNav = ({ route_path, icon, title, classEspecial }) => {
  return (
    <li>
      <Link to = { route_path } >
        <i className={`fa ${icon} fa-2x`} ></i>  { title }
      </Link>
    </li>
  )
}

ItemMenuNav.propTypes = propTypes;

export default ItemMenuNav;
