import React from 'react';
import PropTypes from 'prop-types';

import InfoURL from './InfoURL';

const propTypes = {
  name_client  : PropTypes.string.isRequired,
  notes        : PropTypes.string.isRequired,
  url_facebook : PropTypes.string.isRequired,
  website      : PropTypes.string.isRequired
}

const GeneralInfo = ({ name_client, notes, url_facebook, website }) => {
  return (
    <div className = "col-md-5">
      <h4>{ name_client }</h4>
      <p className = "lead"> { notes } </p>
      <div className = "row">
        <InfoURL
          icon  = { 'fa fa-facebook' }
          title = { 'Facebook' }
          url   = { url_facebook }
        />
        <InfoURL
          icon  = { 'fa fa-soundcloud' }
          title = { 'Sitio Web' }
          url   = { website }
        />
      </div>
    </div>
  )
}

GeneralInfo.propTypes = propTypes;

export default GeneralInfo;
