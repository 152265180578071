import React from 'react';
import PropTypes from 'prop-types';

import FeatureBox from './FeatureBox';
import './css/mission.css';

const propTypes = {
  title_mission       : PropTypes.string.isRequired,
  description_mission : PropTypes.string.isRequired,
  title_view          : PropTypes.string.isRequired,
  description_view    : PropTypes.string.isRequired,
  title_values        : PropTypes.string.isRequired,
  description_values  : PropTypes.string.isRequired
}

const DataGeneral = ({ title_mission, description_mission, title_view, description_view, title_values, description_values }) => {
  return (
    <div>
      <div className="background_mission">
        <div className="container ptxxxl pbxxxl">
          <div className="row">

            <div className = "col-md-4">
              <FeatureBox
                title       = { title_mission }
                description = { description_mission }
                url_image   = { process.env.REACT_APP_IMAGE_MISSION }
              />
            </div>

            <div className = "col-md-4">
              <FeatureBox
                title       = { title_view }
                description = { description_view }
                url_image   = { process.env.REACT_APP_IMAGE_VISION }
              />
            </div>

            <div className = "col-md-4">
              <FeatureBox
                title       = { title_values }
                description = { description_values }
                url_image   = {  process.env.REACT_APP_IMAGE_FLAG }
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

DataGeneral.propTypes = propTypes;

export default DataGeneral;
