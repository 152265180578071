import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actionUploadDocumentsProfile, cleanInfoState } from '../../actions/jobs';
import ContainerPrincipal from '../HOC/ContainerPrincipal';
import Navegation from '../Global/Navegation';
import { NewString } from '../../functions/ReplaceCharacter';
import InputForm from '../Global/InputForm';
import ItemInputFile from './ItemInputFile';
import { _sweetAlert } from '../../services/SweetAlert2';
import { actions_requests } from '../../requests';

const propTypes = {
   id_profile   : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
   ]),
   name_profile : PropTypes.string
}

class ApplyJob extends Component {

   constructor () {
      super();

      this.handleUploadFile = this.handleUploadFile.bind(this);
      this.saveInformation  = this.saveInformation.bind(this);
   }

   handleUploadFile ( evt ) {
      let id   = evt.target.id;
      let file = document.getElementById(id).files[0];

      const body = new FormData();
      body.append('file_profile', file);
      body.append('folder', 'assets/documents_profiles');
      let url_request = process.env.REACT_APP_API_UPLOAD_FILE_PROFILE;

      fetch(url_request, {
         method: 'POST',
         body
      })
      .then( obj => obj.json() )
      .then( respo => {

         if ( respo.response === "success" ) {
            _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Buen Trabajo!!', messageResponse: respo.message, typeResponse: 'success' });
            this.props.actionUploadDocumentsProfile( id, respo.secure_url, respo.public_id, file.name );
         }

      })
      .catch( err => {
      });

   }

   saveInformation () {
      let user_name   = document.getElementById('user_name').value,
      phone_profile   = document.getElementById('phone_profile').value,
      email_profile   = document.getElementById('email_profile').value,
      address_profile = document.getElementById('address_profile').value;

      let { url_curriculum, public_id_curriculum, url_curp, public_id_curp, url_proof_address,
         public_id_proof_address, url_ife, public_id_ife } = this.props.profiles;
      
      if ( user_name === '' ) {
         _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'El nombre es requerido', typeResponse: 'warning' });
      } else {
         if ( phone_profile === '' ) {
            _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'El teléfono es requerido', typeResponse: 'warning' });
         } else {
            if ( email_profile === '' ) {
               _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'El correo electrónico es requerido', typeResponse: 'warning' });
            } else {
               if ( address_profile === '' ) {
                  _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'La dirección es requerida', typeResponse: 'warning' });
               } else {
                  if ( url_curriculum === '' ) {
                     _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'El curriculum es requerido', typeResponse: 'warning' });
                  } else {
                     if ( url_curp === '' ) {
                        _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'El curp es requerido', typeResponse: 'warning' });
                     } else {
                        if ( url_proof_address === '' ) {
                           _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'El comprobante de domicilio es requerido', typeResponse: 'warning' });
                        } else {
                           if ( url_ife === '' ) {
                              _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Validación', messageResponse: 'El ife es requerido', typeResponse: 'warning' });
                           } else {
                              let body = `prospect_name=${ user_name }&phone=${ phone_profile }&email=${ email_profile }&address=${ address_profile }&url_curriculum=${ url_curriculum }&public_id_curriculum=${ public_id_curriculum }&url_curp=${ url_curp }&public_id_curp=${ public_id_curp }&url_proof_address=${ url_proof_address }&public_id_proof_address=${ public_id_proof_address }&url_ife=${ url_ife }&public_id_ife=${ public_id_ife }&profileId=${ this.props.id_profile }`;
                              this.executeRequestProfile( body );
                           }
                        }
                     }
                  }
               }
            }
         }
      }
      
   }

   executeRequestProfile ( body ) {

      let url_request = process.env.REACT_APP_API_SAVE_PROSPECT_PROFILE;

      actions_requests( body, url_request, 'POST')
         .then( respo => {

            if ( respo.response === 'success' ) {
               _sweetAlert({ typeMessage: 'sweet_message', titleResponse: 'Buen Trabajo!!', messageResponse: respo.message, typeResponse: 'success' });
               document.getElementById('user_name').value = "";
               document.getElementById('phone_profile').value = "";
               document.getElementById('email_profile').value = "";
               document.getElementById('address_profile').value = "";
               this.props.cleanInfoState();
            }
         })
         .catch( err => {
         });
   }

   render () {

      let { profiles } = this.props;
      return (
         <div role = "main" className = "main">
            <Navegation
               title = { "Trabajos" }
               url   = { '/jobs' }
            />
            <div className = "container">
               <div className = "text-center">
                  <h2> ¡Gracias por interesarte en nuestra vacante <b>{ NewString( this.props.name_profile, 3 ) } </b>! </h2>
                  <h5> Una vez que hayamos analizado tu información nos comunicaremos contigo. </h5>
               </div>

               {/* <form id = ""> */ }
                  <div className = "row">
                     <div className = "form-group">

                        <InputForm
                           tamDiv = { "6" }
                           title  = { "Nombre" }
                           id     = { 'user_name' }
                        />

                        <div className = "col-md-6">
                           <label> Teléfono </label>
                           <InputMask
                              { ...this.props }
                              mask = { "+52 (999) 999-99-99" }
                              className = { "form-control" }
                              maskChar = { "" }
                              id = { 'phone_profile' }
                           />
                        </div>

                        <InputForm
                           tamDiv = { "6" }
                           title  = { "Correo electrónico" }
                           id     = { "email_profile" }
                        />

                        <InputForm
                           tamDiv = { "6" }
                           title  = { "Dirección" }
                           id     = { "address_profile" }
                       />

                        <ItemInputFile
                           sizeDiv          = { 'col-md-6' }
                           input_id         = { 'curriculum' }
                           handleUploadFile = { this.handleUploadFile }
                           title            = { profiles.title_curriculum }
                        />

                        <ItemInputFile
                           sizeDiv          = { 'col-md-6' }
                           input_id         = { 'curp' }
                           handleUploadFile = { this.handleUploadFile }
                           title            = { profiles.title_curp }
                        />

                        <ItemInputFile
                           sizeDiv          = { 'col-md-6' }
                           input_id         = { 'proof_address' }
                           handleUploadFile = { this.handleUploadFile }
                           title            = { profiles.title_proof_address }
                        />

                        <ItemInputFile
                           sizeDiv          = { 'col-md-6' }
                           input_id         = { 'ife' }
                           handleUploadFile = { this.handleUploadFile }
                           title            = { profiles.title_ife }
                        />

                        <div className = "col-md-12">
                           <div className = "text-center">
                              <button type = "button" className = "btn btn-primary" onClick = { this.saveInformation }> Guardar Información </button>
                           </div>
                        </div>

                     </div>
                  </div>
               {/* </form> */ }

            </div>
         </div>
      )
   }
}

ApplyJob.propTypes = propTypes;

function mapStateToProps ( state ) {
   return {
     profiles: state.profiles
   };
}
 
function mapDispatchToProps ( dispatch ) {
   return bindActionCreators({
      actionUploadDocumentsProfile,
      cleanInfoState
   }, dispatch)
}
export default connect( mapStateToProps, mapDispatchToProps)(ContainerPrincipal(ApplyJob));