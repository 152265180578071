import React from 'react';
import PropTypes from 'prop-types';

import { isOnline, MessageConnection } from '../../../functions/NavigatorOnline';

const propTypes = {
  classItem : PropTypes.string.isRequired,
  url       : PropTypes.string.isRequired,
  label     : PropTypes.string.isRequired
}

const checkItem = ( evt ) => {

  if ( isOnline() === true ) {
    let url = evt.target.getAttribute('data-url');
    window.open(url);
    return false;
  } else if ( isOnline() === false ) {
    MessageConnection();
  }
}

const ItemSocialNetwork = ({ classItem, url, label }) => {
  return (
    <li className= { classItem }>
      <span onClick = { checkItem } data-url = { url }>
        .
      </span>
    </li>
  )
}

ItemSocialNetwork.propTypes = propTypes;

export default ItemSocialNetwork;
