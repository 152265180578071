import {
  GET_FILTER_SUBCATEGORIES,
  UPDATE_FILTERS_SUBCATEGORIES
} from '../constants';

export const filters_posts = ( state= {}, action ) => {

  switch ( action.type ) {

    case GET_FILTER_SUBCATEGORIES: {
      return action.payload;
    }

    case UPDATE_FILTERS_SUBCATEGORIES : {
      return action.payload;
    }

    default:
      return state;

  }
}
