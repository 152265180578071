import React, { Component } from 'react';

import ContactForm from './ContactForm';

class ContentForm extends Component {

  constructor () {
    super();

    this.sendEmail     = this.sendEmail.bind(this);
    this.handleCaptcha = this.handleCaptcha.bind(this);
  }

  sendEmail () {

  }

  handleCaptcha () {

  }

  render () {
    return (
      <div className = "col-md-6" >
        <ContactForm
          sendEmail     = { this.sendEmail }
          handleCaptcha = { this.handleCaptcha }
        />
      </div>
    )
  }
}

export default ContentForm;
