import {
  GET_POSTS_NOTIFICATIONS,
  GET_POSTS_FILTER_PAGINATION
} from '../constants';

import { actions_requests } from '../requests';
import ErrorRequest from '../requests/ErrorRequest';

const getPostNotifications     = payload => { return { type: GET_POSTS_NOTIFICATIONS, payload }};
const getPostsFilterPagination = payload => { return { type: GET_POSTS_FILTER_PAGINATION, payload }};

export const listPostsNotifications = () => {

  return ( dispatch, getState ) => {
    let url_request = process.env.REACT_APP_API_POSTS_NOTIFICATIONS;

    actions_requests(null, url_request, 'GET')
      .then( respo => {

        if ( respo.response === 'success' ) {
          dispatch(getPostNotifications( { posts_data: respo.data } ));
        }

      })
      .catch( err => {
        ErrorRequest(err, null, 3);
      });
  }

}

export const filterPagination = ( data ) => {

  return ( dispatch, getState ) => {
    let posts_data = getState().posts_notifications;
    let posts_filter = { posts_filter: data };
    let new_obj = Object.assign( {}, posts_data, posts_filter );
    dispatch(getPostsFilterPagination(new_obj));
  }
}
