const StyleModal = {
  content : {
    position              : 'absolute',
    top                   : '27%',
    left                  : '10%',
    right                 : '5%',
    bottom                : '7%',
    overflow              : 'auto'
  }
};

export default StyleModal;
