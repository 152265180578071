import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Scrollbars from 'react-custom-scrollbars';

import { playListYoutube } from '../../../actions/playlists';
import { videosYoutube } from '../../../actions/videos_youtube';
import ItemPlayList from './ItemPlayList';
import { isOnline, MessageConnection } from '../../../functions/NavigatorOnline';

class PlayList extends Component {

  constructor () {
    super();

    this.changePlayList = this.changePlayList.bind(this);
  }

  componentDidMount () {
    if ( this.props.playLists_youtube === null ) {
      setTimeout( () => this.loadChannel(), 1000);
    }
  }

  loadChannel () {
    if ( typeof(this.props.channel_youtube) !== 'undefined' && this.props.channel_youtube !== null ) {
      let channelId = this.props.channel_youtube.items[0].id;
      this.props.playListYoutube(channelId);
    }
  }

  changePlayList ( evt ) {

    if ( isOnline() === true ) {
      let playListId = evt.target.getAttribute("data-id");
      this.props.videosYoutube(8, playListId);
    } else if ( isOnline() === false ) {
      MessageConnection();
    }

  }

  renderPlayLists () {
    let thumbnails_url = "";
    if ( typeof(this.props.playLists_youtube) !== 'undefined' && this.props.playLists_youtube !== null ) {
      return (
        <div>
          <Scrollbars style={{ width: '100%', height: 400 }} >
          {
            this.props.playLists_youtube.items.map( lis => {

              if ( lis.snippet.thumbnails.default.url.includes('http://s.ytimg.com/yts/img/no_thumbnail-vfl4t3-4R.jpg') ) {
                thumbnails_url = 'https://s.ytimg.com/yts/img/no_thumbnail-vfl4t3-4R.jpg';
              } else {
                thumbnails_url = lis.snippet.thumbnails.default.url;
              }

              return (
                <ItemPlayList
                  key            = { lis.id }
                  id             = { lis.id }
                  url            = { thumbnails_url }
                  title          = { lis.snippet.localized.title }
                  changePlayList = { this.changePlayList }
                />
              )
            })
          }
          </Scrollbars>
        </div>
      )
    } else {
      return null;
    }
  }

  render () {
    return (
      this.renderPlayLists()
    )
  }

}

function mapStateToProps ( state ) {
  return {
    channel_youtube   : state.channel_youtube,
    playLists_youtube : state.playLists_youtube
  };
}

function mapDispatchToProps ( dispatch ) {
  return bindActionCreators({
    playListYoutube,
    videosYoutube
  }, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(PlayList);
